import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CheckCircle,
  ChevronRight,
  Book,
  Calendar,
  Target,
  Award,
  Clock,
} from "lucide-react";
import { Button } from "./ui/button";
import { supabase } from "../supabaseClient";
import { SessionContext } from "../index";
import { invokeEdgeFunction } from "../utils/supabaseEdgeFunctions";

const PaymentSuccessPage = () => {
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncComplete, setSyncComplete] = useState(false);
  const [error, setError] = useState(null);
  const [countdown, setCountdown] = useState(10);
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  // Automatic redirect to dashboard after 10 seconds with countdown
  useEffect(() => {
    // Only start countdown once we actually have payment details and syncing is complete
    if (paymentDetails && !isLoading && syncComplete) {
      console.log("Payment details loaded and synced, starting countdown");
      if (countdown > 0) {
        const timer = setTimeout(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        console.log("Countdown complete, redirecting to dashboard");
        navigate("/dashboard");
      }
    }
  }, [paymentDetails, isLoading, navigate, countdown, syncComplete]);

  // Function to eagerly sync with Stripe
  const syncWithStripe = async () => {
    if (!session || !session.user) {
      console.error("Cannot sync with Stripe: No active session");
      return false;
    }

    try {
      setIsSyncing(true);
      console.log("Eagerly syncing Stripe data for user:", session.user.id);

      // Determine if we're in a production environment based on hostname
      // This checks if we're not on localhost or an IP address
      const isProduction =
        !window.location.hostname.includes("localhost") &&
        !/^(\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname);

      // Pass the environment detection to the function
      const result = await invokeEdgeFunction("sync-stripe-subscription", {
        userId: session.user.id,
        isTest: !isProduction, // Use test mode if not in production
      });

      console.log("Stripe sync result:", result);

      // Update payment details with the data from sync if it's available
      if (result && result.data) {
        const syncData = result.data;

        // Fetch full profile data to get any additional details
        const { data: profileData, error: profileError } = await supabase
          .from("user_profiles")
          .select(
            "plan, plan_type, plan_start_date, plan_end_date, payment_amount, payment_date, current_score, target_score",
          )
          .eq("user_id", session.user.id)
          .single();

        if (!profileError && profileData) {
          // Use synced data combined with profile data
          setPaymentDetails({
            plan: syncData.planStatus || profileData.plan,
            planType: syncData.planType || profileData.plan_type,
            startDate: profileData.plan_start_date,
            endDate: syncData.planEndDate || profileData.plan_end_date,
            amount: profileData.payment_amount,
            date: profileData.payment_date,
            currentScore: profileData.current_score,
            targetScore: profileData.target_score,
          });
        }
      }

      setSyncComplete(true);
      return true;
    } catch (error) {
      console.error("Error synchronizing with Stripe:", error);
      // We'll continue with the regular flow even if sync fails
      return false;
    } finally {
      setIsSyncing(false);
    }
  };

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      if (!session) {
        setError("No active session found. Please log in.");
        setIsLoading(false);
        return;
      }

      try {
        console.log("Fetching payment details for user:", session.user.id);

        // First attempt to sync with Stripe to ensure we have the latest data
        const syncSuccessful = await syncWithStripe();

        if (!syncSuccessful) {
          // If sync failed, try to get the most recent payment from payment_history
          const { data: paymentHistoryData, error: paymentHistoryError } =
            await supabase
              .from("payment_history")
              .select("*")
              .eq("user_id", session.user.id)
              .order("payment_date", { ascending: false })
              .limit(1);

          console.log("Payment history query result:", {
            paymentHistoryData,
            paymentHistoryError,
          });

          // Then get profile data which should have been updated by either:
          // 1. The payment_history trigger in production
          // 2. The direct update in our test environment
          const { data: profileData, error: profileError } = await supabase
            .from("user_profiles")
            .select(
              "plan, plan_type, plan_start_date, plan_end_date, payment_amount, payment_date, current_score, target_score",
            )
            .eq("user_id", session.user.id)
            .single();

          console.log("Profile data query result:", {
            profileData,
            profileError,
          });

          if (profileError) {
            console.error("Error fetching profile data:", profileError);
            throw new Error("Failed to fetch profile data");
          }

          // If somehow we're on this page but don't have premium, redirect to dashboard
          if (!profileData || profileData.plan !== "Premium") {
            console.warn("User reached success page without premium status");
            navigate("/dashboard");
            return;
          }

          // Only set payment details here if we didn't already set it in the sync process
          if (!paymentDetails) {
            // Use profile data as our source of truth
            const data = profileData;

            setPaymentDetails({
              plan: data.plan,
              planType: data.plan_type,
              startDate: data.plan_start_date,
              endDate: data.plan_end_date,
              amount: data.payment_amount,
              date: data.payment_date,
              currentScore: data.current_score,
              targetScore: data.target_score,
            });
          }
        }

        // Mark sync as complete if it wasn't already
        if (!syncComplete) {
          setSyncComplete(true);
        }
      } catch (err) {
        console.error("Error fetching payment details:", err);

        // If this is a known error or specific issue we could provide more details
        let errorMessage =
          "Failed to load payment details. Please contact support.";

        if (err.message === "Failed to fetch profile data") {
          // Try to recover by redirecting to dashboard
          console.log("Attempting recovery by redirecting to dashboard");
          setTimeout(() => navigate("/dashboard"), 2000);
          errorMessage =
            "Payment processed successfully, but we couldn't load the details. Redirecting to dashboard...";
        }

        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPaymentDetails();
  }, [session, navigate]);

  // Format date to readable string
  const formatDate = (dateString) => {
    if (!dateString) return "Never";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Calculate days remaining on subscription
  const getDaysRemaining = (endDateString) => {
    if (!endDateString) return "∞";
    const today = new Date();
    const endDate = new Date(endDateString);
    const diffTime = Math.abs(endDate - today);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  // Calculate the plan duration in days
  const getPlanDuration = (startDateString, endDateString) => {
    if (!endDateString || !startDateString) return null;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    const diffTime = Math.abs(endDate - startDate);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  // Next steps for the user
  const nextSteps = [
    {
      id: 1,
      title: "Complete Your First Practice Session",
      description:
        "Start with a 30-minute CARS practice session focusing on passage comprehension.",
      icon: Book,
      action: "Start Practice",
      path: "/practice",
    },
    {
      id: 2,
      title: "Schedule Your Study Plan",
      description:
        "Set up your weekly CARS practice schedule to stay on track with your goals.",
      icon: Calendar,
      action: "Create Schedule",
      path: "/schedule",
    },
    {
      id: 3,
      title: "Explore Your Analytics Dashboard",
      description:
        "Review your baseline performance and identify areas for improvement.",
      icon: Target,
      action: "View Analytics",
      path: "/analytics",
    },
  ];

  if (isLoading || isSyncing) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-b from-blue-50 to-green-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
        <p className="text-blue-700 font-medium">
          {isSyncing
            ? "Synchronizing your subscription..."
            : "Loading your premium account details..."}
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          {error.includes("Redirecting") ? (
            <div className="text-blue-500 mb-4">
              <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600 mx-auto"></div>
            </div>
          ) : (
            <div className="text-red-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-16 w-16 mx-auto"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            </div>
          )}
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            {error.includes("Redirecting")
              ? "Processing Complete"
              : "Something went wrong"}
          </h1>
          <p className="text-gray-600 mb-6">{error}</p>
          <Button
            onClick={() => navigate("/dashboard")}
            className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    );
  }

  // Default message if no payment details found
  if (!paymentDetails) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full text-center">
          <div className="text-yellow-500 mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-16 w-16 mx-auto"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-2">
            Payment Status Unknown
          </h1>
          <p className="text-gray-600 mb-6">
            We couldn't confirm your payment status. If you completed a payment,
            please contact our support team.
          </p>
          <Button
            onClick={() => navigate("/dashboard")}
            className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
          >
            Go to Dashboard
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-xl w-full border border-gray-100">
        <div className="text-center mb-6">
          <div className="bg-gradient-to-r from-green-100 to-emerald-100 p-5 rounded-full inline-block mb-4 shadow-sm animate-pulse">
            <CheckCircle className="h-16 w-16 text-emerald-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            Congratulations on Your Decision!
          </h1>
          <h2 className="text-xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text mb-3">
            You've Just Taken The First Step To Your Dream Medical School
          </h2>
          <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-3 rounded-lg inline-block">
            <p className="text-gray-800 font-medium">
              <span className="font-bold text-blue-700">
                You made the right choice
              </span>{" "}
              - 94% of our successful students say this was their best MCAT
              decision
            </p>
          </div>
          <div className="mt-4 flex justify-center items-center text-sm">
            <Clock className="h-4 w-4 text-blue-600 mr-1" />
            <span className="text-blue-700">
              Redirecting to your new dashboard in{" "}
              <span className="font-bold">{countdown}</span> seconds
            </span>
          </div>
        </div>

        {/* Payment info and subscription details */}
        <div className="bg-gray-50 rounded-lg p-5 mb-6">
          <h2 className="text-lg font-semibold text-gray-900 mb-4 flex items-center">
            <Award className="h-5 w-5 text-blue-600 mr-2" />
            Your Premium Subscription
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="bg-white p-3 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-500 mb-1">Plan Type</div>
              <div className="font-semibold text-gray-900">
                {paymentDetails.planType === "3month"
                  ? "3-Month Access"
                  : paymentDetails.planType === "6month"
                    ? "6-Month Access"
                    : paymentDetails.planType === "lifetime"
                      ? "Lifetime Access"
                      : paymentDetails.planType}
              </div>
            </div>

            <div className="bg-white p-3 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-500 mb-1">Payment Amount</div>
              <div className="font-semibold text-gray-900">
                ${paymentDetails.amount}
              </div>
            </div>

            <div className="bg-white p-3 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-500 mb-1">Start Date</div>
              <div className="font-semibold text-gray-900">
                {formatDate(paymentDetails.startDate)}
              </div>
            </div>

            <div className="bg-white p-3 rounded-lg border border-gray-200">
              <div className="text-sm text-gray-500 mb-1">
                {paymentDetails.planType === "lifetime"
                  ? "Access"
                  : "Valid Until"}
              </div>
              <div className="font-semibold text-green-600">
                {paymentDetails.planType === "lifetime"
                  ? "Lifetime"
                  : formatDate(paymentDetails.endDate)}
              </div>
            </div>
          </div>

          {paymentDetails.planType !== "lifetime" && (
            <div className="mt-4 bg-blue-50 p-3 rounded-lg border border-blue-100 flex items-center">
              <div className="mr-3 p-2 bg-blue-100 rounded-full">
                <Clock className="h-5 w-5 text-blue-600" />
              </div>
              <div>
                <span className="font-medium text-blue-800">
                  {getDaysRemaining(paymentDetails.endDate)} days remaining
                </span>
                <span className="text-sm text-blue-600 ml-1">
                  of your{" "}
                  {getPlanDuration(
                    paymentDetails.startDate,
                    paymentDetails.endDate,
                  )}
                  -day subscription
                </span>
              </div>
            </div>
          )}
        </div>

        {/* CARS Goal */}
        {paymentDetails.currentScore && paymentDetails.targetScore && (
          <div className="mb-6 p-4 bg-gradient-to-r from-indigo-50 to-purple-50 rounded-lg border border-indigo-100">
            <h2 className="text-lg font-semibold text-gray-900 mb-2 flex items-center">
              <Target className="h-5 w-5 text-indigo-600 mr-2" />
              Your CARS Goal
            </h2>
            <div className="flex items-center justify-center space-x-3">
              <div className="text-center">
                <div className="text-sm text-gray-500">Current</div>
                <div className="text-2xl font-bold text-gray-800">
                  {paymentDetails.currentScore}
                </div>
              </div>

              <div className="text-gray-400">→</div>

              <div className="text-center">
                <div className="text-sm text-gray-500">Target</div>
                <div className="text-2xl font-bold text-green-600">
                  {paymentDetails.targetScore}
                </div>
              </div>

              <div className="text-center px-3 py-1 bg-green-100 rounded-full">
                <div className="text-sm font-medium text-green-700">
                  +{paymentDetails.targetScore - paymentDetails.currentScore}{" "}
                  points
                </div>
              </div>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-1.5 mt-3">
              <div
                className="bg-gradient-to-r from-indigo-500 to-purple-500 h-1.5 rounded-full"
                style={{
                  width: `${((paymentDetails.targetScore - 118) / (132 - 118)) * 100}%`,
                }}
              />
            </div>
          </div>
        )}

        {/* Next Steps */}
        <div className="mb-6">
          <div className="flex items-center mb-3">
            <h2 className="text-lg font-semibold text-gray-900">
              Your Fast-Track Success Plan
            </h2>
            <div className="ml-2 px-2 py-0.5 bg-blue-100 text-blue-700 text-xs font-bold rounded-full">
              Required
            </div>
          </div>
          <p className="text-gray-600 text-sm mb-4">
            Complete these three steps in the next 48 hours to triple your
            chances of success:
          </p>
          <div className="space-y-3">
            {nextSteps.map((step, index) => {
              const Icon = step.icon;
              return (
                <div
                  key={step.id}
                  className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-all hover:border-blue-300 transform hover:-translate-y-1"
                >
                  <div className="flex items-start">
                    <div className="flex items-center justify-center p-3 bg-gradient-to-r from-blue-500 to-blue-600 rounded-full mr-3 flex-shrink-0 text-white shadow-md">
                      <span className="font-bold text-lg">{index + 1}</span>
                    </div>
                    <div className="flex-1">
                      <h3 className="font-bold text-gray-900 flex items-center">
                        {step.title}
                        {index === 0 && (
                          <span className="ml-2 px-2 py-0.5 bg-amber-100 text-amber-800 text-xs rounded-full animate-pulse">
                            Start here!
                          </span>
                        )}
                      </h3>
                      <p className="text-sm text-gray-600 mt-1">
                        {step.description}
                      </p>
                      <div className="mt-2 flex items-center text-xs text-gray-500">
                        <Clock className="h-3 w-3 mr-1" />
                        <span>
                          Estimated time:{" "}
                          {index === 0
                            ? "15 minutes"
                            : index === 1
                              ? "10 minutes"
                              : "5 minutes"}
                        </span>
                      </div>
                    </div>
                    <Button
                      variant={index === 0 ? "default" : "outline"}
                      size="sm"
                      className={`flex-shrink-0 ${index === 0 ? "bg-gradient-to-r from-blue-600 to-green-600 text-white hover:shadow-md" : "border-blue-200 text-blue-700 hover:bg-blue-50 hover:text-blue-800"}`}
                      onClick={() => navigate(step.path)}
                    >
                      {step.action}
                      <ChevronRight className="h-3 w-3 ml-1" />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="mt-4 bg-amber-50 rounded-lg p-3 border border-amber-200 text-sm text-amber-700 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-amber-500 mr-2 flex-shrink-0"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              />
            </svg>
            <span>
              <strong>Success Tip:</strong> Students who complete these three
              steps within 48 hours are 3.2x more likely to achieve their target
              score.
            </span>
          </div>
        </div>

        <div className="text-center space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg text-sm text-gray-600">
            <p>
              A receipt has been sent to your email address. If you have any
              questions, please contact our support team at{" "}
              <span className="text-blue-600">team@upangea.com</span>
            </p>
          </div>

          <Button
            onClick={() => navigate("/dashboard")}
            className="w-full py-4 bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 text-lg font-bold shadow-lg hover:shadow-xl transition-all transform hover:scale-105 animate-pulse"
          >
            Start Your Journey to {paymentDetails?.targetScore || "130"}+ Now!
            <span className="ml-2 px-1.5 py-0.5 bg-white/20 rounded text-xs font-medium">
              Your 48hr window starts now
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
