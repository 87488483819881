import { supabase } from "../supabaseClient";

export const invokeEdgeFunction = async (functionName, payload = {}) => {
  try {
    // Get the current session
    const {
      data: { session },
    } = await supabase.auth.getSession();

    if (!session) {
      throw new Error("No active session");
    }

    const { data, error } = await supabase.functions.invoke(functionName, {
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error(`Error invoking ${functionName}:`, error);
    throw error;
  }
};
