import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import TestInterface from "./pages/TestInterface";
import TestSummary from "./pages/TestSummary";
import Navigation from "./components/navigation";
import PrivacyPage from "./pages/PrivacyPage";
import TermsOfService from "./pages/TermsofService";
import Contact from "./pages/Contact";
import NotFoundPage from "./pages/NotFoundPage";
import PaymentSuccessPage from "./components/PaymentSuccessPage";
import PaymentCancelPage from "./components/PaymentCancelPage";
import PaywallMiddleware from "./components/PaywallMiddleware";
import { Analytics } from "@vercel/analytics/react";
import { SessionContext } from "./index";
import { createClient } from "@supabase/supabase-js";
import UnauthorizedPage from "./pages/UnauthorizedPage";

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY,
);

// Add this helper function to ensure user profiles exist
const ensureUserProfileExists = async (user) => {
  if (!user || !user.id) return;

  try {
    // First check if profile already exists
    const { data, error } = await supabase
      .from("user_profiles")
      .select("user_id")
      .eq("user_id", user.id)
      .maybeSingle();

    if (error && error.code !== "PGRST116") {
      console.error("Error checking user profile:", error);
      return;
    }

    // If profile already exists, no need to create one
    if (data) return;

    // Profile doesn't exist, create it now
    console.log("Creating user profile for:", user.id);
    await supabase.from("user_profiles").insert({
      user_id: user.id,
      email: user.email,
      plan: "Free",
    });
  } catch (err) {
    console.error("Exception creating user profile:", err);
  }
};

const PrivateRoute = ({ children }) => {
  const session = React.useContext(SessionContext);
  const [loading, setLoading] = React.useState(true);
  const [hasCheckedSession, setHasCheckedSession] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const checkSession = async () => {
      try {
        const {
          data: { session: currentSession },
        } = await supabase.auth.getSession();
        if (currentSession) {
          console.log("Session found, no timeouted");

          // Ensure user profile exists when session is found
          await ensureUserProfileExists(currentSession.user);

          setHasCheckedSession(true);
          setLoading(false);
        } else {
          console.log("No session found");
          setHasCheckedSession(false);
          setLoading(false);
        }
      } catch (error) {
        console.error("Session check error:", error);
        setHasCheckedSession(true);
        setLoading(false);
      }
    };

    checkSession();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  // First check if user is authenticated
  if (!session) {
    return <UnauthorizedPage />;
  }

  // Then pass through the PaywallMiddleware to check subscription
  return <PaywallMiddleware>{children}</PaywallMiddleware>;
};

function App() {
  const [initialized, setInitialized] = React.useState(false);
  const session = React.useContext(SessionContext);

  React.useEffect(() => {
    const handleAuth = async () => {
      try {
        const hash = window.location.hash;
        const query = new URLSearchParams(window.location.search);
        const accessToken =
          query.get("access_token") ||
          (hash && new URLSearchParams(hash.substring(1)).get("access_token"));

        if (accessToken) {
          console.log("OAuth return detected, waiting for session...");
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const {
            data: { session: currentSession },
          } = await supabase.auth.getSession();
          if (currentSession) {
            console.log("Session established, redirecting to dashboard");
            // Direct inline construction of the redirect URL
            window.location.replace(`${window.location.origin}/dashboard`);
            return;
          }
        }
        setInitialized(true);
      } catch (error) {
        console.error("Auth handling error:", error);
        setInitialized(true);
      }
    };

    handleAuth();
  }, [session]);

  if (!initialized) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              session ? <Navigate to="/dashboard" replace /> : <LandingPage />
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/:testId"
            element={
              <PrivateRoute>
                <TestInterface />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/:testId/review"
            element={
              <PrivateRoute>
                <TestInterface isReviewMode={true} />
              </PrivateRoute>
            }
          />
          <Route
            path="/test/:testId/summary"
            element={
              <PrivateRoute>
                <TestSummary />
              </PrivateRoute>
            }
          />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/payment-success"
            element={
              <PrivateRoute>
                <PaymentSuccessPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/cancel"
            element={
              session ? <PaymentCancelPage /> : <Navigate to="/" replace />
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
}

export default App;
