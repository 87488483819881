import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import logoImage from "../assets/images/5.svg";

export default function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
          <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
            UPangea
          </span>
        </Link>
        <div className="ml-auto">
          <Button
            asChild
            variant="outline"
            className="text-blue-600 border-blue-600 hover:bg-blue-50"
          >
            <Link to="/">Back to Home</Link>
          </Button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 pt-32 pb-16 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-gray-900">
          Privacy Policy
        </h1>
        <div className="space-y-8 text-gray-700">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Introduction
            </h2>
            <p className="mb-4">
              At UPangea, we take your privacy seriously. This Privacy Policy
              explains how we collect, use, disclose, and safeguard your
              information when you use our website and services.
            </p>
            <p className="mb-4">
              By accessing or using our platform, you consent to the collection,
              use, and storage of your information as described in this Privacy
              Policy. We are committed to protecting your personal information
              and being transparent about our practices.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. Information We Collect
            </h2>
            <h3 className="text-xl font-medium mb-2">
              2.1 Personal Information
            </h3>
            <p className="mb-2">
              We may collect the following types of personal information:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Full name, email address, and contact information when you
                create an account
              </li>
              <li>
                Payment information and billing details when you subscribe to
                our services
              </li>
              <li>
                Profile information you voluntarily provide, such as educational
                background, MCAT preparation timeline, and target scores
              </li>
              <li>
                Academic and study progress information, including performance
                data, completion rates, and learning patterns
              </li>
              <li>
                Communications between you and UPangea through email, chat, or
                other messaging features
              </li>
              <li>
                Official test scores and documentation submitted for refund
                verification purposes
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2">2.2 Usage Information</h3>
            <p className="mb-2">
              We automatically collect certain information about your
              interaction with our platform, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Log data, IP address, browser type, and device information
              </li>
              <li>Time spent on platform, pages visited, and features used</li>
              <li>
                Study patterns, question response times, and engagement metrics
              </li>
              <li>
                Performance statistics such as accuracy rates, improvement
                trends, and question completion data
              </li>
              <li>
                Time spent per question, highlighting patterns, and other
                interaction data
              </li>
              <li>
                Session recordings and user behavior analytics (anonymized and
                aggregated)
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2">
              2.3 Learning Pattern Information
            </h3>
            <p className="mb-2">
              To provide our educational services and improve your learning
              experience, we collect:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Question response data, including your answer choices and time
                taken per question
              </li>
              <li>
                Passage interaction data, such as highlighting patterns and time
                spent on different sections
              </li>
              <li>Mistake patterns and recurring error types</li>
              <li>Learning progress over time and improvement metrics</li>
              <li>
                Usage frequency, study schedule patterns, and engagement
                consistency
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2">
              2.4 Cookies and Tracking Technologies
            </h3>
            <p className="mb-2">
              We use cookies and similar technologies to enhance your
              experience, gather usage information, and improve our services.
              These may include:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Essential cookies necessary for platform functionality</li>
              <li>
                Analytics cookies to understand user behavior and improve our
                services
              </li>
              <li>
                Preference cookies to remember your settings and preferences
              </li>
              <li>Session cookies to maintain your login state and security</li>
            </ul>
            <p className="mb-4">
              You can manage cookie preferences through your browser settings,
              although disabling certain cookies may affect platform
              functionality.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. How We Use Your Information
            </h2>
            <p className="mb-2">
              We use the information we collect for the following purposes:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                To create and maintain your account and provide our educational
                services
              </li>
              <li>
                To personalize your learning experience and tailor content to
                your specific needs
              </li>
              <li>
                To analyze and improve our platform, content, and educational
                methodologies
              </li>
              <li>
                To communicate with you about account updates, service changes,
                and educational recommendations
              </li>
              <li>To process payments, subscriptions, and refund requests</li>
              <li>
                To verify eligibility for our refund policy conditions,
                including monitoring question completion and validating test
                scores
              </li>
              <li>
                To detect and prevent fraudulent activity, platform abuse, or
                terms violations
              </li>
              <li>
                To generate anonymized, aggregated statistics about platform
                usage and educational outcomes
              </li>
              <li>
                To provide technical support and respond to your inquiries
              </li>
              <li>
                To comply with legal obligations and enforce our terms of
                service
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2">
              3.1 Refund Verification Process
            </h3>
            <p className="mb-4">
              For our refund policy verification purposes, we specifically use
              your information to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Verify completion of our question bank, including tracking the
                number of questions completed and time spent per question
              </li>
              <li>
                Detect patterns that may indicate artificial or automated
                question completion that does not constitute genuine learning
                attempts
              </li>
              <li>
                Validate official MCAT score reports for qualification under our
                achievement-based refund option
              </li>
              <li>
                Process and validate refund requests based on our Terms of
                Service criteria
              </li>
            </ul>
            <p className="mb-4">
              This processing is necessary for the performance of our contract
              with you and to ensure fair and consistent application of our
              refund policy.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. How We Share Your Information
            </h2>
            <p className="mb-2">
              UPangea may share your information in the following circumstances:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                <span className="font-medium">Service Providers:</span> We may
                share information with third-party vendors who help us operate
                our platform, process payments, provide technical support, or
                analyze data. These service providers are contractually bound to
                use your information only for the purposes of providing services
                to us.
              </li>
              <li>
                <span className="font-medium">Business Transfers:</span> If
                UPangea is involved in a merger, acquisition, or sale of all or
                a portion of our assets, your information may be transferred as
                part of that transaction.
              </li>
              <li>
                <span className="font-medium">Legal Requirements:</span> We may
                disclose your information when required by law, legal process,
                or government request, or to protect our rights, property, or
                safety, or that of our users or others.
              </li>
              <li>
                <span className="font-medium">With Your Consent:</span> We may
                share your information with third parties when you explicitly
                consent to such sharing.
              </li>
            </ul>
            <p className="mb-4">
              We do not sell, rent, or lease your personal information to third
              parties for their marketing purposes without your explicit
              consent.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Data Security
            </h2>
            <p className="mb-4">
              We implement appropriate technical, organizational, and physical
              measures to maintain the security and integrity of your personal
              information, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Encryption of sensitive data both in transit and at rest</li>
              <li>Regular security assessments and vulnerability testing</li>
              <li>
                Access controls and authentication requirements for our staff
              </li>
              <li>Data backup and recovery procedures</li>
              <li>
                Staff training on data protection and security best practices
              </li>
            </ul>
            <p className="mb-4">
              However, please note that no method of transmission over the
              internet or electronic storage is 100% secure. While we strive to
              use commercially acceptable means to protect your personal
              information, we cannot guarantee its absolute security.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. Data Retention
            </h2>
            <p className="mb-4">
              We retain your personal information for as long as necessary to
              fulfill the purposes outlined in this Privacy Policy, unless a
              longer retention period is required or permitted by law.
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Account information is retained for the duration of your active
                account and for a reasonable period afterward for record-keeping
                purposes
              </li>
              <li>
                Learning and performance data is retained to maintain your
                educational progress and history
              </li>
              <li>
                Payment information is retained as required for financial, tax,
                and legal compliance purposes
              </li>
              <li>
                Usage logs and analytics data may be retained in anonymized or
                aggregated form for longer periods for platform improvement
                purposes
              </li>
            </ul>
            <p className="mb-4">
              You may request deletion of your account and personal information
              as described under "Your Privacy Rights" below, subject to
              legitimate business or legal retention requirements.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. Your Privacy Rights
            </h2>
            <p className="mb-2">
              Depending on your location, you may have certain rights regarding
              your personal information, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>The right to access your personal information</li>
              <li>The right to correct inaccurate or incomplete data</li>
              <li>
                The right to request deletion of your personal information,
                subject to any legal requirements for data retention
              </li>
              <li>
                The right to object to or restrict certain processing of your
                data
              </li>
              <li>
                The right to data portability (receiving a copy of your data in
                a structured, commonly used format)
              </li>
              <li>
                The right to withdraw consent, where processing is based on your
                consent
              </li>
            </ul>
            <p className="mb-4">
              To exercise these rights, please contact us at{" "}
              <a
                href="mailto:team@upangea.com"
                className="text-blue-600 hover:underline"
              >
                team.com
              </a>
              . We will respond to your request within a reasonable timeframe
              and in accordance with applicable laws.
            </p>
            <p className="mb-4">
              Please note that some requests may be subject to verification of
              your identity and certain legal limitations. Some information may
              be retained for legitimate business or legal purposes even after a
              deletion request.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Children's Privacy
            </h2>
            <p className="mb-4">
              Our services are not intended for individuals under the age of 16,
              and we do not knowingly collect personal information from children
              under 16. If we learn that we have collected personal information
              from a child under 16, we will take steps to delete such
              information promptly unless we have a legal obligation to keep it.
            </p>
            <p className="mb-4">
              If you are a parent or guardian and believe that your child has
              provided us with personal information without your consent, please
              contact us as described below.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              9. International Data Transfers
            </h2>
            <p className="mb-4">
              UPangea is based in the United States, and your information may be
              processed and stored on servers located in the United States or
              other countries where our service providers maintain facilities.
              These countries may have data protection laws that differ from
              those in your country of residence.
            </p>
            <p className="mb-4">
              By using our services, you consent to the transfer of your
              information to countries outside your country of residence,
              including the United States. We take appropriate measures to
              ensure that your personal information receives an adequate level
              of protection in the jurisdictions in which we process it.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              10. Third-Party Links and Services
            </h2>
            <p className="mb-4">
              Our platform may contain links to third-party websites,
              applications, or services that are not owned or controlled by
              UPangea. This Privacy Policy applies only to our platform. We have
              no control over and assume no responsibility for the privacy
              practices of any third-party sites or services.
            </p>
            <p className="mb-4">
              We encourage you to review the privacy policies of any third-party
              sites or services before providing any personal information or
              using such services.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              11. Changes to This Privacy Policy
            </h2>
            <p className="mb-4">
              We may update this Privacy Policy from time to time to reflect
              changes in our practices, technologies, legal requirements, or
              other factors. We will notify you of any material changes by
              posting the updated Privacy Policy on this page and updating the
              "Last Updated" date below.
            </p>
            <p className="mb-4">
              We encourage you to review this Privacy Policy periodically to
              stay informed about our information practices. Your continued use
              of our services after changes to the Privacy Policy indicates your
              acceptance of the updated terms.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              12. California Privacy Rights
            </h2>
            <p className="mb-4">
              California residents may have additional rights under the
              California Consumer Privacy Act (CCPA) regarding their personal
              information, including:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                The right to know what personal information we collect, use,
                disclose, and sell
              </li>
              <li>The right to request deletion of personal information</li>
              <li>The right to opt-out of the sale of personal information</li>
              <li>
                The right to non-discrimination for exercising these rights
              </li>
            </ul>
            <p className="mb-4">
              To exercise these rights, please contact us using the information
              provided below.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              13. Contact Us
            </h2>
            <p className="mb-4">
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or our privacy practices, please contact us at:
            </p>
            <p className="mb-4">
              <strong>Email:</strong>{" "}
              <a
                href="mailto:team@upangea.com"
                className="text-blue-600 hover:underline"
              >
                team@upangea.com
              </a>
            </p>
            <p className="mb-4">
              <strong>Postal Address:</strong>
              <br />
              UPangea Inc.
              <br />
              Privacy Team
              <br />
              123 Education Lane
              <br />
              San Francisco, CA 94105
              <br />
              United States
            </p>
            <p className="mb-4">
              We will respond to your inquiries or concerns within a reasonable
              timeframe.
            </p>
          </section>

          <section>
            <p className="mt-8 font-medium">Last Updated: March 1, 2024</p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-6">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-sm">© 2024 UPangea Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
