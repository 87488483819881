import React, { useState } from "react";
import { Dialog, DialogContent, DialogHeader, DialogFooter } from "./ui/dialog";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Label } from "./ui/label";
import { Slider } from "./ui/slider";
import {
  CheckCircle,
  Star,
  ArrowRight,
  BookOpen,
  MessageSquare,
  ChevronLeft,
  ChevronRight,
  Check,
  X,
  Brain,
  Clock,
  TrendingUp,
  Target,
  Medal,
  Sparkles,
  School,
  CheckSquare,
  Shield,
  Copy,
} from "lucide-react";
import { cn } from "../lib/utils";
import PaymentModal from "./PaymentModal";

// Beta discount code - used for early bird users
// To create this discount code in Stripe:
// 1. Go to https://dashboard.stripe.com/coupons
// 2. Click "Create coupon"
// 3. Set "Discount type" to "Percentage"
// 4. Set "Amount off" to "50%"
// 5. Set "Duration" to "Once"
// 6. Enter "BETA50" as the Coupon ID
// 7. Save the coupon
const BETA_DISCOUNT_CODE = "BETA50";

const OnboardingFlow = ({
  open,
  onOpenChange,
  session,
  onComplete,
  hasEarlyBirdDiscount = false,
}) => {
  // Steps in the onboarding flow
  const [step, setStep] = useState(0);

  // State for collecting user preferences
  const [formData, setFormData] = useState({
    currentScore: 123,
    targetScore: 128,
    testDate: "",
    mainChallenges: [],
    confidenceLevel: 6,
  });

  // State for selected plan
  const [selectedPlan, setSelectedPlan] = useState("3month");

  // State for copy success
  const [copySuccess, setCopySuccess] = useState(false);

  // Toggles for payment modal
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

  // Standard pricing options
  const standardPricingOptions = {
    "1month": {
      label: "1 Month Access",
      price: "97",
      originalPrice: "129",
      period: "for 1 month",
      description: "Great for last-minute prep or trying out the platform",
    },
    "3month": {
      label: "3 Months Access",
      price: "247",
      originalPrice: "327",
      period: "for 3 months",
      description: "Perfect for dedicated CARS prep during intensive study",
      popular: true,
    },
    "6month": {
      label: "6 Months Access",
      price: "347",
      originalPrice: "459",
      period: "for 6 months",
      description: "For long-term practice, early starters, or slower pace",
    },
    lifetime: {
      label: "Lifetime Access",
      price: "447",
      originalPrice: "599",
      period: "one-time",
      description: "One-time investment, unlimited CARS practice forever",
    },
  };

  // Early bird pricing options (50% off)
  const earlyBirdPricingOptions = {
    "1month": {
      label: "1 Month Access",
      price: "49",
      originalPrice: "97",
      period: "for 1 month",
      description: "Great for last-minute prep or trying out the platform",
    },
    "3month": {
      label: "3 Months Access",
      price: "124",
      originalPrice: "247",
      period: "for 3 months",
      description: "Perfect for dedicated CARS prep during intensive study",
      popular: true,
    },
    "6month": {
      label: "6 Months Access",
      price: "174",
      originalPrice: "347",
      period: "for 6 months",
      description: "For long-term practice, early starters, or slower pace",
    },
    lifetime: {
      label: "Lifetime Access",
      price: "224",
      originalPrice: "447",
      period: "one-time",
      description: "One-time investment, unlimited CARS practice forever",
    },
  };

  // Select pricing options based on early bird status
  const pricingOptions = hasEarlyBirdDiscount
    ? earlyBirdPricingOptions
    : standardPricingOptions;

  // Common CARS challenges
  const challenges = [
    {
      value: "time_management",
      label: "Time Management",
      icon: Clock,
      description: "Finishing passages within time limits",
    },
    {
      value: "comprehension",
      label: "Reading Comprehension",
      icon: BookOpen,
      description: "Understanding complex passage content",
    },
    {
      value: "reasoning",
      label: "Critical Reasoning",
      icon: Brain,
      description: "Analyzing arguments and identifying logical flaws",
    },
    {
      value: "answer_selection",
      label: "Answer Selection",
      icon: CheckSquare,
      description: "Eliminating wrong answers confidently",
    },
  ];

  // Testimonials
  const testimonials = [
    {
      name: "Taylor W.",
      score: "122 → 128 in CARS",
      timeframe: "10 weeks",
      dreamOutcome: "Accepted to medical school",
      quote:
        "After struggling with CARS, I found UPangea's approach made a difference. The passages felt similar to the real MCAT, and the AI tutor helped me recognize when I wasn't picking up on authors' implicit assumptions. With consistent practice over 10 weeks, I improved by 6 points, which made a meaningful difference in my overall application.",
    },
    {
      name: "Priya G.",
      score: "123 → 127 in CARS",
      timeframe: "3 months",
      dreamOutcome: "Accepted to medical school",
      quote:
        "As a non-native English speaker, CARS was challenging for me. UPangea's highlighting tool helped me identify which sentences I was overlooking, and the tutor pointed out tone shifts I couldn't detect. It took consistent work over three months, but a 4-point improvement made a difference in my application. Even a modest score increase can be significant.",
    },
    {
      name: "Josh F.",
      score: "124 → 131 in CARS",
      timeframe: "3 months",
      dreamOutcome: "Preparing Application",
      quote:
        "Despite my background in humanities, I was surprised when my CARS diagnostic came back at 124. UPangea's analytics helped me realize I was overthinking certain question types. The way they categorize wrong answers by reasoning pattern was very helpful. With dedicated practice over 3 months, I improved by 7 points, which DEFINITELY has strengthened my application and confidence.",
    },
  ];

  // Handle copy discount code to clipboard
  const handleCopyCode = () => {
    navigator.clipboard.writeText(BETA_DISCOUNT_CODE).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // Handle slider changes
  const handleSliderChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value[0],
    });
  };

  // Handle checkbox changes for challenges
  const handleChallengeToggle = (value) => {
    setFormData((prev) => {
      const current = [...prev.mainChallenges];
      if (current.includes(value)) {
        return {
          ...prev,
          mainChallenges: current.filter((item) => item !== value),
        };
      } else {
        return { ...prev, mainChallenges: [...current, value] };
      }
    });
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle navigation between steps
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // Handle proceeding to payment
  const handleProceedToPayment = () => {
    setIsPaymentModalOpen(true);
  };

  // Handle payment success - this is now handled directly by the payment component
  // which redirects to the success page
  const handlePaymentSuccess = () => {
    setIsPaymentModalOpen(false);
    // We don't call onComplete() here as we're redirecting to the payment success page
  };

  // Handle modal close events properly
  const handleModalOpenChange = (open) => {
    setIsPaymentModalOpen(open);
    if (!open) {
      // Delay onOpenChange to avoid UI glitches
      setTimeout(() => {
        if (onOpenChange) onOpenChange(open);
      }, 100);
    }
  };

  // Calculate potential MCAT CARS score based on current score
  const getEstimatedScoreImprovement = () => {
    // Realistic estimate of improvement with consistent practice
    return Math.min(formData.currentScore + 3, 132);
  };

  // Get percentile for a given CARS score
  const getPercentileText = (score) => {
    if (score >= 132) return "100th";
    if (score >= 131) return "99th";
    if (score >= 130) return "98th";
    if (score >= 129) return "95th";
    if (score >= 128) return "92nd";
    if (score >= 127) return "88th";
    if (score >= 126) return "84th";
    if (score >= 125) return "80th";
    if (score >= 124) return "74th";
    if (score >= 123) return "68th";
    if (score >= 122) return "62nd";
    if (score >= 121) return "55th";
    if (score >= 120) return "48th";
    return "Below 45th";
  };

  // Render step content based on current step
  const renderStepContent = () => {
    switch (step) {
      case 0:
        // Welcome screen
        return (
          <div className="text-center space-y-6 p-6">
            <div className="mx-auto w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center">
              <BookOpen className="w-12 h-12 text-blue-600" />
            </div>
            <h3 className="text-3xl font-bold text-gray-900">
              Improve Your MCAT CARS Preparation
            </h3>
            <p className="text-gray-600 max-w-lg mx-auto text-lg">
              Take <span className="font-bold text-blue-700">2 minutes</span> to
              discover how our extensive CARS question bank and personalized AI
              feedback can help strengthen your MCAT preparation and boost your
              confidence for test day.
            </p>
            <div className="pt-4">
              <Button
                onClick={nextStep}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-10 py-6 text-lg font-medium rounded-xl hover:shadow-lg transition-all animate-pulse"
              >
                Begin Your Medical School Journey{" "}
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </div>
            <p className="text-xs text-gray-500 mt-3">
              No credit card required to see your potential
            </p>
          </div>
        );

      case 1:
        // Dream Outcome & Score Goals
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <Medal className="h-16 w-16 mx-auto text-blue-600 mb-3" />
              <h3 className="text-2xl font-bold text-gray-900">
                Your CARS Goal
              </h3>
              <p className="text-gray-600">
                Let's visualize your success journey
              </p>
            </div>

            <div className="space-y-8 max-w-2xl mx-auto">
              <div>
                <h4 className="text-lg font-medium text-gray-900 mb-3">
                  Your current CARS score
                </h4>
                <div className="space-y-3">
                  <Slider
                    min={118}
                    max={132}
                    step={1}
                    value={[formData.currentScore]}
                    onValueChange={(value) =>
                      handleSliderChange("currentScore", value)
                    }
                    className="py-2"
                  />
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">118</span>
                    <div className="text-center">
                      <span className="text-3xl font-bold text-blue-600">
                        {formData.currentScore}
                      </span>
                      <p className="text-xs text-gray-500">
                        ({getPercentileText(formData.currentScore)} percentile)
                      </p>
                    </div>
                    <span className="text-sm text-gray-500">132</span>
                  </div>
                </div>
              </div>

              <div>
                <h4 className="text-lg font-medium text-gray-900 mb-3">
                  Your target CARS score
                </h4>
                <div className="space-y-3">
                  <Slider
                    min={formData.currentScore}
                    max={132}
                    step={1}
                    value={[formData.targetScore]}
                    onValueChange={(value) =>
                      handleSliderChange("targetScore", value)
                    }
                    className="py-2"
                  />
                  <div className="flex justify-between items-center">
                    <span className="text-sm text-gray-500">
                      {formData.currentScore}
                    </span>
                    <div className="text-center">
                      <span className="text-3xl font-bold text-green-600">
                        {formData.targetScore}
                      </span>
                      <p className="text-xs text-gray-500">
                        ({getPercentileText(formData.targetScore)} percentile)
                      </p>
                    </div>
                    <span className="text-sm text-gray-500">132</span>
                  </div>
                </div>
              </div>

              <div className="bg-gradient-to-r from-blue-50 to-green-50 p-5 rounded-lg border border-green-100 shadow-sm">
                <div className="flex items-start">
                  <School className="h-6 w-6 text-green-600 mt-1 mr-3 flex-shrink-0" />
                  <div>
                    <h4 className="font-medium text-gray-900 text-lg">
                      Your Dream Outcome with {formData.targetScore} in CARS
                    </h4>
                    <p className="text-gray-700 mt-2">
                      A {formData.targetScore} in CARS would place you in the{" "}
                      {getPercentileText(formData.targetScore)} percentile,
                      making you competitive for{" "}
                      {formData.targetScore >= 129
                        ? "top-tier medical schools"
                        : formData.targetScore >= 126
                          ? "most medical schools"
                          : "many medical schools"}
                      .
                    </p>
                    <div className="mt-3 flex items-center">
                      <TrendingUp className="h-5 w-5 text-green-600 mr-2" />
                      <span className="font-medium text-green-700">
                        {formData.targetScore - formData.currentScore} point
                        improvement
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-blue-50 p-5 rounded-lg border border-blue-100 shadow-sm">
                <p className="text-blue-700">
                  <span className="font-bold">Important to know:</span> Some of
                  our beta users with similar starting scores have seen profound
                  improvements through consistent practice over 8-12 weeks.
                  Individual results vary based on dedication and study habits.
                </p>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                variant="outline"
                onClick={prevStep}
                size="lg"
                className="font-medium"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button
                onClick={nextStep}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-8 font-medium"
                size="lg"
              >
                Continue <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        );

      case 2:
        // Challenges & Obstacles
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <Target className="h-16 w-16 mx-auto text-blue-600 mb-3" />
              <h3 className="text-2xl font-bold text-gray-900">
                Your CARS Challenges
              </h3>
              <p className="text-gray-600">
                Let's identify what's holding you back
              </p>
            </div>

            <div className="max-w-2xl mx-auto">
              <h4 className="text-lg font-bold text-gray-900 mb-2">
                Which CARS obstacles are keeping you from your dream medical
                school?
              </h4>
              <p className="text-red-600 font-medium mb-1">
                These roadblocks cost pre-med students thousands of hours and
                countless rejections.
              </p>
              <p className="text-gray-600 mb-4">
                Select all that currently prevent you from achieving your target
                score:
              </p>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {challenges.map((challenge) => {
                  const isSelected = formData.mainChallenges.includes(
                    challenge.value,
                  );
                  const Icon = challenge.icon;

                  return (
                    <div
                      key={challenge.value}
                      className={`border rounded-lg p-4 cursor-pointer transition-all hover:shadow-md ${
                        isSelected
                          ? "border-blue-500 bg-blue-50"
                          : "border-gray-200 hover:border-gray-300"
                      }`}
                      onClick={() => handleChallengeToggle(challenge.value)}
                    >
                      <div className="flex items-center">
                        <div
                          className={`p-2 rounded-full ${isSelected ? "bg-blue-100" : "bg-gray-100"}`}
                        >
                          <Icon
                            className={`h-5 w-5 ${isSelected ? "text-blue-600" : "text-gray-500"}`}
                          />
                        </div>
                        <div className="ml-3">
                          <span
                            className={`font-medium ${isSelected ? "text-blue-700" : "text-gray-700"}`}
                          >
                            {challenge.label}
                          </span>
                          <p className="text-xs text-gray-500">
                            {challenge.description}
                          </p>
                          {isSelected && (
                            <p className="text-xs text-red-600 mt-1">
                              <Clock className="h-3 w-3 inline mr-1" />
                              {challenge.value === "time_management"
                                ? "Costs 2-3 extra months of prep time"
                                : challenge.value === "comprehension"
                                  ? "Causes up to 30% of missed questions"
                                  : challenge.value === "reasoning"
                                    ? "Top reason for rejection letters"
                                    : "Prevents a 6-point score improvement"}
                            </p>
                          )}
                        </div>
                        {isSelected && (
                          <CheckCircle className="h-5 w-5 text-blue-500 ml-auto" />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>

              {formData.mainChallenges.length > 0 && (
                <div className="mt-6 bg-gradient-to-r from-green-50 to-emerald-50 p-5 rounded-lg border border-green-100 shadow-sm">
                  <div className="flex items-start">
                    <Sparkles className="h-6 w-6 text-green-600 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <p className="font-medium text-green-700 text-lg">
                        How UPangea Solves Your Specific Challenges:
                      </p>

                      {/* Challenge-specific solutions */}
                      <div className="mt-3 space-y-4">
                        {formData.mainChallenges.includes(
                          "time_management",
                        ) && (
                          <div className="bg-white p-3 rounded-lg border border-green-200 shadow-sm">
                            <h5 className="font-bold text-gray-800 flex items-center">
                              <Clock className="h-4 w-4 mr-2 text-green-600" />
                              Time Management Solution
                            </h5>
                            <p className="text-sm text-gray-600 mt-1">
                              <strong>13,000+ Timed Passages</strong> with
                              precise passage timer analytics that pinpoint
                              where you're losing time. Built-in pacing guides
                              optimize your speed without sacrificing accuracy.
                            </p>
                            <div className="mt-2 flex items-center text-xs text-green-700">
                              <TrendingUp className="h-3 w-3 mr-1" />
                              <span>
                                Improved passage completion time with focused
                                practice
                              </span>
                            </div>
                          </div>
                        )}

                        {formData.mainChallenges.includes("comprehension") && (
                          <div className="bg-white p-3 rounded-lg border border-green-200 shadow-sm">
                            <h5 className="font-bold text-gray-800 flex items-center">
                              <BookOpen className="h-4 w-4 mr-2 text-green-600" />
                              Reading Comprehension Solution
                            </h5>
                            <p className="text-sm text-gray-600 mt-1">
                              <strong>24/7 AI Tutor</strong> analyzes your
                              highlighting patterns to identify critical missed
                              content. Get personalized strategies for each
                              passage type that eliminates your comprehension
                              blind spots.
                            </p>
                            <div className="mt-2 flex items-center text-xs text-green-700">
                              <TrendingUp className="h-3 w-3 mr-1" />
                              <span>
                                Comprehension scores typically improve with
                                regular practice
                              </span>
                            </div>
                          </div>
                        )}

                        {formData.mainChallenges.includes("reasoning") && (
                          <div className="bg-white p-3 rounded-lg border border-green-200 shadow-sm">
                            <h5 className="font-bold text-gray-800 flex items-center">
                              <Brain className="h-4 w-4 mr-2 text-green-600" />
                              Critical Reasoning Solution
                            </h5>
                            <p className="text-sm text-gray-600 mt-1">
                              <strong>AAMC-Style Questions</strong> categorized
                              by reasoning patterns with advanced analytics that
                              target your specific reasoning weaknesses. Genuine
                              CARS logic that mirrors the real MCAT.
                            </p>
                            <div className="mt-2 flex items-center text-xs text-green-700">
                              <TrendingUp className="h-3 w-3 mr-1" />
                              <span>
                                Many users see fewer reasoning errors with
                                dedicated practice
                              </span>
                            </div>
                          </div>
                        )}

                        {formData.mainChallenges.includes(
                          "answer_selection",
                        ) && (
                          <div className="bg-white p-3 rounded-lg border border-green-200 shadow-sm">
                            <h5 className="font-bold text-gray-800 flex items-center">
                              <CheckSquare className="h-4 w-4 mr-2 text-green-600" />
                              Answer Selection Solution
                            </h5>
                            <p className="text-sm text-gray-600 mt-1">
                              <strong>Advanced Analytics</strong> expose your
                              exact distractor weaknesses. Learn precise
                              elimination strategies for the specific answer
                              choice traps that fool you most frequently.
                            </p>
                            <div className="mt-2 flex items-center text-xs text-green-700">
                              <TrendingUp className="h-3 w-3 mr-1" />
                              <span>
                                Better answer selection skills develop through
                                targeted practice
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="mt-2 pt-2 border-t border-green-200">
                          <p className="text-sm font-medium text-green-800">
                            Dominate CARS with our proprietary system: 13,000+
                            AAMC-style questions, 24/7 AI tutor feedback, and
                            precision analytics that improve your entire MCAT
                            performance.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="max-w-2xl mx-auto mt-8">
              <h4 className="text-lg font-medium text-gray-900 mb-3">
                How confident are you in achieving your CARS goal?
              </h4>
              <div className="space-y-3">
                <Slider
                  min={1}
                  max={10}
                  step={1}
                  value={[formData.confidenceLevel]}
                  onValueChange={(value) =>
                    handleSliderChange("confidenceLevel", value)
                  }
                  className="py-2"
                />
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Not confident</span>
                  <span>Very confident</span>
                </div>
                <div className="text-center py-2">
                  <span className="text-2xl font-bold text-blue-600">
                    {formData.confidenceLevel}/10
                  </span>
                </div>
              </div>

              <div className="mt-4 bg-blue-50 p-5 rounded-lg border border-blue-100 shadow-sm">
                <p className="text-blue-700">
                  {formData.confidenceLevel <= 5
                    ? "Many students feel the same way before using UPangea. Our structured approach and authentic CARS practice leads to dramatic confidence shifts in just weeks."
                    : "Great! Your confidence will be a huge asset. UPangea's targeted approach will help transform that confidence into real score improvements."}
                </p>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                variant="outline"
                onClick={prevStep}
                size="lg"
                className="font-medium"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button
                onClick={nextStep}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-8 font-medium"
                size="lg"
              >
                Continue <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        );

      case 3:
        // Solution & Transformation
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <Sparkles className="h-16 w-16 mx-auto text-blue-600 mb-3" />
              <h3 className="text-2xl font-bold text-gray-900">
                Your CARS Transformation
              </h3>
              <p className="text-gray-600">
                Here's how UPangea will help you achieve your goals
              </p>
            </div>

            <div className="bg-gradient-to-r from-blue-50 to-green-50 p-6 rounded-lg border border-blue-100 shadow-sm max-w-2xl mx-auto">
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="bg-white p-3 rounded-full shadow-sm mr-4 flex-shrink-0">
                    <TrendingUp className="h-6 w-6 text-green-600" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 text-lg">
                      Your Score Potential
                    </h4>
                    <div className="flex items-baseline mt-2 space-x-2">
                      <span className="text-2xl font-bold text-gray-800">
                        {formData.currentScore}
                      </span>
                      <span className="text-gray-400">→</span>
                      <span className="text-2xl font-bold text-green-600">
                        {formData.targetScore}
                      </span>
                      <span className="text-green-600">
                        (+{formData.targetScore - formData.currentScore} points)
                      </span>
                    </div>
                    <p className="text-gray-600 mt-1">
                      From {getPercentileText(formData.currentScore)} percentile
                      to {getPercentileText(formData.targetScore)} percentile
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-white p-3 rounded-full shadow-sm mr-4 flex-shrink-0">
                    <Clock className="h-6 w-6 text-blue-600" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 text-lg">
                      Time Savings
                    </h4>
                    <p className="text-gray-600 mt-1">
                      <span className="font-medium">
                        24/7 AI tutor feedback
                      </span>{" "}
                      means no waiting for tutors or study groups - save 70% of
                      your prep time while getting better results
                    </p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="bg-white p-3 rounded-full shadow-sm mr-4 flex-shrink-0">
                    <Brain className="h-6 w-6 text-purple-600" />
                  </div>
                  <div>
                    <h4 className="font-medium text-gray-900 text-lg">
                      Focused Learning Approach
                    </h4>
                    <p className="text-gray-600 mt-1">
                      <span className="font-medium">
                        13,000+ practice questions
                      </span>{" "}
                      help you focus your study time on high-quality content
                      specifically designed for CARS improvement, allowing for
                      more efficient practice and targeted feedback
                    </p>
                    <div className="mt-2 flex items-center">
                      <div className="w-full h-2 bg-gray-200 rounded-full">
                        <div className="w-1/2 h-2 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full"></div>
                      </div>
                      <span className="ml-2 text-xs font-bold text-purple-700">
                        Efficient
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex items-start bg-amber-50 p-4 rounded-lg border border-amber-200">
                  <div className="bg-white p-3 rounded-full shadow-md mr-4 flex-shrink-0 border border-amber-300">
                    <Shield className="h-6 w-6 text-amber-600" />
                  </div>
                  <div>
                    <h4 className="font-bold text-amber-800 text-lg">
                      Money-Back Guarantee
                    </h4>
                    <p className="text-gray-700 mt-1">
                      Your payment is refundable when you complete all questions
                      OR score in the top 10% - ensuring you can focus on your
                      MCAT preparation with confidence
                    </p>
                    <div className="mt-3 flex items-center bg-white px-3 py-2 rounded-lg border border-amber-200">
                      <svg
                        className="h-5 w-5 text-amber-500 mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12 1.5a5.25 5.25 0 00-5.25 5.25v3a3 3 0 00-3 3v6.75a3 3 0 003 3h10.5a3 3 0 003-3v-6.75a3 3 0 00-3-3v-3c0-2.9-2.35-5.25-5.25-5.25zm3.75 8.25v-3a3.75 3.75 0 10-7.5 0v3h7.5z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span className="text-xs font-medium text-gray-700">
                        Shows our commitment to your success - we succeed when
                        you do
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-r from-amber-50 to-yellow-50 p-5 rounded-lg border border-amber-200 shadow-md max-w-2xl mx-auto">
              <div className="flex items-start">
                <div className="bg-gradient-to-r from-amber-500 to-yellow-500 p-2 rounded-xl mr-3 flex-shrink-0 shadow-md">
                  <Star className="h-7 w-7 text-white" />
                </div>
                <div>
                  <h4 className="font-bold text-amber-800 text-lg">
                    A Path to Improvement
                  </h4>
                  <p className="text-amber-700 mt-2">
                    Beta users with similar starting scores have seen
                    improvements through consistent practice. With regular,
                    focused study sessions, you may be able to work toward your
                    target score over time. Results vary by individual, and
                    improvement requires commitment.
                  </p>
                  <div className="mt-3 bg-white rounded-lg p-3 border border-amber-200">
                    <div className="flex justify-between items-center mb-1">
                      <span className="text-xs font-medium text-gray-500">
                        Key Factor for Success
                      </span>
                      <span className="text-xs font-bold text-amber-700">
                        Consistent Practice
                      </span>
                    </div>
                    <div className="w-full h-3 bg-gray-200 rounded-full overflow-hidden">
                      <div
                        className="h-full bg-gradient-to-r from-amber-500 to-yellow-500 rounded-full"
                        style={{ width: "60%" }}
                      ></div>
                    </div>
                    <div className="mt-2 flex items-center">
                      <Clock className="h-4 w-4 text-amber-600 mr-1" />
                      <span className="text-xs text-gray-600">
                        Typical study period:{" "}
                        <span className="font-medium">8-12 weeks</span> of
                        regular practice
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-4 pt-6">
              {hasEarlyBirdDiscount && (
                <div className="bg-blue-50 border border-blue-200 rounded-lg p-3 text-blue-700 text-sm flex items-center">
                  <Star className="h-5 w-5 text-blue-500 mr-2 flex-shrink-0" />
                  <span>
                    Congratulations! You qualify for our special 50% Early Bird
                    Discount as one of our first 250 beta users.
                  </span>
                </div>
              )}
              <div className="flex justify-between">
                <Button
                  variant="outline"
                  onClick={prevStep}
                  size="lg"
                  className="font-medium"
                >
                  <ChevronLeft className="mr-2 h-4 w-4" /> Back
                </Button>
                <Button
                  onClick={nextStep}
                  className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-8 font-medium"
                  size="lg"
                >
                  Continue <ChevronRight className="ml-2 h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        );

      case 4:
        // Plan selection
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold text-gray-900">
                Choose Your Plan
              </h3>
              <p className="text-gray-600">
                Select the access duration that fits your journey to{" "}
                {formData.targetScore}+ in CARS
              </p>

              {hasEarlyBirdDiscount && (
                <div className="mt-4 flex flex-col items-center">
                  <div className="inline-block bg-blue-50 border border-blue-200 rounded-lg px-4 py-2 text-blue-700 text-sm mb-3">
                    <div className="flex items-center">
                      <Star className="h-5 w-5 text-yellow-500 mr-2 flex-shrink-0" />
                      <span className="font-medium">
                        You qualify for 50% Early Bird Discount as one of our
                        first 250 beta users!
                      </span>
                    </div>
                  </div>

                  {/* Discount Code Box */}
                  <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 max-w-md mx-auto">
                    <h4 className="font-semibold text-amber-800 mb-1 flex items-center justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-4 w-4 mr-1.5 text-amber-600"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                      Your 50% Beta Discount Code:
                    </h4>
                    <div className="flex items-center justify-between bg-white rounded border border-amber-300 p-2">
                      <code className="font-mono text-base font-semibold text-amber-900 tracking-wide">
                        {BETA_DISCOUNT_CODE}
                      </code>
                      <button
                        onClick={handleCopyCode}
                        className="ml-2 rounded-md p-1 hover:bg-amber-100 text-amber-700 transition-colors"
                        aria-label="Copy discount code"
                      >
                        {copySuccess ? (
                          <span className="text-green-600 flex items-center text-xs font-medium">
                            <CheckCircle className="h-3.5 w-3.5 mr-1" />
                            Copied!
                          </span>
                        ) : (
                          <Copy className="h-3.5 w-3.5" />
                        )}
                      </button>
                    </div>
                    <p className="text-xs text-amber-600 mt-1 text-center">
                      Remember to enter this code at checkout to get your
                      discount
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="grid gap-4 max-w-2xl mx-auto">
              {Object.entries(pricingOptions).map(([planId, plan]) => (
                <div
                  key={planId}
                  className={cn(
                    "relative border rounded-lg p-5 transition-all cursor-pointer hover:shadow-md",
                    selectedPlan === planId
                      ? "border-blue-600 bg-blue-50 shadow-md"
                      : "border-gray-200",
                  )}
                  onClick={() => handlePlanSelect(planId)}
                >
                  {plan.popular && (
                    <div className="absolute -top-3 right-6 bg-gradient-to-r from-blue-600 to-green-600 text-white px-4 py-1 rounded-full text-sm font-bold shadow-sm">
                      Most Popular
                    </div>
                  )}

                  <div className="flex justify-between items-center">
                    <div className="pl-6">
                      <h4 className="font-semibold text-gray-900 text-xl">
                        {plan.label}
                      </h4>
                      <p className="text-gray-500 mt-1">{plan.description}</p>
                    </div>
                    <div className="text-right flex-shrink-0">
                      <div className="flex items-center justify-end space-x-2">
                        <span className="line-through text-red-500 text-sm">
                          ${plan.originalPrice}
                        </span>
                        <span className="text-3xl font-bold text-gray-900">
                          ${plan.price}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">{plan.period}</p>
                    </div>
                  </div>

                  {selectedPlan === planId && (
                    <div className="absolute top-1/2 transform -translate-y-1/2 left-2">
                      <Check className="w-6 h-6 text-blue-600" />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <div className="bg-gradient-to-r from-blue-50 to-green-50 p-5 rounded-lg border border-blue-100 shadow-md max-w-2xl mx-auto">
              <div className="flex items-start">
                <CheckCircle className="h-6 w-6 text-green-600 mr-3 mt-0.5 flex-shrink-0" />
                <div>
                  <h4 className="font-bold text-gray-900 text-lg">
                    Our Guarantee to You
                  </h4>
                  <p className="text-gray-700 mt-2">
                    While traditional MCAT prep courses typically charge $2,000+
                    for generic materials, UPangea provides 13,000+ authentic
                    CARS questions and 24/7 AI tutoring with a satisfaction
                    guarantee.
                  </p>
                  <div className="bg-amber-50 border border-amber-200 rounded-lg p-3 mt-3">
                    <p className="text-amber-800 font-medium flex items-center">
                      <svg
                        className="h-5 w-5 text-amber-600 mr-2 flex-shrink-0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <span>
                        Complete all questions or score in the top 10% to
                        qualify for a full refund
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                variant="outline"
                onClick={prevStep}
                size="lg"
                className="font-medium"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button
                onClick={nextStep}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-8 font-medium"
                size="lg"
              >
                Continue <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        );

      case 5:
        // What you'll get
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold text-gray-900">
                What You'll Get
              </h3>
              <p className="text-gray-600">
                Premium features to boost your CARS performance
              </p>
            </div>

            <div className="space-y-6 max-w-2xl mx-auto">
              <div className="flex items-start bg-white p-5 rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-all">
                <div className="flex-shrink-0 mt-1">
                  <CheckCircle className="h-6 w-6 text-green-500" />
                </div>
                <div className="ml-4">
                  <h4 className="font-semibold text-gray-900 text-lg">
                    13,000+ Authentic CARS Questions
                  </h4>
                  <p className="text-gray-600 mt-2">
                    The most representative, authentic AAMC-style CARS question
                    bank ever created - no more wasting time on unrealistic
                    practice
                  </p>
                </div>
              </div>

              <div className="flex items-start bg-white p-5 rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-all">
                <div className="flex-shrink-0 mt-1">
                  <CheckCircle className="h-6 w-6 text-green-500" />
                </div>
                <div className="ml-4">
                  <h4 className="font-semibold text-gray-900 text-lg">
                    AI Tutor Assistant
                  </h4>
                  <p className="text-gray-600 mt-2">
                    24/7 AI tutor with context of your highlights,
                    strikethroughs, and answer choices - get feedback in seconds
                    instead of waiting days
                  </p>
                </div>
              </div>

              <div className="flex items-start bg-white p-5 rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-all">
                <div className="flex-shrink-0 mt-1">
                  <CheckCircle className="h-6 w-6 text-green-500" />
                </div>
                <div className="ml-4">
                  <h4 className="font-semibold text-gray-900 text-lg">
                    Performance Analytics
                  </h4>
                  <p className="text-gray-600 mt-2">
                    Track your progress and identify improvement areas with
                    detailed analytics - focus your effort where it matters most
                  </p>
                </div>
              </div>

              <div className="flex items-start bg-white p-5 rounded-lg border border-gray-100 shadow-sm hover:shadow-md transition-all">
                <div className="flex-shrink-0 mt-1">
                  <CheckCircle className="h-6 w-6 text-green-500" />
                </div>
                <div className="ml-4">
                  <h4 className="font-semibold text-gray-900 text-lg">
                    Money-Back Guarantee
                  </h4>
                  <p className="text-gray-600 mt-2">
                    Complete all 13,000+ questions OR score in the top 10% on
                    CARS and get your money back - making this a completely
                    risk-free investment in your future
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                variant="outline"
                onClick={prevStep}
                size="lg"
                className="font-medium"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button
                onClick={nextStep}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-8 font-medium"
                size="lg"
              >
                Continue <ChevronRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        );

      case 6:
        // Testimonials
        return (
          <div className="space-y-6 p-6">
            <div className="text-center mb-6">
              <h3 className="text-2xl font-bold text-gray-900">
                Transformations Like Yours
              </h3>
              <p className="text-gray-600">
                Students who achieved their dream scores with UPangea
              </p>
            </div>

            <div className="space-y-5 max-w-2xl mx-auto">
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="bg-gradient-to-r from-blue-50 to-purple-50 rounded-lg p-5 border border-blue-100 shadow-sm hover:shadow-md transition-all"
                >
                  <p className="italic text-gray-700 mb-4 text-lg">
                    &ldquo;{testimonial.quote}&rdquo;
                  </p>
                  <div className="flex items-center">
                    <div className="w-10 h-10 bg-blue-200 rounded-full flex items-center justify-center text-blue-700 font-bold text-lg">
                      {testimonial.name.charAt(0)}
                    </div>
                    <div className="ml-3">
                      <p className="font-semibold text-gray-900">
                        {testimonial.name}
                      </p>
                      <div className="flex items-center gap-2 text-sm">
                        <span className="text-green-600 font-medium">
                          {testimonial.score} in {testimonial.timeframe}
                        </span>
                        <span className="text-blue-600">
                          🎓 {testimonial.dreamOutcome}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="pt-6 max-w-2xl mx-auto">
              <div className="bg-gradient-to-r from-green-50 to-emerald-50 p-5 rounded-lg border border-green-100 shadow-sm">
                <div className="flex items-start">
                  <Medal className="h-7 w-7 text-amber-600 mr-4 flex-shrink-0" />
                  <div>
                    <h4 className="font-semibold text-gray-900 text-lg">
                      Your Future Success Story
                    </h4>
                    <p className="text-gray-700 mt-2">
                      Imagine having your dream score in just a few months, just
                      like these students. With UPangea's authentic practice
                      materials and AI-powered guidance, you're on track to join
                      them with your {formData.targetScore}+ CARS score.
                    </p>
                    <p className="font-medium text-green-700 mt-3">
                      The only question is: when will you start?
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              <Button
                variant="outline"
                onClick={prevStep}
                size="lg"
                className="font-medium"
              >
                <ChevronLeft className="mr-2 h-4 w-4" /> Back
              </Button>
              <Button
                onClick={handleProceedToPayment}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white px-10 py-3 text-lg font-medium rounded-xl hover:shadow-lg transition-all"
              >
                Start Your CARS Practice <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-md md:max-w-xl lg:max-w-3xl xl:max-w-4xl bg-white rounded-xl p-0 max-h-[95vh] overflow-hidden">
          <div className="w-full bg-gradient-to-r from-blue-50 to-green-50 p-3 border-b">
            <div className="flex justify-center items-center">
              <div className="flex items-center space-x-1">
                {[...Array(7)].map((_, i) => (
                  <div
                    key={i}
                    className={`h-1.5 rounded-full transition-all duration-300 ${
                      i <= step ? "w-12 bg-blue-600" : "w-6 bg-gray-200"
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="overflow-y-auto max-h-[calc(95vh-4rem)]">
            {renderStepContent()}
          </div>
        </DialogContent>
      </Dialog>

      {/* Payment modal is conditionally rendered only when open */}
      {isPaymentModalOpen && (
        <PaymentModal
          open={isPaymentModalOpen}
          onOpenChange={handleModalOpenChange}
          plan={selectedPlan}
          planDetails={pricingOptions[selectedPlan]}
          onSuccess={handlePaymentSuccess}
          hasEarlyBirdDiscount={hasEarlyBirdDiscount}
        />
      )}
    </>
  );
};

export default OnboardingFlow;
