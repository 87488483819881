import React, { useState, useEffect, useCallback, useContext } from "react";
import { Button } from "../components/ui/button";
import { cn } from "../lib/utils";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "../components/ui/card";
import { Label } from "../components/ui/label";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "../components/ui/dialog";
import { Input } from "../components/ui/input";
import {
  Lock,
  Unlock,
  Menu,
  BookOpen,
  ChevronDown,
  ChevronRight,
  Plus,
  AlertCircle,
  BarChart2,
  ClipboardList,
  X,
  Settings,
  CreditCard,
  User,
  CheckCircle,
  Settings2,
  Filter,
} from "lucide-react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import logoImage from "../assets/images/5.svg";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceLine,
  Label as RechartsLabel,
} from "recharts";
import PremiumCreateTest from "../components/PremiumCreateTest";
import { SessionContext } from "../index";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ScrollArea } from "../components/ui/scroll-area";
import PaymentModal from "../components/PaymentModal";
import SubscriptionCard from "../components/SubscriptionCard";
import OnboardingFlow from "../components/OnboardingFlow";

// Add this with other constants at the top of the file, before the Dashboard component
const QUESTION_COUNT_INCREMENTS = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 53, 59,
];

const unavailableSubjects = [
  {
    name: "Behavioral Sciences",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Biochemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Biology",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "General Chemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Organic Chemistry",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
  {
    name: "Physics",
    totalQuestions: 0,
    subCategories: [],
    isLocked: true,
    isChecked: false,
  },
];

const ALL_SUBJECTS = [
  "Critical Analysis & Reasoning Skills",
  "Biology",
  "Chemistry",
  "Physics",
  "Psychology",
  "Sociology",
  "Biochemistry",
];

const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");

const FilterDialog = ({
  open,
  onOpenChange,
  selectedDisciplines,
  setSelectedDisciplines,
  selectedDifficulties,
  setSelectedDifficulties,
  disciplines,
  analyzeAvailablePassages,
}) => {
  const [activeTab, setActiveTab] = useState("disciplines"); // Add this state

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px] h-[500px] bg-white overflow-hidden flex flex-col">
        <DialogHeader className="flex-shrink-0 pb-4">
          <DialogTitle className="flex items-center gap-2">
            <Filter className="h-5 w-5" />
            Customize Test Options
          </DialogTitle>
        </DialogHeader>

        {/* Tab Navigation */}
        <div className="flex border-b mb-4">
          <button
            onClick={() => setActiveTab("disciplines")}
            className={`flex items-center gap-2 px-4 py-2 border-b-2 transition-colors ${
              activeTab === "disciplines"
                ? "border-blue-600 text-blue-600"
                : "border-transparent hover:text-blue-600"
            }`}
          >
            <BookOpen className="h-4 w-4" />
            Disciplines
            <span className="ml-1 text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full">
              {Object.values(selectedDisciplines).filter(Boolean).length}
            </span>
          </button>
          <button
            onClick={() => setActiveTab("difficulty")}
            className={`flex items-center gap-2 px-4 py-2 border-b-2 transition-colors ${
              activeTab === "difficulty"
                ? "border-blue-600 text-blue-600"
                : "border-transparent hover:text-blue-600"
            }`}
          >
            <BarChart2 className="h-4 w-4" />
            Difficulty
            <span className="ml-1 text-xs bg-blue-100 text-blue-600 px-2 py-0.5 rounded-full">
              {Object.values(selectedDifficulties).filter(Boolean).length}
            </span>
          </button>
        </div>

        <ScrollArea className="flex-grow px-4">
          {activeTab === "disciplines" ? (
            <div className="space-y-2">
              {[...disciplines].map((discipline) => (
                <div
                  key={discipline}
                  className={`relative flex items-center space-x-2 rounded-lg border p-3 shadow-sm transition-colors
                    ${
                      selectedDisciplines[discipline]
                        ? "border-blue-600 bg-blue-50"
                        : "border-gray-200 hover:border-gray-300"
                    }`}
                >
                  <input
                    type="checkbox"
                    checked={selectedDisciplines[discipline]}
                    onChange={() => {
                      setSelectedDisciplines((prev) => ({
                        ...prev,
                        [discipline]: !prev[discipline],
                      }));
                      analyzeAvailablePassages();
                    }}
                    className="hidden"
                    id={`discipline-${discipline}`}
                  />
                  <label
                    htmlFor={`discipline-${discipline}`}
                    className="flex flex-1 cursor-pointer items-center justify-between"
                  >
                    <span className="text-sm font-medium">{discipline}</span>
                    {selectedDisciplines[discipline] && (
                      <CheckCircle className="h-4 w-4 text-blue-600" />
                    )}
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <div className="space-y-2">
              {Object.keys(selectedDifficulties).map((difficulty) => (
                <div
                  key={difficulty}
                  className={`relative flex items-center space-x-2 rounded-lg border p-3 shadow-sm transition-colors
                    ${
                      selectedDifficulties[difficulty]
                        ? "border-blue-600 bg-blue-50"
                        : "border-gray-200 hover:border-gray-300"
                    }`}
                >
                  <input
                    type="checkbox"
                    checked={selectedDifficulties[difficulty]}
                    onChange={() => {
                      setSelectedDifficulties((prev) => ({
                        ...prev,
                        [difficulty]: !prev[difficulty],
                      }));
                      analyzeAvailablePassages();
                    }}
                    className="hidden"
                    id={`difficulty-${difficulty}`}
                  />
                  <label
                    htmlFor={`difficulty-${difficulty}`}
                    className="flex flex-1 cursor-pointer items-center justify-between"
                  >
                    <span className="text-sm font-medium">{difficulty}</span>
                    {selectedDifficulties[difficulty] && (
                      <CheckCircle className="h-4 w-4 text-blue-600" />
                    )}
                  </label>
                </div>
              ))}
            </div>
          )}
        </ScrollArea>

        <DialogFooter className="flex-shrink-0 pt-4 border-t mt-4">
          <Button variant="secondary" onClick={() => onOpenChange(false)}>
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

function BetaSignupPopup({ isOpen, setIsOpen, session }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    currentScore: "",
    targetScore: "",
    studyHours: "",
    testDate: "",
    prepResources: "",
    why: "",
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasExistingApplication, setHasExistingApplication] = useState(false);
  const [existingApplicationData, setExistingApplicationData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // Check for existing application when popup opens
  useEffect(() => {
    const checkExistingApplication = async () => {
      if (!isOpen || !session?.user?.email) return;

      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("beta_applications")
          .select("*")
          .eq("email", session.user.email)
          .single();

        if (error && error.code !== "PGRST116") {
          // PGRST116 is the "no rows returned" error
          console.error("Error checking application:", error);
          throw error;
        }

        if (data) {
          setHasExistingApplication(true);
          setExistingApplicationData(data);
        } else {
          setHasExistingApplication(false);
          setExistingApplicationData(null);
        }
      } catch (error) {
        console.error("Error:", error);
        setErrorMessage("Error checking application status");
      } finally {
        setIsLoading(false);
      }
    };

    checkExistingApplication();
  }, [isOpen, session?.user?.email]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus(null);

    // Prevent submission if already applied
    if (hasExistingApplication) {
      setErrorMessage("You have already submitted a beta application");
      setSubmitStatus("error");
      return;
    }

    try {
      console.log("Submitting form data:", formData);

      const userEmail = session?.user?.email || formData.email;

      const { data, error } = await supabase
        .from("beta_applications")
        .insert([
          {
            name: formData.name,
            email: userEmail,
            current_score: parseInt(formData.currentScore) || null,
            target_score: parseInt(formData.targetScore),
            study_hours: parseFloat(formData.studyHours),
            test_date: formData.testDate,
            prep_resources: formData.prepResources,
            why: formData.why,
          },
        ])
        .select();

      if (error) {
        console.error("Supabase error details:", error);
        throw error;
      }

      console.log("Successfully submitted application:", data);
      setSubmitStatus("success");
      setFormData({
        name: "",
        email: "",
        currentScore: "",
        targetScore: "",
        studyHours: "",
        testDate: "",
        prepResources: "",
        why: "",
      });

      setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    } catch (error) {
      console.error("Detailed submission error:", error);
      setSubmitStatus("error");
      setErrorMessage(
        error.message || "Error submitting application. Please try again.",
      );
    }
  };

  if (!isOpen) return null;

  // Loading state
  if (isLoading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-4 text-gray-600">Checking application status...</p>
        </div>
      </div>
    );
  }

  // Already submitted state
  if (hasExistingApplication) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-lg shadow-xl max-w-md w-full relative">
          <button
            onClick={() => setIsOpen(false)}
            className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
          </button>
          <div className="p-8">
            <div className="text-center">
              <CheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
              <h2 className="text-2xl font-bold mb-4 text-gray-900">
                Application Already Submitted
              </h2>
              <p className="text-gray-600 mb-6">
                You have already applied to join our beta program on{" "}
                {new Date(
                  existingApplicationData.created_at,
                ).toLocaleDateString()}
              </p>
              <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-6 text-left">
                <h3 className="font-semibold text-blue-900 mb-2">
                  Your Application Details:
                </h3>
                <ul className="space-y-2 text-blue-800">
                  <li>
                    <span className="font-medium">Name:</span>{" "}
                    {existingApplicationData.name}
                  </li>
                  <li>
                    <span className="font-medium">Target Score:</span>{" "}
                    {existingApplicationData.target_score}
                  </li>
                  <li>
                    <span className="font-medium">Study Hours/Week:</span>{" "}
                    {existingApplicationData.study_hours}
                  </li>
                </ul>
              </div>
              <Button
                onClick={() => setIsOpen(false)}
                className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Regular form state
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full relative max-h-[90vh] overflow-y-auto">
        <button
          onClick={() => setIsOpen(false)}
          className="absolute right-4 top-4 text-gray-500 hover:text-gray-700"
        >
          <X className="h-6 w-6" />
        </button>
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-6 text-blue-600">
            Join Our Beta Program
          </h2>
          <p className="text-gray-600 mb-6">
            Get exclusive access to premium features and help shape the future
            of UPangea.
          </p>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="name">Full Name</Label>
                <Input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your full name"
                  required
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="your@email.com"
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="currentScore">Current MCAT Score</Label>
                <Input
                  id="currentScore"
                  name="currentScore"
                  type="number"
                  value={formData.currentScore}
                  onChange={handleChange}
                  placeholder="Current score"
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="targetScore">Target Score</Label>
                <Input
                  id="targetScore"
                  name="targetScore"
                  type="number"
                  value={formData.targetScore}
                  onChange={handleChange}
                  placeholder="Target score"
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <Label htmlFor="studyHours">Study Hours/Week</Label>
                <Input
                  id="studyHours"
                  name="studyHours"
                  type="number"
                  value={formData.studyHours}
                  onChange={handleChange}
                  placeholder="Hours per week"
                  required
                  className="mt-1"
                />
              </div>

              <div>
                <Label htmlFor="testDate">Planned Test Date</Label>
                <Input
                  id="testDate"
                  name="testDate"
                  type="date"
                  value={formData.testDate}
                  onChange={handleChange}
                  required
                  className="mt-1"
                />
              </div>
            </div>

            <div>
              <Label htmlFor="prepResources">Current MCAT Prep Resources</Label>
              <textarea
                id="prepResources"
                name="prepResources"
                value={formData.prepResources}
                onChange={handleChange}
                placeholder="What MCAT prep resources are you currently using?"
                className="w-full min-h-[80px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <div>
              <Label htmlFor="why">
                Why do you want to join our beta program?
              </Label>
              <textarea
                id="why"
                name="why"
                value={formData.why}
                onChange={handleChange}
                placeholder="Tell us why you'd be a great beta tester..."
                required
                className="w-full min-h-[80px] px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>

            <Button
              type="submit"
              className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white 
                hover:from-blue-700 hover:to-green-700 transition-all duration-300"
            >
              Apply for Beta Access
            </Button>

            {submitStatus === "success" && (
              <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                <CheckCircle className="w-5 h-5" />
                <p>Thank you for applying! We'll be in touch soon.</p>
              </div>
            )}
            {submitStatus === "error" && (
              <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center justify-center space-x-2">
                <AlertCircle className="w-5 h-5" />
                <p>{errorMessage}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const stripe_options = {
    // passing the client secret obtained from the server
    clientSecret: "{{CLIENT_SECRET}}",
  };

  const handleSignOut = async () => {
    const { error } = await supabase.auth.signOut();
    navigate("/");
  };

  const handleSubjectCardClick = (subject) => {
    const isPremium = subject !== "Critical Analysis & Reasoning Skills";
    const subjectData = performanceData.find(
      (data) => data.subject === subject,
    ) || {
      score: 0,
      total_questions_answered: 0,
      correct_answers: 0,
    };

    if (
      (isPremium && currentPlan !== "Premium") ||
      subjectData.total_questions_answered === 0
    ) {
      return;
    }

    setSelectedPerformanceSubject(subject);
    setIsPerformanceChartOpen(true);
  };

  const [subjects, setSubjects] = useState([]);
  const [passageTypes, setPassageTypes] = useState([
    { questions: 5, count: 0, available: 0 },
    { questions: 6, count: 0, available: 0 },
    { questions: 7, count: 0, available: 0 },
  ]);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);
  const [activeTab, setActiveTab] = useState("create");
  const [isSubjectSelected, setIsSubjectSelected] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);
  const [previousTests, setPreviousTests] = useState([]);
  const [carsSubject, setCarsSubject] = useState(null);
  const [isQBankExpanded, setIsQBankExpanded] = useState(true);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isResetting, setIsResetting] = useState(false);
  const [isResetConfirmOpen, setIsResetConfirmOpen] = useState(false);
  const [isResetTypeConfirmOpen, setIsResetTypeConfirmOpen] = useState(false);
  const [resetConfirmText, setResetConfirmText] = useState("");
  const [isPremiumPopupOpen, setIsPremiumPopupOpen] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("Free");

  // New state variables for performance chart
  const [selectedPerformanceSubject, setSelectedPerformanceSubject] =
    useState(null);
  const [isPerformanceChartOpen, setIsPerformanceChartOpen] = useState(false);

  // after AI
  const [userId, setUserId] = useState(null);
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [selectedSubjects, setSelectedSubjects] = useState([
    "Critical Analysis & Reasoning Skills",
  ]);
  const [selectedTopics, setSelectedTopics] = useState([
    "Humanities",
    "Social Sciences",
  ]);
  const [testTopics, setTestTopics] = useState({});
  const [testQuestions, setTestQuestions] = useState({});

  // First, let's add loading states
  const [isLoadingTestData, setIsLoadingTestData] = useState(true);

  // Add these new state variables
  const [availablePassages, setAvailablePassages] = useState([]);

  // Add these state variables
  const [selectedPassageCounts, setSelectedPassageCounts] = useState({
    5: 0, // 5-question passages
    6: 0, // 6-question passages
    7: 0, // 7-question passages
  });

  const [isCreatingTest, setIsCreatingTest] = useState(false);
  const [selectedDisciplines, setSelectedDisciplines] = useState({});
  const [disciplines, setDisciplines] = useState(new Set());
  const [selectedDifficulties, setSelectedDifficulties] = useState({
    Challenging: false,
    Moderate: false,
  });

  const [showFilters, setShowFilters] = useState(false);

  // Add this constant near the top with other constants
  const TEST_STYLES = {
    AAMC: {
      name: "AAMC Style",
      description: "Traditional 5-7 question passages following AAMC format",
      questionCounts: [5, 6, 7],
    },
    CUSTOM: {
      name: "Flexible Style",
      description:
        "Variable length passages (3-8 questions) for diverse practice",
      questionCounts: [3, 4, 5, 6, 7, 8],
    },
  };

  // Add this state near other state declarations
  const [testStyle, setTestStyle] = useState("AAMC");

  const showErrorMessage = (message) => {
    setErrorMessage(message);
    setIsErrorModalOpen(true);
  };

  useEffect(() => {
    if (!session) navigate("/");
  }, [session]);

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);

  const togglePlan = () => {
    // Always open onboarding flow which leads to payment
    setIsOnboardingOpen(true);
  };

  useEffect(() => {
    const fetchUserPlan = async () => {
      if (!session || !isUserInitialized) return;

      try {
        // Get the Supabase user ID

        const supabaseUserId = session.user.id;
        setUserId(supabaseUserId);

        // Fetch the user's current plan
        const { data: planData, error: planError } = await supabase
          .from("user_profiles")
          .select("plan")
          .eq("user_id", supabaseUserId)
          .single();

        if (planError) throw planError;

        setCurrentPlan(planData.plan || "Free");
      } catch (error) {
        console.error("Error fetching user plan:", error);
        showErrorMessage("Error fetching user plan");
      }
    };

    fetchUserPlan();
  }, [session]);

  useEffect(() => {
    const initializeUser = async () => {
      if (session && !isUserInitialized) {
        // Check if user mapping exists
        const { data: mappingData, error: mappingError } = await supabase
          .from("user_profiles")
          .select("id")
          .eq("user_id", session.user.id)
          .single();

        if (mappingError && mappingError.code !== "PGRST116") {
          console.error("Error checking user mapping:", mappingError);
          return;
        }

        if (!mappingData) {
          // Create new user in 'users' table
          const { error: userError } = await supabase
            .from("user_profiles")
            .insert({
              user_id: session.user.id,
              email: session.user.email,
              plan: "Free",
            })
            .select()
            .single();
          if (userError) {
            console.error("Error creating user:", userError);
            return;
          }
          console.log("User initialized successfully");
        }
        setIsUserInitialized(true);
      }
    };

    initializeUser();
  }, [session]);

  useEffect(() => {
    const fetchSession = async () => {
      // Instead of a mock user ID, use the authenticated user's ID
      if (session) {
        // Fetch performance data
        const { data: performanceData, error: performanceError } =
          await supabase
            .from("performance_cars")
            .select(
              "id, user_id, subject, total_questions_answered, correct_answers, score",
            )
            .eq("user_id", session.user.id);

        if (performanceError) {
          console.error("Performance data error:", performanceError);
        } else {
          // console.log("Fetched performance data:", performanceData);
          setPerformanceData(performanceData || []);
        }
        // Fetch previous tests
        const { data: testsData, error: testsError } = await supabase
          .from("tests_newest")
          .select("id, user_id, test_date, total_questions, score")
          .eq("user_id", session.user.id)
          .order("test_date", { ascending: false });

        if (testsError) {
          console.error("Tests data error:", testsError);
        } else {
          // console.log("Fetched tests data:", testsData);
          setPreviousTests(testsData || []);
        }
      }

      // Fetch the total number of CARS questions

      const { data: previousTests, error: previousTestsError } = await supabase
        .from("tests_newest")
        .select("passage_ids")
        .eq("user_id", session.user.id);
      if (previousTestsError)
        console.error("Error fetching previous tests:", previousTestsError);
      const completedPassageIds = previousTests?.length
        ? previousTests.flatMap((test) => test.passage_ids || [])
        : [];

      // Initialize arrays to store all questions
      let allQuestionData = [];
      let hasMore = true;
      let page = 0;
      const pageSize = 1000;

      // Fetch all questions using pagination
      while (hasMore) {
        const {
          data: questionData,
          error: questionError,
          count,
        } = await supabase
          .from("new_passages")
          .select("id, passage_topic, question_count", { count: "exact" })
          .not("id", "in", `(${completedPassageIds.join(",")})`)
          .range(page * pageSize, (page + 1) * pageSize - 1);

        if (questionError) {
          console.error("Error fetching question count:", questionError);
          break;
        }

        if (!questionData || questionData.length === 0) {
          hasMore = false;
        } else {
          allQuestionData = [...allQuestionData, ...questionData];
          page++;

          // If we got less than pageSize results, we've reached the end
          if (questionData.length < pageSize) {
            hasMore = false;
          }
        }
      }

      console.log("Total questions fetched:", allQuestionData.length);

      if (!allQuestionData.length) {
        console.error("Error fetching question count");
      } else {
        const totalQuestionsForSubject = allQuestionData.reduce(
          (sum, row) => sum + row.question_count,
          0,
        );
        const totalQuestionsHumanities = allQuestionData
          .filter((row) => row.passage_topic === "Humanities")
          .reduce((sum, row) => sum + row.question_count, 0);
        const totalQuestionsSocialSciences = allQuestionData
          .filter((row) => row.passage_topic === "Social Sciences")
          .reduce((sum, row) => sum + row.question_count, 0);
        setCarsSubject({
          name: "Critical Analysis & Reasoning Skills",
          totalQuestions: totalQuestionsForSubject,
          subCategories: [
            {
              name: "Humanities",
              isChecked: true, // Set to true by default
              questions: totalQuestionsHumanities,
            },
            {
              name: "Social Sciences",
              isChecked: true, // Set to true by default
              questions: totalQuestionsSocialSciences,
            },
          ],
          isLocked: false,
          isChecked: true, // Set to true by default
        });
      }

      // Fetch available passage types
      // const { data: passageData, error: passageError } = await supabase
      //   .from("passages")
      //   .select("id, questions!inner(id)")
      //   .order("id");

      // if (passageError) {
      //   console.error("Error fetching passage data:", passageError);
      // } else {
      //   const availablePassageTypes = passageData.reduce((acc, passage) => {
      //     const questionCount = passage.questions.length;
      //     acc[questionCount] = (acc[questionCount] || 0) + 1;
      //     return acc;
      //   }, {});

      //   setPassageTypes([
      //     { questions: 5, count: 0, available: availablePassageTypes[5] || 0 },
      //     { questions: 6, count: 0, available: availablePassageTypes[6] || 0 },
      //     { questions: 7, count: 0, available: availablePassageTypes[7] || 0 },
      //   ]);
      // }
    };

    fetchSession();
  }, [session]); // Add session to the dependency array

  useEffect(() => {
    if (subjects && carsSubject) {
      // TODO: subjects sometimes is empty! need to figure out why. right after ending a test
      const humanities = subjects[0]?.subCategories[0];
      const socialSciences = subjects[0]?.subCategories[1];
      let total =
        (humanities.isChecked ? humanities.questions : 0) +
        (socialSciences.isChecked ? socialSciences.questions : 0);
      setQuestionCount(total > 59 ? 59 : total);
    }
  }, [subjects, selectedTopics]);

  useEffect(() => {
    if (carsSubject) {
      setSubjects([carsSubject]);
    }
  }, [carsSubject]);

  useEffect(() => {
    const totalCount = passageTypes.reduce((sum, type) => sum + type.count, 0);
    if (totalCount > 0) {
      setSubjects((prevSubjects) =>
        prevSubjects.map((subject) => ({
          ...subject,
          isChecked: subject.subCategories.some(
            (subCategory) => subCategory.isChecked,
          ),
        })),
      );
    }
  }, [passageTypes]);

  useEffect(() => {
    const hasSelectedSubject = subjects.some(
      (subject) =>
        subject.isChecked ||
        subject.subCategories.some((subCategory) => subCategory.isChecked),
    );
    setIsSubjectSelected(hasSelectedSubject);
  }, [subjects]);

  useEffect(() => {
    const fetchTestDetails = async () => {
      if (!previousTests.length) return;

      const topicsMap = {};
      const questionsMap = {};

      for (const test of previousTests) {
        if (!test.passage_ids || !test.passage_ids.length) continue;

        const { data, error } = await supabase
          .from("new_passages")
          .select("passage_topic, question_count")
          .in("id", test.passage_ids);

        if (error) {
          console.error("Error fetching test details:", error);
          continue;
        }

        const topics = [...new Set(data.map((p) => p.topic))];
        const totalQuestions = data.reduce(
          (sum, passage) => sum + passage.question_count,
          0,
        );

        topicsMap[test.id] = topics;
        questionsMap[test.id] = totalQuestions;
      }

      setTestTopics(topicsMap);
      setTestQuestions(questionsMap);
    };

    fetchTestDetails();
  }, [previousTests]);

  // Update the fetchUserData function to include more test details
  const fetchUserData = async () => {
    if (!session) return;

    try {
      setIsLoadingTestData(true);
      const supabaseUserId = session.user.id;

      // Fetch test data with passage details
      const { data: testsData, error: testsError } = await supabase
        .from("tests_newest")
        .select(
          `
          id,
          user_id,
          test_date,
          total_questions,
          score,
          passage_ids,
          subjects
        `,
        )
        .eq("user_id", supabaseUserId)
        .order("test_date", { ascending: false });

      if (testsError) throw testsError;

      if (testsData) {
        // Calculate CARS performance from completed tests only
        const completedTests = testsData.filter(
          (test) => test.score !== null && test.total_questions > 0,
        );

        if (completedTests.length > 0) {
          const totalQuestions = completedTests.reduce(
            (sum, test) => sum + (test.total_questions || 0),
            0,
          );

          const weightedScore = completedTests.reduce(
            (sum, test) =>
              sum + (test.score || 0) * (test.total_questions || 0),
            0,
          );

          const averageScore = Math.round(weightedScore / totalQuestions);

          const carsPerformance = {
            subject: "Critical Analysis & Reasoning Skills",
            total_questions_answered: totalQuestions,
            correct_answers: Math.round((averageScore / 100) * totalQuestions),
            score: averageScore,
          };

          setPerformanceData([carsPerformance]);
        }

        // Enrich test data with passage details
        const enrichedTestsData = await Promise.all(
          testsData.map(async (test) => {
            if (!test.passage_ids || test.passage_ids.length === 0) {
              return test;
            }

            const { data: passagesData, error: passagesError } = await supabase
              .from("new_passages")
              .select(
                `
                passage_topic,
                passage_discipline,
                passage_reading_difficulty,
                question_count
              `,
              )
              .in("id", test.passage_ids);

            if (passagesError) {
              console.error("Error fetching passage details:", passagesError);
              return test;
            }

            // Aggregate passage metadata
            const metadata = passagesData.reduce(
              (acc, passage) => {
                acc.topics.add(passage.passage_topic);
                acc.disciplines.add(passage.passage_discipline);
                acc.difficulties.add(passage.passage_reading_difficulty);
                acc.totalQuestions += passage.question_count;
                return acc;
              },
              {
                topics: new Set(),
                disciplines: new Set(),
                difficulties: new Set(),
                totalQuestions: 0,
              },
            );

            return {
              ...test,
              topics: Array.from(metadata.topics),
              disciplines: Array.from(metadata.disciplines),
              difficulties: Array.from(metadata.difficulties),
              questionCount: metadata.totalQuestions,
            };
          }),
        );

        setPreviousTests(enrichedTestsData);

        // Process test topics and questions maps (for backward compatibility)
        const topicsMap = {};
        const questionsMap = {};

        enrichedTestsData.forEach((test) => {
          topicsMap[test.id] = test.topics || [];
          questionsMap[test.id] =
            test.questionCount || test.total_questions || 0;
        });

        setTestTopics(topicsMap);
        setTestQuestions(questionsMap);
      }
    } catch (error) {
      console.error("Error fetching user data:", error.message);
      if (error.message !== "FetchError: Failed to fetch") {
        showErrorMessage("Error fetching user data");
      }
    } finally {
      setIsLoadingTestData(false);
    }
  };

  // Update the initialization useEffect to prevent multiple fetches
  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (session && isMounted) {
        await fetchUserData();
      }
    };

    initializeData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [session]); // Only depend on session

  // Remove the test_answers_cars subscription since we don't need it anymore
  // Remove or update other subscriptions that might trigger unnecessary refreshes
  useEffect(() => {
    if (!session) return;

    const subscription = supabase
      .channel("test_updates")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "tests_newest",
          filter: `user_id=eq.${session.user.id}`,
        },
        (payload) => {
          // Only fetch if we have a completed test update
          if (
            payload.new &&
            payload.new.user_id === session.user.id &&
            payload.new.score !== null
          ) {
            fetchUserData();
          }
        },
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [session]);

  const toggleSubject = (subjectName) => {
    setSubjects((prevSubjects) => {
      return prevSubjects.map((subject) => {
        if (subject.name === subjectName) {
          const newIsChecked = !subject.isChecked;
          return {
            ...subject,
            isChecked: newIsChecked,
            subCategories: subject.subCategories.map((subCategory) => ({
              ...subCategory,
              isChecked: newIsChecked,
            })),
          };
        }
        return subject;
      });
    });

    setSelectedSubjects((prev) =>
      prev.includes(subjectName)
        ? prev.filter((s) => s !== subjectName)
        : [...prev, subjectName],
    );
    setSelectedTopics((prev) =>
      prev.length === 0 ? ["Humanities", "Social Sciences"] : [],
    );

    // const subject = subjects.find((s) => s.name === subjectName);
    // const newTopics = subject.topics.map((t) => t.name);
    // setSelectedTopics((prev) =>
    //   prev.some((t) => newTopics.includes(t))
    //     ? prev.filter((t) => !newTopics.includes(t))
    //     : [...prev, ...newTopics],
    // );
  };

  const toggleSubCategory = (subjectName, subCategoryName) => {
    setSubjects((prevSubjects) => {
      return prevSubjects.map((subject) => {
        if (subject.name === subjectName) {
          const updatedSubCategories = subject.subCategories.map(
            (subCategory) =>
              subCategory.name === subCategoryName
                ? { ...subCategory, isChecked: !subCategory.isChecked }
                : subCategory,
          );
          return {
            ...subject,
            isChecked: updatedSubCategories.some(
              (subCategory) => subCategory.isChecked,
            ),
            subCategories: updatedSubCategories,
          };
        }
        return subject;
      });
    });

    // Update selected topics
    setSelectedTopics((prev) => {
      const newTopics = prev.includes(subCategoryName)
        ? prev.filter((s) => s !== subCategoryName)
        : [...prev, subCategoryName];

      // If no topics are selected, reset available passages
      if (newTopics.length === 0) {
        setAvailablePassages([]);
        setSelectedPassageCounts({
          5: 0,
          6: 0,
          7: 0,
        });
      }
      analyzeAvailablePassages();

      return newTopics;
    });

    setSelectedSubjects((prev) =>
      prev.length === 0 ? ["Critical Analysis & Reasoning Skills"] : [],
    );
  };

  const handleResetConfirm = () => {
    setIsResetConfirmOpen(false);
    setIsResetTypeConfirmOpen(true);
  };

  const handleResetTypeConfirm = () => {
    if (resetConfirmText.toUpperCase() === "RESET") {
      setIsResetTypeConfirmOpen(false);
      handleResetData();
    }
  };

  const handleResetData = async () => {
    try {
      setIsResetting(true);
      const supabaseUserId = session.user.id;

      // Delete test answers first (they reference tests and questions)
      const { error: deleteTestAnswersError } = await supabase
        .from("test_answers_newest")
        .delete()
        .eq(
          "test_id",
          supabase
            .from("tests_newest")
            .select("id")
            .eq("user_id", supabaseUserId),
        );

      if (deleteTestAnswersError) {
        console.error("Error deleting test answers:", deleteTestAnswersError);
      }

      // Delete feedback next (it references tests)
      const { error: deleteFeedbackError } = await supabase
        .from("feedback_new")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deleteFeedbackError) {
        console.error("Error deleting feedback:", deleteFeedbackError);
      }

      // Delete test progress (it references user)
      const { error: deleteTestProgressError } = await supabase
        .from("test_progress")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deleteTestProgressError) {
        console.error("Error deleting test progress:", deleteTestProgressError);
      }

      // Delete performance data
      const { error: deletePerformanceError } = await supabase
        .from("performance_cars")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deletePerformanceError) {
        console.error("Error deleting performance:", deletePerformanceError);
      }

      // Finally delete tests
      const { error: deleteTestsError } = await supabase
        .from("tests_newest")
        .delete()
        .eq("user_id", supabaseUserId);

      if (deleteTestsError) {
        console.error("Error deleting tests:", deleteTestsError);
      }

      // Reset all relevant state
      setPreviousTests([]);
      setPerformanceData([]);
      setAvailablePassages([]);
      setSelectedPassageCounts({
        5: 0,
        6: 0,
        7: 0,
      });

      // Short timeout to ensure user sees the loading state
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.error("Error resetting data:", error);
      showErrorMessage(
        "An error occurred while resetting your data. Please try again.",
      );
    } finally {
      setIsResetting(false);
    }
  };

  const renderSubjects = (subject) =>
    subject && (
      <Card
        key={subject.name}
        className="transition-all duration-200 hover:shadow-md overflow-hidden border-0 bg-gradient-to-r from-slate-50 to-white"
      >
        <CardHeader className="pb-0">
          <div className="flex items-center justify-between pb-4 border-b border-slate-200">
            <div className="flex items-center gap-3">
              <div className="w-12 h-12 rounded-full bg-emerald-50 flex items-center justify-center text-emerald-600">
                <BookOpen className="w-6 h-6" />
              </div>
              <div>
                <CardTitle className="text-slate-800 font-bold">
                  {subject.name}
                </CardTitle>
                <CardDescription>
                  Select passage topics to include in your test
                </CardDescription>
              </div>
            </div>
            <div className="bg-emerald-50 px-4 py-1.5 rounded-full text-sm font-medium text-emerald-700 flex items-center gap-2">
              <span className="w-2 h-2 rounded-full bg-emerald-500"></span>
              {subject.totalQuestions} questions available
            </div>
          </div>
        </CardHeader>
        <CardContent className="pt-4">
          <div className="space-y-5">
            {/* Topics */}
            <div className="grid gap-3">
              {subject.subCategories.map((subCategory) => (
                <div
                  key={subCategory.name}
                  className={`flex items-center justify-between p-3 rounded-lg transition-colors duration-200 ${
                    subCategory.isChecked
                      ? "bg-emerald-50/50 border border-emerald-200"
                      : "hover:bg-slate-50 border border-slate-200"
                  }`}
                >
                  <div className="flex items-center">
                    <div className="relative mr-4">
                      <input
                        type="checkbox"
                        id={`category-${subCategory.name}`}
                        className="peer absolute opacity-0 w-5 h-5 cursor-pointer"
                        checked={subCategory.isChecked}
                        onChange={() =>
                          toggleSubCategory(subject.name, subCategory.name)
                        }
                        disabled={subCategory.questions === 0}
                      />
                      <div
                        className={`w-5 h-5 border-2 rounded flex items-center justify-center ${
                          subCategory.isChecked
                            ? "bg-emerald-500 border-emerald-500"
                            : "border-slate-300"
                        }`}
                      >
                        {subCategory.isChecked && (
                          <svg
                            width="12"
                            height="12"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20 6L9 17L4 12"
                              stroke="white"
                              strokeWidth="3"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                    <label
                      htmlFor={`category-${subCategory.name}`}
                      className="cursor-pointer"
                    >
                      <span
                        className={`text-base font-medium ${subCategory.isChecked ? "text-emerald-800" : "text-slate-700"}`}
                      >
                        {subCategory.name}
                      </span>
                      <p className="text-xs text-slate-500 mt-0.5">
                        {subCategory.name === "Humanities"
                          ? "Literature, philosophy, ethics, and visual arts passages"
                          : "Psychology, sociology, politics, and anthropology content"}
                      </p>
                    </label>
                  </div>
                  <div
                    className={`text-sm px-3 py-1 rounded-full ${
                      subCategory.isChecked
                        ? "bg-emerald-100 text-emerald-700"
                        : "bg-slate-100 text-slate-600"
                    }`}
                  >
                    {subCategory.questions} questions
                  </div>
                </div>
              ))}
            </div>

            {/* New Customization Button - Only show when topics are selected */}
            {selectedTopics.length > 0 && (
              <div className="pt-5 border-t border-slate-200">
                <Button
                  variant="outline"
                  className={cn(
                    "w-full justify-between shadow-sm border border-slate-200 py-2.5",
                    (Object.values(selectedDisciplines).some((v) => v) ||
                      Object.values(selectedDifficulties).some((v) => v)) &&
                      "border-emerald-300 bg-emerald-50 text-emerald-700",
                  )}
                  onClick={() => setShowFilters(true)}
                >
                  <div className="flex items-center gap-2">
                    <Settings2
                      className={`h-4 w-4 ${
                        Object.values(selectedDisciplines).some((v) => v) ||
                        Object.values(selectedDifficulties).some((v) => v)
                          ? "text-emerald-600"
                          : "text-slate-500"
                      }`}
                    />
                    <span>Customize Test Options</span>
                  </div>
                  <div className="flex items-center gap-2">
                    {(Object.values(selectedDisciplines).some((v) => v) ||
                      Object.values(selectedDifficulties).some((v) => v)) && (
                      <span className="text-xs bg-emerald-600 text-white px-2 py-0.5 rounded-full">
                        Filters Applied
                      </span>
                    )}
                    <ChevronRight className="h-4 w-4" />
                  </div>
                </Button>
              </div>
            )}
          </div>
        </CardContent>

        {/* Add the FilterDialog component */}
        <FilterDialog
          open={showFilters}
          onOpenChange={setShowFilters}
          selectedDisciplines={selectedDisciplines}
          setSelectedDisciplines={setSelectedDisciplines}
          selectedDifficulties={selectedDifficulties}
          setSelectedDifficulties={setSelectedDifficulties}
          disciplines={disciplines}
          analyzeAvailablePassages={analyzeAvailablePassages}
        />
      </Card>
    );

  const updatePassageQuestionCounts = async () => {
    try {
      const { data: passages, error } = await supabase.from("new_passages")
        .select(`
            id,
            new_questions (
              id
            )
          `);

      if (error) throw error;
      console.log("Fetched passages for question count update:", passages);

      for (const passage of passages) {
        const questionCount = passage.new_questions?.length || 0;

        const { error: updateError } = await supabase
          .from("new_passages")
          .update({ question_count: questionCount })
          .eq("id", passage.id);

        if (updateError) {
          console.error(`Error updating passage ${passage.id}:`, updateError);
        }
      }

      console.log("Successfully updated question counts for all passages");
    } catch (error) {
      console.error("Error in updatePassageQuestionCounts:", error);
    }
  };

  const selectRandomPassages = (passages, numPassages) => {
    if (!passages || passages.length < numPassages) {
      throw new Error(
        `Not enough passages available. Needed: ${numPassages}, Available: ${passages?.length}`,
      );
    }

    // Select passages from different clusters to ensure diversity
    const clusterSize = 16; // Size of similar passage clusters
    const selectedPassageIds = [];
    const passagesByCluster = [];

    // Divide passages into clusters of 16
    for (let i = 0; i < passages.length; i += clusterSize) {
      passagesByCluster.push(passages.slice(i, i + clusterSize));
    }

    // First, select one passage randomly from each cluster
    for (let cluster of passagesByCluster) {
      if (selectedPassageIds.length >= numPassages) break;
      if (cluster.length > 0) {
        const randomIndex = Math.floor(Math.random() * cluster.length);
        selectedPassageIds.push(cluster[randomIndex].id);
      }
    }

    // If we still need more passages, add random ones
    if (selectedPassageIds.length < numPassages) {
      // Create a set of already selected IDs for quick lookup
      const selectedIdSet = new Set(selectedPassageIds);

      // Create a shuffled copy of all passage IDs
      const allPassageIds = [...passages].map((p) => p.id);
      for (let i = allPassageIds.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [allPassageIds[i], allPassageIds[j]] = [
          allPassageIds[j],
          allPassageIds[i],
        ];
      }

      // Add passages that haven't been selected yet
      for (const id of allPassageIds) {
        if (!selectedIdSet.has(id)) {
          selectedPassageIds.push(id);
          selectedIdSet.add(id);
          if (selectedPassageIds.length >= numPassages) break;
        }
      }
    }

    // Finally, shuffle the selected passages to randomize their order
    const finalSelectedIds = [...selectedPassageIds];
    for (let i = finalSelectedIds.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [finalSelectedIds[i], finalSelectedIds[j]] = [
        finalSelectedIds[j],
        finalSelectedIds[i],
      ];
    }

    // Trim to the required number
    return finalSelectedIds.slice(0, numPassages);
  };

  const handleCreateTest = async () => {
    if (!session || isCreatingTest) {
      return;
    }

    try {
      setIsCreatingTest(true); // Set loading state
      const supabaseUserId = session.user.id;

      // Get completed passages with proper error handling
      const { data: previousTests, error: previousTestsError } = await supabase
        .from("tests_newest")
        .select("passage_ids")
        .eq("user_id", supabaseUserId);

      if (previousTestsError) {
        console.error("Error fetching previous tests:", previousTestsError);
        throw previousTestsError;
      }

      // Safely extract completed passage IDs, ensuring we have a valid array
      const completedPassageIds = previousTests
        ? previousTests
            .flatMap((test) => test.passage_ids || [])
            .filter(Boolean)
        : [];

      // Selected passages array to store all fetched passages
      const selectedPassages = [];

      // Handle each question count group separately
      for (const [questionCount, numPassages] of Object.entries(
        selectedPassageCounts,
      )) {
        if (numPassages > 0) {
          let query = supabase
            .from("new_passages")
            .select("id")
            .in("passage_topic", selectedTopics);

          // Only apply the completed passages filter if there are completed passages
          if (completedPassageIds.length > 0) {
            query = query.not("id", "in", `(${completedPassageIds.join(",")})`);
          }

          // Apply discipline filters if any are selected
          if (Object.values(selectedDisciplines).some((d) => d)) {
            const selectedDisciplineKeys = Object.entries(selectedDisciplines)
              .filter(([_, isSelected]) => isSelected)
              .map(([key, _]) => key);
            query = query.in("passage_discipline", selectedDisciplineKeys);
          }

          // Apply difficulty filters if any are selected
          if (Object.values(selectedDifficulties).some((d) => d)) {
            const selectedDifficultyKeys = Object.entries(selectedDifficulties)
              .filter(([_, isSelected]) => isSelected)
              .map(([key, _]) => key);
            query = query.in(
              "passage_reading_difficulty",
              selectedDifficultyKeys,
            );
          }

          // Special handling for 5-question group
          if (questionCount === "5") {
            query = query.in("question_count", [4, 5]);
          } else {
            query = query.eq("question_count", parseInt(questionCount));
          }

          // Log the applied filters for debugging
          console.log(
            `Creating test with filters: Topics=${selectedTopics}, ` +
              `Disciplines=${JSON.stringify(
                Object.entries(selectedDisciplines)
                  .filter(([_, v]) => v)
                  .map(([k, _]) => k),
              )}, ` +
              `Difficulties=${JSON.stringify(
                Object.entries(selectedDifficulties)
                  .filter(([_, v]) => v)
                  .map(([k, _]) => k),
              )}`,
          );

          const { data: passages, error: passagesError } = await query;

          if (passagesError) {
            console.error(
              `Error fetching ${questionCount}-question passages:`,
              passagesError,
            );
            throw passagesError;
          }

          if (!passages || passages.length < numPassages) {
            console.error(
              `Not enough ${questionCount}-question passages. Available:`,
              passages?.length,
              "Needed:",
              numPassages,
            );
            showErrorMessage(
              `Not enough ${questionCount}-question passages available with your selected filters. Please try selecting different discipline/difficulty filters or reducing the number of passages.`,
            );
            return;
          }

          try {
            // Use the new utility function
            const passageIds = selectRandomPassages(passages, numPassages);
            selectedPassages.push(...passageIds);
          } catch (selectionError) {
            console.error("Error selecting passages:", selectionError);
            showErrorMessage(selectionError.message);
            return;
          }
        }
      }

      const totalQuestions = Object.entries(selectedPassageCounts).reduce(
        (sum, [questionCount, passages]) => sum + questionCount * passages,
        0,
      );

      const testData = {
        total_questions: totalQuestions,
        passage_ids: selectedPassages,
        user_id: supabaseUserId,
        user_email: session.user.email,
      };

      const { data, error } = await supabase
        .from("tests_newest")
        .insert(testData)
        .select()
        .single();

      if (error) throw error;

      await fetchUserData();
      navigate(`/test/${data.id}`);
    } catch (error) {
      console.error("Error creating test:", error);
      showErrorMessage(
        "Error creating test. Please try again or contact support if the problem persists.",
      );
      setIsCreatingTest(false); // Reset loading state on error
    }
  };

  const handleCreateTestClick = () => {
    setActiveTab("create");
  };

  const toggleQBank = () => {
    setIsQBankExpanded(!isQBankExpanded);
  };

  const hasAvailableQuestions = useCallback(() => {
    return subjects.some((subject) => subject.totalQuestions > 0);
  }, [subjects]);

  // Function to get chart data for a subject
  const getChartData = (subjectName) => {
    const filteredTests = previousTests
      .sort((a, b) => new Date(a.test_date) - new Date(b.test_date))
      .map((test, index) => ({
        date: new Date(test.test_date).toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }),
        score: test.score || 0,
        testNumber: index + 1,
        questions: testQuestions[test.id] || 0,
        topics: testTopics[test.id] ? testTopics[test.id].join(", ") : "",
      }));

    return filteredTests;
  };

  // Add this useEffect back to initialize the data
  useEffect(() => {
    let isMounted = true;

    const initializeData = async () => {
      if (session && isMounted) {
        await fetchUserData();
      }
    };

    initializeData();

    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, [session]); // Add session as dependency

  // Add this function to fetch and analyze available passages
  const analyzeAvailablePassages = async () => {
    if (!session) {
      setAvailablePassages([]);
      return;
    }

    try {
      // First get all completed passages
      const { data: previousTests, error: testsError } = await supabase
        .from("tests_newest")
        .select("passage_ids")
        .eq("user_id", session.user.id);

      if (testsError) throw testsError;

      // Combine all completed passage IDs
      const completedPassageIds =
        previousTests?.flatMap((test) => test.passage_ids || []) || [];

      console.log("Fetching disciplines...");
      // Fetch disciplines with pagination
      let allDisciplines = new Set();
      let hasMoreDisciplines = true;
      let disciplinesPage = 0;
      const pageSize = 1000;

      while (hasMoreDisciplines) {
        const { data: disciplinesData, error: disciplinesError } =
          await supabase
            .from("new_passages")
            .select("passage_discipline", { count: "exact" })
            .in("passage_topic", selectedTopics)
            .range(
              disciplinesPage * pageSize,
              (disciplinesPage + 1) * pageSize - 1,
            );

        if (disciplinesError) throw disciplinesError;

        if (!disciplinesData || disciplinesData.length === 0) {
          hasMoreDisciplines = false;
        } else {
          disciplinesData.forEach((d) =>
            allDisciplines.add(d.passage_discipline),
          );
          disciplinesPage++;

          if (disciplinesData.length < pageSize) {
            hasMoreDisciplines = false;
          }
        }
      }

      setDisciplines(allDisciplines);
      console.log("Disciplines:", allDisciplines);

      // Fetch available passages with pagination
      let allPassages = [];
      let hasMorePassages = true;
      let passagesPage = 0;

      while (hasMorePassages) {
        let query = supabase
          .from("new_passages")
          .select(
            "id, question_count, passage_topic, passage_discipline, passage_reading_difficulty",
          )
          .in("passage_topic", selectedTopics)
          .range(passagesPage * pageSize, (passagesPage + 1) * pageSize - 1);

        // Only filter by completed passages if there are any
        if (completedPassageIds.length > 0) {
          query = query.not("id", "in", `(${completedPassageIds.join(",")})`);
        }

        if (Object.values(selectedDisciplines).some((d) => d)) {
          const selectedDisciplineKeys = Object.entries(selectedDisciplines)
            .filter(([_, isSelected]) => isSelected)
            .map(([key, _]) => key);
          query = query.in("passage_discipline", selectedDisciplineKeys);
        }

        if (Object.values(selectedDifficulties).some((d) => d)) {
          const selectedDifficultyKeys = Object.entries(selectedDifficulties)
            .filter(([_, isSelected]) => isSelected)
            .map(([key, _]) => key);
          query = query.in(
            "passage_reading_difficulty",
            selectedDifficultyKeys,
          );
        }

        const { data: passages, error: passagesError } = await query;

        if (passagesError) throw passagesError;

        if (!passages || passages.length === 0) {
          hasMorePassages = false;
        } else {
          allPassages = [...allPassages, ...passages];
          passagesPage++;

          if (passages.length < pageSize) {
            hasMorePassages = false;
          }
        }
      }

      console.log("Total passages fetched:", allPassages.length);

      // Reset passage counts when available passages change
      setAvailablePassages(allPassages);

      // Reset selected passage counts when available passages change
      setSelectedPassageCounts({
        5: 0,
        6: 0,
        7: 0,
      });
    } catch (error) {
      console.error("Error analyzing passages:", error);
      setAvailablePassages([]);
    }
  };

  // Add useEffect to trigger passage analysis when topics or tests change
  useEffect(() => {
    if (session) {
      analyzeAvailablePassages();
    }
  }, [session, selectedTopics, previousTests.length]); // Add previousTests.length as dependency

  // Update the renderQuestionCountSelector function
  const renderQuestionCountSelector = () => {
    const MAX_QUESTIONS = 53;
    const currentStyle = TEST_STYLES[testStyle];

    // Helper function to get available count for a passage group
    const getAvailableCount = (questionCount) => {
      return availablePassages.filter((p) => p.question_count === questionCount)
        .length;
    };

    // Helper function to get actual questions that would be added
    const getActualQuestionCount = (questionCount, numPassages) => {
      return questionCount * numPassages;
    };

    // Calculate total selected questions
    const totalSelectedQuestions = Object.entries(selectedPassageCounts).reduce(
      (sum, [count, num]) => sum + getActualQuestionCount(Number(count), num),
      0,
    );

    return (
      <div className="space-y-6">
        {/* Test Style Toggle Card */}
        <div className="bg-white rounded-xl border border-slate-200 shadow-sm overflow-hidden">
          <div className="px-6 py-5 border-b border-slate-200 bg-gradient-to-r from-slate-50 to-white">
            <div className="flex items-center justify-between">
              <div>
                <h4 className="font-semibold text-slate-800 mb-1">
                  Format Style
                </h4>
                <p className="text-sm text-slate-500">
                  {currentStyle.description}
                </p>
              </div>
              <div className="flex items-center p-1 bg-slate-100 rounded-lg shadow-inner">
                <button
                  onClick={() => setTestStyle("AAMC")}
                  className={cn(
                    "px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 shadow",
                    testStyle === "AAMC"
                      ? "bg-white text-emerald-700 shadow-sm"
                      : "bg-transparent text-slate-600 hover:text-slate-800",
                  )}
                >
                  <span className="flex items-center gap-1">
                    <CheckCircle
                      className={`w-3.5 h-3.5 ${testStyle === "AAMC" ? "text-emerald-500" : "text-transparent"}`}
                    />
                    AAMC Style
                  </span>
                </button>
                <button
                  onClick={() => setTestStyle("CUSTOM")}
                  className={cn(
                    "px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 shadow",
                    testStyle === "CUSTOM"
                      ? "bg-white text-emerald-700 shadow-sm"
                      : "bg-transparent text-slate-600 hover:text-slate-800",
                  )}
                >
                  <span className="flex items-center gap-1">
                    <CheckCircle
                      className={`w-3.5 h-3.5 ${testStyle === "CUSTOM" ? "text-emerald-500" : "text-transparent"}`}
                    />
                    Flexible Style
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="px-6 py-4 bg-gradient-to-r from-slate-50 to-white">
            <div className="flex items-start gap-3 p-3 bg-amber-50 border border-amber-200 rounded-lg mb-4">
              <AlertCircle className="h-5 w-5 text-amber-500 mt-0.5 flex-shrink-0" />
              <div>
                <p className="text-sm font-medium text-amber-800">
                  Configure your test composition
                </p>
                <p className="text-xs text-amber-700 mt-1">
                  Choose how many passages of each length to include. Maximum{" "}
                  {MAX_QUESTIONS} total questions.
                </p>
              </div>
            </div>

            <div
              className={cn(
                "grid gap-4 mt-6",
                testStyle === "AAMC"
                  ? "grid-cols-1 max-w-3xl mx-auto"
                  : "md:grid-cols-2",
              )}
            >
              {currentStyle.questionCounts.map((questionCount) => {
                const availableCount = getAvailableCount(questionCount);
                const currentTotal = totalSelectedQuestions;
                const remainingQuestions = MAX_QUESTIONS - currentTotal;
                const maxMorePassages = Math.floor(
                  remainingQuestions / questionCount,
                );
                const effectiveMaxPassages = Math.min(
                  availableCount,
                  maxMorePassages + (selectedPassageCounts[questionCount] || 0),
                );

                const isSelected =
                  (selectedPassageCounts[questionCount] || 0) > 0;

                return (
                  <div
                    key={questionCount}
                    className={cn(
                      "rounded-xl border transition-all duration-200",
                      isSelected
                        ? "bg-emerald-50 border-emerald-200 shadow-sm"
                        : "bg-white border-slate-200 hover:border-slate-300",
                    )}
                  >
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center gap-2">
                          <div
                            className={cn(
                              "w-8 h-8 rounded-full flex items-center justify-center text-white text-sm font-bold",
                              isSelected
                                ? "bg-emerald-500"
                                : "bg-slate-200 text-slate-600",
                            )}
                          >
                            {questionCount}
                          </div>
                          <div>
                            <span
                              className={cn(
                                "font-medium",
                                isSelected
                                  ? "text-emerald-800"
                                  : "text-slate-700",
                              )}
                            >
                              {questionCount}-Question Passages
                            </span>
                          </div>
                        </div>
                        <div
                          className={cn(
                            "text-xs px-2 py-1 rounded-full",
                            isSelected
                              ? "bg-emerald-100 text-emerald-700"
                              : "bg-slate-100 text-slate-600",
                          )}
                        >
                          {availableCount} available
                        </div>
                      </div>

                      <div className="flex items-center justify-between mt-4">
                        <div className="text-xs text-slate-500">
                          {isSelected
                            ? `${selectedPassageCounts[questionCount]} passages selected (${selectedPassageCounts[questionCount] * questionCount} questions)`
                            : "No passages selected"}
                        </div>
                        <div className="flex items-center bg-white rounded-lg border border-slate-200 overflow-hidden shadow-sm">
                          <button
                            onClick={() =>
                              setSelectedPassageCounts((prev) => ({
                                ...prev,
                                [questionCount]: Math.max(
                                  0,
                                  (prev[questionCount] || 0) - 1,
                                ),
                              }))
                            }
                            className={cn(
                              "w-8 h-8 flex items-center justify-center border-r border-slate-200",
                              !selectedPassageCounts[questionCount]
                                ? "text-slate-300 cursor-not-allowed"
                                : "text-slate-600 hover:bg-slate-50",
                            )}
                            disabled={!selectedPassageCounts[questionCount]}
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5 12H19"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                          <span className="w-10 text-center font-medium text-slate-800">
                            {selectedPassageCounts[questionCount] || 0}
                          </span>
                          <button
                            onClick={() =>
                              setSelectedPassageCounts((prev) => ({
                                ...prev,
                                [questionCount]: Math.min(
                                  effectiveMaxPassages,
                                  (prev[questionCount] || 0) + 1,
                                ),
                              }))
                            }
                            className={cn(
                              "w-8 h-8 flex items-center justify-center border-l border-slate-200",
                              (selectedPassageCounts[questionCount] || 0) >=
                                effectiveMaxPassages
                                ? "text-slate-300 cursor-not-allowed"
                                : "text-slate-600 hover:bg-slate-50",
                            )}
                            disabled={
                              (selectedPassageCounts[questionCount] || 0) >=
                              effectiveMaxPassages
                            }
                          >
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 5V19"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M5 12H19"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="p-6 border-t border-slate-200 bg-gradient-to-r from-slate-50 to-white">
            <div className="flex justify-between items-center mb-3">
              <div className="flex flex-col">
                <span className="text-sm font-medium text-slate-700">
                  Total Questions
                </span>
                <span className="text-xs text-slate-500">
                  {totalSelectedQuestions > 0
                    ? `${Object.entries(selectedPassageCounts).filter(([_, num]) => num > 0).length} passage type${Object.entries(selectedPassageCounts).filter(([_, num]) => num > 0).length !== 1 ? "s" : ""} selected`
                    : "No passages selected yet"}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span
                  className={cn(
                    "text-lg font-bold",
                    totalSelectedQuestions > 0
                      ? totalSelectedQuestions > MAX_QUESTIONS
                        ? "text-red-600"
                        : "text-emerald-600"
                      : "text-slate-400",
                  )}
                >
                  {totalSelectedQuestions}
                </span>
                <span className="text-slate-400 font-medium">
                  / {MAX_QUESTIONS}
                </span>
              </div>
            </div>

            <div className="h-2.5 bg-slate-100 rounded-full overflow-hidden">
              <div
                className={cn(
                  "h-full rounded-full transition-all duration-300",
                  totalSelectedQuestions > MAX_QUESTIONS
                    ? "bg-red-500"
                    : "bg-gradient-to-r from-emerald-500 to-teal-500",
                )}
                style={{
                  width: `${Math.min(100, (totalSelectedQuestions / MAX_QUESTIONS) * 100)}%`,
                }}
              />
            </div>

            {totalSelectedQuestions > 0 && (
              <Button
                className={cn(
                  "w-full mt-6 text-white relative shadow-md",
                  isCreatingTest
                    ? "bg-slate-400 cursor-not-allowed"
                    : "bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 transition-all duration-200",
                )}
                onClick={handleCreateTest}
                disabled={
                  isCreatingTest || totalSelectedQuestions > MAX_QUESTIONS
                }
              >
                {isCreatingTest ? (
                  <div className="flex items-center justify-center">
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
                    <span>Creating Your Test...</span>
                  </div>
                ) : totalSelectedQuestions > MAX_QUESTIONS ? (
                  <span className="flex items-center gap-2">
                    <AlertCircle className="w-4 h-4" />
                    Too Many Questions Selected
                  </span>
                ) : (
                  <span className="flex items-center gap-2">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 8V16M8 12H16"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Create Test with {totalSelectedQuestions} Questions
                  </span>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  // Add these helper functions after the QUESTION_COUNT_INCREMENTS constant
  const getBellCurveData = (userScore) => {
    const mean = 124.5;
    const stdDev = 2.5;
    const points = 150;
    const range = 15;

    const data = [];
    for (let i = 0; i < points; i++) {
      const x = 117 + (range * i) / (points - 1);
      const y =
        (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
        Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2));

      const percentile = userScore
        ? x < userScore
          ? (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
            Math.exp(-0.5 * Math.pow((x - mean) / stdDev, 2))
          : 0
        : 0;

      data.push({
        score: x,
        frequency: y,
        percentile: percentile,
        isUser: Math.abs(x - userScore) < 0.1,
      });
    }
    return data;
  };

  const getDailyAverageData = (previousTests) => {
    const dailyScores = {};

    previousTests.forEach((test) => {
      if (!test.test_date || !test.score) return;

      const date = new Date(test.test_date).toLocaleDateString();
      if (!dailyScores[date]) {
        dailyScores[date] = {
          total: test.score,
          count: 1,
        };
      } else {
        dailyScores[date].total += test.score;
        dailyScores[date].count += 1;
      }
    });

    const data = Object.entries(dailyScores).map(([date, scores]) => ({
      date: date,
      average: Math.round((scores.total / scores.count) * 10) / 10,
    }));

    return data.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  // Add this helper function before the renderPerformanceSection function
  const calculateMCATScore = (percentage) => {
    if (percentage >= 100) return 132;
    if (percentage >= 99) return 131;
    if (percentage >= 98) return 130;
    if (percentage >= 95) return 129;
    if (percentage >= 90) return 128;
    if (percentage >= 83) return 127;
    if (percentage >= 73) return 126;
    if (percentage >= 61) return 125;
    if (percentage >= 49) return 124;
    if (percentage >= 36) return 123;
    if (percentage >= 25) return 122;
    if (percentage >= 15) return 121;
    if (percentage >= 8) return 120;
    if (percentage >= 3) return 119;
    if (percentage >= 1) return 118;
    if (percentage > 0) return 117;
    return null;
  };

  // Update the renderPerformanceSection function
  const renderPerformanceSection = () => {
    if (isLoadingTestData) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      );
    }

    const carsData = performanceData.find(
      (data) => data.subject === "Critical Analysis & Reasoning Skills",
    ) || {
      score: 0,
      total_questions_answered: 0,
      correct_answers: 0,
    };

    const correctAnswers = carsData.correct_answers || 0;
    const totalAnswered = carsData.total_questions_answered || 0;
    const incorrectAnswers = totalAnswered - correctAnswers;
    const userMcatScore = calculateMCATScore(carsData.score);
    const bellCurveData = getBellCurveData(userMcatScore);
    const dailyAverageData = getDailyAverageData(previousTests);

    return (
      <div className="space-y-8">
        {/* Key Metrics Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          <Card className="bg-gradient-to-b from-emerald-50/60 to-white border border-emerald-100 overflow-hidden rounded-xl shadow-sm">
            <div className="absolute top-0 right-0 h-24 w-24 opacity-10">
              <svg
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5858 13.4142L7.75735 10.5858L6.34314 12L10.5858 16.2427L17.6568 9.17157L16.2426 7.75736L10.5858 13.4142Z"
                  fill="#059669"
                />
                <path
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="#059669"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <CardContent className="pt-6 pb-5 relative">
              <div className="flex flex-col items-center">
                <span className="text-xs font-semibold text-emerald-600 uppercase tracking-wider mb-2 px-3 py-1 bg-emerald-50 rounded-full">
                  Current Score
                </span>
                <div className="text-5xl font-bold text-slate-800 mb-2">
                  {carsData.score || 0}%
                </div>
                <div className="flex items-center gap-1.5 text-sm text-slate-500">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {previousTests[0]?.test_date
                    ? `Updated: ${new Date(previousTests[0]?.test_date).toLocaleDateString("en-US", { month: "short", day: "numeric" })}`
                    : "No tests completed yet"}
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gradient-to-b from-sky-50/60 to-white border border-sky-100 overflow-hidden rounded-xl shadow-sm">
            <div className="absolute top-0 right-0 h-24 w-24 opacity-10">
              <svg
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 6V12L16 14M22 12C22 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="#0284c7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <CardContent className="pt-6 pb-5 relative">
              <div className="flex flex-col items-center">
                <span className="text-xs font-semibold text-sky-600 uppercase tracking-wider mb-2 px-3 py-1 bg-sky-50 rounded-full">
                  Questions Mastered
                </span>
                <div className="flex items-baseline gap-2 mb-1">
                  <span className="text-5xl font-bold text-slate-800">
                    {correctAnswers}
                  </span>
                  <span className="text-xl text-slate-400 font-medium">/</span>
                  <span className="text-3xl font-bold text-slate-600">
                    {totalAnswered}
                  </span>
                </div>
                <div className="flex items-center gap-1.5 text-sm text-slate-500 mt-1">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 17L15 17M9 12L15 12M9 7L15 7M5 22H19C20.1046 22 21 21.1046 21 20V4C21 2.89543 20.1046 2 19 2H5C3.89543 2 3 2.89543 3 4V20C3 21.1046 3.89543 22 5 22Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {carsSubject?.totalQuestions || 0} questions remaining
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="bg-gradient-to-b from-purple-50/60 to-white border border-purple-100 overflow-hidden rounded-xl shadow-sm">
            <div className="absolute top-0 right-0 h-24 w-24 opacity-10">
              <svg
                width="100"
                height="100"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8V16M12 11V16M8 14V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                  stroke="#7c3aed"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <CardContent className="pt-6 pb-5 relative">
              <div className="flex flex-col items-center">
                <span className="text-xs font-semibold text-purple-600 uppercase tracking-wider mb-2 px-3 py-1 bg-purple-50 rounded-full">
                  Estimated MCAT Score
                </span>
                <div className="text-5xl font-bold text-slate-800 mb-1">
                  {userMcatScore || "—"}
                </div>
                <div className="flex items-center gap-1.5 text-sm text-slate-500">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 10V3L4 14H11V21L20 10H13Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {userMcatScore
                    ? `${getPercentileText(userMcatScore)} percentile`
                    : "Complete more tests to estimate"}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Score Distribution Section */}
        <Card className="border border-slate-200/80 shadow-sm rounded-xl overflow-hidden">
          <CardHeader className="border-b border-slate-100 bg-gradient-to-r from-slate-50 to-white pb-4">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-purple-50 flex items-center justify-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-purple-600"
                >
                  <path
                    d="M3 13.2L7.23529 18L21 4.8"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <CardTitle className="text-lg font-bold text-slate-800">
                  MCAT CARS Score Distribution
                </CardTitle>
                <CardDescription className="text-sm text-slate-500">
                  Your estimated score compared to the national MCAT CARS
                  scoring distribution
                </CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent className="p-6">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  data={bellCurveData}
                  margin={{ top: 20, right: 30, left: 20, bottom: 30 }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis
                    dataKey="score"
                    domain={[117, 132]}
                    type="number"
                    tick={{ fontSize: 12, fill: "#64748b" }}
                    axisLine={{ stroke: "#cbd5e1" }}
                    tickLine={{ stroke: "#cbd5e1" }}
                    label={{
                      value: "MCAT CARS Score",
                      position: "bottom",
                      offset: 15,
                      fontSize: 12,
                      fill: "#64748b",
                    }}
                  />
                  <YAxis hide />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        const data = payload[0].payload;
                        return (
                          <div className="bg-white p-3 shadow-lg rounded-lg border border-slate-200">
                            <p className="font-medium text-slate-800 mb-1">
                              Score: {Math.round(data.score)}
                            </p>
                            {data.isUser && (
                              <div className="bg-purple-50 border border-purple-100 rounded-md p-2 mt-1">
                                <p className="text-sm text-purple-700 font-medium flex items-center gap-1.5">
                                  <svg
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M20 6L9 17L4 12"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                  Your Current Score
                                </p>
                                <p className="text-xs text-purple-600 mt-1">
                                  Top{" "}
                                  {Math.round(
                                    (1 - data.percentile / data.frequency) *
                                      100,
                                  )}
                                  % of test takers
                                </p>
                              </div>
                            )}
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <defs>
                    <linearGradient
                      id="bellCurveGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#8b5cf6" stopOpacity={0.4} />
                      <stop
                        offset="100%"
                        stopColor="#8b5cf6"
                        stopOpacity={0.1}
                      />
                    </linearGradient>
                    <linearGradient
                      id="percentileGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#8b5cf6" stopOpacity={0.7} />
                      <stop
                        offset="100%"
                        stopColor="#8b5cf6"
                        stopOpacity={0.3}
                      />
                    </linearGradient>
                  </defs>
                  <Area
                    type="monotone"
                    dataKey="frequency"
                    stroke="#8b5cf6"
                    fill="url(#bellCurveGradient)"
                    strokeWidth={2}
                    dot={false}
                    activeDot={false}
                  />
                  <Area
                    type="monotone"
                    dataKey="percentile"
                    stroke="none"
                    fill="url(#percentileGradient)"
                    fillOpacity={0.6}
                  />
                  {userMcatScore && (
                    <ReferenceLine
                      x={userMcatScore}
                      stroke="#7c3aed"
                      strokeWidth={2}
                      strokeDasharray="3 3"
                      label={{
                        position: "top",
                        value: "Your Score",
                        fill: "#7c3aed",
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      <RechartsLabel
                        value={`${userMcatScore}`}
                        position="bottom"
                        fill="#7c3aed"
                        fontSize={12}
                        fontWeight={"bold"}
                        offset={15}
                      />
                    </ReferenceLine>
                  )}
                </AreaChart>
              </ResponsiveContainer>
            </div>
            {userMcatScore && (
              <div className="mt-4 p-3 bg-purple-50 border border-purple-100 rounded-lg text-sm text-purple-800">
                <div className="flex items-start">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-purple-600 mt-0.5 mr-3 flex-shrink-0"
                  >
                    <path
                      d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>
                    <p className="font-medium">
                      Your estimated score of {userMcatScore} places you in the{" "}
                      {getPercentileText(userMcatScore)} of test takers.
                    </p>
                    <p className="mt-1 text-purple-700">
                      A score of 127 or higher is typically considered
                      competitive for most medical schools.
                    </p>
                  </div>
                </div>
              </div>
            )}
          </CardContent>
        </Card>

        {/* Progress Timeline Section */}
        <Card className="border border-slate-200/80 shadow-sm rounded-xl overflow-hidden">
          <CardHeader className="border-b border-slate-100 bg-gradient-to-r from-slate-50 to-white pb-4">
            <div className="flex items-center gap-3">
              <div className="flex-shrink-0 w-10 h-10 rounded-lg bg-sky-50 flex items-center justify-center">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-sky-600"
                >
                  <path
                    d="M7 12L10 15L15 8M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                    stroke="currentColor"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <CardTitle className="text-lg font-bold text-slate-800">
                  Performance Timeline
                </CardTitle>
                <CardDescription className="text-sm text-slate-500">
                  Track your test scores and progress over time
                </CardDescription>
              </div>
            </div>
          </CardHeader>
          <CardContent className="p-6">
            <div className="h-[300px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={getChartData("Critical Analysis & Reasoning Skills")}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                  <XAxis
                    dataKey="date"
                    stroke="#94a3b8"
                    angle={-35}
                    textAnchor="end"
                    height={60}
                    tick={{ fontSize: 11, fill: "#64748b" }}
                    axisLine={{ stroke: "#cbd5e1" }}
                    tickLine={{ stroke: "#cbd5e1" }}
                  />
                  <YAxis
                    domain={[0, 100]}
                    stroke="#94a3b8"
                    tick={{ fontSize: 11, fill: "#64748b" }}
                    axisLine={{ stroke: "#cbd5e1" }}
                    tickLine={{ stroke: "#cbd5e1" }}
                    label={{
                      value: "Score (%)",
                      angle: -90,
                      position: "insideLeft",
                      style: {
                        textAnchor: "middle",
                        fill: "#64748b",
                        fontSize: 12,
                      },
                      dy: 40,
                      dx: -10,
                    }}
                  />
                  <Tooltip
                    content={({ active, payload }) => {
                      if (active && payload && payload.length) {
                        const data = payload[0].payload;
                        return (
                          <div className="bg-white p-4 shadow-lg rounded-lg border border-slate-200">
                            <div className="flex justify-between items-center mb-2">
                              <span className="font-medium text-slate-700">
                                Test #{data.testNumber}
                              </span>
                              <span className="text-xs text-slate-500 bg-slate-100 rounded-full px-2 py-0.5">
                                {data.date}
                              </span>
                            </div>
                            <div className="space-y-2 mt-3">
                              <div className="bg-sky-50 p-2 rounded-md flex items-center justify-between">
                                <span className="text-sm text-sky-800">
                                  Score:
                                </span>
                                <span className="font-bold text-sky-700">
                                  {data.score}%
                                </span>
                              </div>
                              <div className="flex items-center justify-between text-sm text-slate-600 px-1">
                                <span>Questions:</span>
                                <span className="font-medium">
                                  {data.questions}
                                </span>
                              </div>
                              {data.topics && (
                                <div className="flex flex-col gap-1 text-sm text-slate-600 px-1 pt-1">
                                  <span>Topics:</span>
                                  <div className="flex flex-wrap gap-1 mt-1">
                                    {data.topics.split(", ").map((topic, i) => (
                                      <span
                                        key={i}
                                        className="bg-slate-100 text-slate-700 text-xs px-2 py-0.5 rounded-full"
                                      >
                                        {topic}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    }}
                  />
                  <defs>
                    <linearGradient
                      id="scoreGradient"
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                    >
                      <stop offset="0%" stopColor="#0ea5e9" stopOpacity={0.8} />
                      <stop
                        offset="100%"
                        stopColor="#0ea5e9"
                        stopOpacity={0.2}
                      />
                    </linearGradient>
                  </defs>
                  <Line
                    type="monotone"
                    dataKey="score"
                    stroke="#0ea5e9"
                    strokeWidth={3}
                    dot={{
                      r: 5,
                      strokeWidth: 2,
                      fill: "#fff",
                      stroke: "#0ea5e9",
                    }}
                    activeDot={{
                      r: 7,
                      strokeWidth: 2,
                      fill: "#0ea5e9",
                      stroke: "#fff",
                    }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {getChartData("Critical Analysis & Reasoning Skills").length > 0 ? (
              <div className="mt-4 p-3 bg-sky-50 border border-sky-100 rounded-lg text-sm">
                <div className="flex items-start">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-sky-600 mt-0.5 mr-3 flex-shrink-0"
                  >
                    <path
                      d="M13 16H12V12H11M12 8H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div>
                    <p className="font-medium text-sky-800">
                      {getChartData("Critical Analysis & Reasoning Skills")
                        .length > 1
                        ? `You've completed ${getChartData("Critical Analysis & Reasoning Skills").length} tests with an average score of ${Math.round(getChartData("Critical Analysis & Reasoning Skills").reduce((sum, test) => sum + test.score, 0) / getChartData("Critical Analysis & Reasoning Skills").length)}%.`
                        : "You've completed your first test! Keep practicing to see your progress over time."}
                    </p>
                    <p className="mt-1 text-sky-700">
                      {getChartData("Critical Analysis & Reasoning Skills")
                        .length > 1
                        ? `Your highest score so far is ${Math.max(...getChartData("Critical Analysis & Reasoning Skills").map((test) => test.score))}%.`
                        : "Complete more tests to track your improvement."}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mt-4 p-3 bg-amber-50 border border-amber-100 rounded-lg text-sm text-amber-800">
                <div className="flex items-start">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-amber-600 mt-0.5 mr-3 flex-shrink-0"
                  >
                    <path
                      d="M12 9V13M12 17H12.01M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <p>
                    No test data available yet. Create and complete your first
                    test to start tracking your progress.
                  </p>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    );
  };

  // Helper function to get percentile text
  const getPercentileText = (score) => {
    const percentiles = {
      132: "100th",
      131: "99th",
      130: "98th",
      129: "95th",
      128: "90th",
      127: "83rd",
      126: "73rd",
      125: "61st",
      124: "49th",
      123: "36th",
      122: "25th",
      121: "15th",
      120: "8th",
      119: "3rd",
      118: "1st",
      117: "< 1st",
    };
    return percentiles[score] || "N/A";
  };

  // Update the previous tests section render logic
  const renderPreviousTestsSection = () => {
    if (isLoadingTestData) {
      return (
        <div className="flex justify-center items-center p-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      );
    }

    if (!previousTests || previousTests.length === 0) {
      return (
        <Card className="text-center p-8 bg-gray-50">
          <div className="flex flex-col items-center">
            <ClipboardList className="w-16 h-16 text-gray-400 mb-4" />
            <h4 className="text-xl font-semibold text-gray-900 mb-2">
              No Tests Completed Yet
            </h4>
            <p className="text-gray-600 mb-6 max-w-md mx-auto">
              Start your MCAT CARS practice journey by taking your first test.
              Regular practice is key to improving your score!
            </p>
            <Button
              className="bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white px-8"
              onClick={handleCreateTestClick}
            >
              Create Your First Test
            </Button>
          </div>
        </Card>
      );
    }

    // Helper function to format date
    const formatTestDate = (date) => {
      const testDate = new Date(date);
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);

      // If test was today
      if (testDate.toDateString() === today.toDateString()) {
        return `Today at ${testDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
        })}`;
      }
      // If test was yesterday
      if (testDate.toDateString() === yesterday.toDateString()) {
        return `Yesterday at ${testDate.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "2-digit",
        })}`;
      }
      // Otherwise show full date
      return testDate.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
        year:
          testDate.getFullYear() !== today.getFullYear()
            ? "numeric"
            : undefined,
        hour: "numeric",
        minute: "2-digit",
      });
    };

    // Update the getTestName function to handle multiple subjects/topics better
    const getTestName = (test, index) => {
      const totalTests = previousTests.length;
      const testNumber = totalTests - index;

      // Get all unique disciplines and topics
      const allTopics = [
        ...(test.disciplines || []),
        ...(test.topics || []),
      ].filter(Boolean);

      if (allTopics.length === 0) {
        return `Test ${testNumber}`; // Changed from "Test Set" to "Test"
      }

      if (allTopics.length === 1) {
        return `Test ${testNumber}: ${allTopics[0]}`; // Changed from "Test Set" to "Test"
      }

      // If we have multiple topics, show the first one with an indicator
      return `Test ${testNumber}: ${allTopics[0]} +${allTopics.length - 1}`; // Changed from "Test Set" to "Test"
    };

    // Update the test card rendering to show topics/disciplines more elegantly
    return (
      <div className="space-y-6">
        {previousTests
          .sort((a, b) => new Date(b.test_date) - new Date(a.test_date)) // Ensure chronological order
          .map((test, index) => (
            <Card
              key={test.id}
              className="hover:shadow-lg transition-all duration-200 border border-gray-100"
            >
              <CardContent className="p-6">
                <div className="flex flex-col space-y-4">
                  {/* Header Section */}
                  <div className="flex items-center justify-between">
                    <div className="flex-1">
                      <div className="flex items-center gap-3">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {getTestName(test, index)}
                        </h4>

                        {/* Quick Stats Pills */}
                        <div className="flex items-center gap-2">
                          <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                            {test.questionCount || test.total_questions}{" "}
                            questions
                          </span>
                          <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full">
                            {formatTestDate(test.test_date)}
                          </span>
                        </div>
                      </div>

                      {/* Topics and Disciplines Grid */}
                      {(test.topics?.length > 0 ||
                        test.difficulties?.length > 0) && (
                        <div className="mt-3 flex flex-wrap gap-2">
                          {/* Group by difficulty */}
                          {test.difficulties?.length > 0 && (
                            <div className="flex items-center gap-2">
                              <span className="text-xs text-gray-400">
                                Difficulty:
                              </span>
                              <div className="flex gap-1">
                                {test.difficulties
                                  .slice(0, 2)
                                  .map((difficulty) => (
                                    <span
                                      key={difficulty}
                                      className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-purple-50 text-purple-700"
                                    >
                                      {difficulty}
                                    </span>
                                  ))}
                                {test.difficulties.length > 2 && (
                                  <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-purple-50 text-purple-700">
                                    +{test.difficulties.length - 2}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          {/* Group by topic */}
                          {test.topics?.length > 0 && (
                            <div className="flex items-center gap-2">
                              <span className="text-xs text-gray-400">
                                Topics:
                              </span>
                              <div className="flex gap-1">
                                {test.topics.slice(0, 2).map((topic) => (
                                  <span
                                    key={topic}
                                    className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700"
                                  >
                                    {topic}
                                  </span>
                                ))}
                                {test.topics.length > 2 && (
                                  <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-blue-50 text-blue-700">
                                    +{test.topics.length - 2}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Score and Review Section */}
                    <div className="flex items-center gap-4">
                      <div className="text-center">
                        <div
                          className={`px-4 py-2 rounded-lg ${
                            test.score >= 80
                              ? "bg-green-50 text-green-700 border border-green-200"
                              : test.score >= 65
                                ? "bg-blue-50 text-blue-700 border border-blue-200"
                                : "bg-yellow-50 text-yellow-700 border border-yellow-200"
                          }`}
                        >
                          <div className="text-2xl font-bold">
                            {test.score || test.score === 0
                              ? `${test.score}%`
                              : "N/A"}
                          </div>
                          <div className="text-xs mt-1">
                            {test.score >= 80
                              ? "Excellent"
                              : test.score >= 65
                                ? "Good"
                                : "Review"}{" "}
                            {/* Changed from "Needs Practice" to "Review" */}
                          </div>
                        </div>
                      </div>

                      <div className="flex gap-2">
                        <Button
                          onClick={() => navigate(`/test/${test.id}/summary`)}
                          className="bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 transition-all duration-300 flex items-center gap-2"
                          variant="outline"
                        >
                          <BarChart2 className="w-4 h-4" />
                          Summary
                        </Button>

                        <Button
                          onClick={() => navigate(`/test/${test.id}/review`)}
                          className="bg-white border border-gray-200 hover:bg-gray-50 text-gray-700 transition-all duration-300 flex items-center gap-2"
                          variant="outline"
                        >
                          <BookOpen className="w-4 h-4" />
                          Review
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          ))}
      </div>
    );
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {isSidebarVisible && (
        <div className="w-64 bg-gradient-to-b from-[#0283f9] to-[#11b981] text-white p-6 flex flex-col shadow-xl">
          <div className="flex flex-col items-center mb-8">
            <div className="mb-4">
              <img
                src={logoImage}
                alt="UPangea Logo"
                className="h-28 w-28 object-cover object-center"
              />
            </div>
            <div className="text-center">
              <h1 className="text-3xl font-bold tracking-tight">UPangea</h1>
              <h2 className="text-sm font-medium text-white mt-1 uppercase tracking-wider">
                MCAT Preparation
              </h2>
            </div>
          </div>

          <div className="h-px bg-gradient-to-r from-transparent via-white/30 to-transparent mb-6"></div>

          <nav className="flex-grow">
            <div className="space-y-1.5 mb-6">
              <h3 className="text-xs font-semibold uppercase text-white/70 tracking-wider px-2">
                Main
              </h3>
              <ul className="space-y-2">
                <li>
                  <button
                    onClick={toggleQBank}
                    className="w-full flex items-center justify-between py-3 px-4 rounded-lg text-base font-medium transition-colors hover:bg-white/10 group"
                  >
                    <div className="flex items-center">
                      <BookOpen className="w-5 h-5 mr-3 text-white group-hover:text-white" />
                      <span className="group-hover:text-white">
                        Question Bank
                      </span>
                    </div>
                    {isQBankExpanded ? (
                      <ChevronDown className="w-5 h-5 text-white/80" />
                    ) : (
                      <ChevronRight className="w-5 h-5 text-white/80" />
                    )}
                  </button>
                </li>
                {isQBankExpanded && (
                  <div className="pl-10 space-y-2 mt-2">
                    <button
                      onClick={() => setActiveTab("create")}
                      className={`w-full text-left py-2.5 px-4 rounded-lg text-base transition-colors ${
                        activeTab === "create"
                          ? "bg-white/30 text-white font-semibold"
                          : "text-white hover:text-white hover:bg-white/10 font-medium"
                      }`}
                    >
                      <Plus className="w-4 h-4 inline-block mr-2.5 align-text-bottom" />
                      Create Test
                    </button>
                    <button
                      onClick={() => setActiveTab("previous")}
                      className={`w-full text-left py-2.5 px-4 rounded-lg text-base transition-colors ${
                        activeTab === "previous"
                          ? "bg-white/30 text-white font-semibold"
                          : "text-white hover:text-white hover:bg-white/10 font-medium"
                      }`}
                    >
                      <ClipboardList className="w-4 h-4 inline-block mr-2.5 align-text-bottom" />
                      Previous Tests
                    </button>
                    <button
                      onClick={() => setActiveTab("performance")}
                      className={`w-full text-left py-2.5 px-4 rounded-lg text-base transition-colors ${
                        activeTab === "performance"
                          ? "bg-white/30 text-white font-semibold"
                          : "text-white hover:text-white hover:bg-white/10 font-medium"
                      }`}
                    >
                      <BarChart2 className="w-4 h-4 inline-block mr-2.5 align-text-bottom" />
                      Performance
                    </button>
                  </div>
                )}
              </ul>
            </div>
          </nav>

          <div className="mt-auto pt-6 border-t border-white/20">
            <Button
              variant="outline"
              className="w-full text-white border-white/30 bg-transparent hover:bg-white/10 hover:text-white hover:border-white/50 text-xs font-medium"
              onClick={() => setIsResetConfirmOpen(true)}
              disabled={isResetting}
            >
              {isResetting ? (
                <div className="flex items-center justify-center">
                  <div className="animate-spin rounded-full h-3 w-3 border-b-2 border-white mr-2"></div>
                  <span>Resetting...</span>
                </div>
              ) : (
                "Reset All Progress"
              )}
            </Button>
          </div>
        </div>
      )}

      <div className="flex-1 flex flex-col">
        <header className="bg-white shadow-md z-10">
          <div className="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => setIsSidebarVisible(!isSidebarVisible)}
                  className="mr-3 h-9 w-9 rounded-full hover:bg-slate-100"
                >
                  <Menu className="h-5 w-5 text-slate-600" />
                </Button>
                <div className="flex flex-col">
                  <h2 className="text-xl font-bold text-slate-800">
                    {activeTab === "create"
                      ? "Create Test"
                      : activeTab === "previous"
                        ? "Previous Tests"
                        : "Performance Analytics"}
                  </h2>
                  <p className="text-xs text-slate-500 hidden sm:block">
                    {activeTab === "create"
                      ? "Build a custom test to practice your MCAT CARS skills"
                      : activeTab === "previous"
                        ? "Review and analyze your past test attempts"
                        : "Track your progress and identify improvement areas"}
                  </p>
                </div>
              </div>

              <div className="flex items-center gap-4">
                {session && (
                  <>
                    <div className="hidden md:flex items-center gap-3 px-3 py-1.5 bg-slate-50 rounded-lg border border-slate-200">
                      <div className="relative w-7 h-7 rounded-full bg-emerald-100 flex items-center justify-center overflow-hidden">
                        <User className="w-4 h-4 text-emerald-700" />
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xs text-slate-500">Welcome</span>
                        <span className="text-sm font-medium text-slate-700 truncate max-w-[150px]">
                          {session.user.email}
                        </span>
                      </div>
                    </div>

                    <div className="flex items-center">
                      <div className="px-3 py-1.5 rounded-full bg-gradient-to-r from-emerald-500 to-teal-600 text-white text-sm font-medium shadow-md flex items-center gap-2">
                        <span className="w-2 h-2 rounded-full bg-white animate-pulse"></span>
                        <span className="font-medium">Premium</span>
                      </div>
                    </div>
                  </>
                )}

                <Button
                  onClick={handleSignOut}
                  variant="outline"
                  className="text-slate-700 border-slate-300 hover:bg-slate-50 gap-2 text-sm flex items-center px-3 py-1.5 h-auto"
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-slate-600"
                  >
                    <path
                      d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16 17L21 12L16 7"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M21 12H9"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span>Log Out</span>
                </Button>
              </div>
            </div>
          </div>
        </header>

        <main className="flex-1 overflow-y-auto p-6 bg-slate-50/80">
          <div className="bg-white rounded-xl shadow-sm border border-slate-200/60 p-6 max-w-5xl mx-auto">
            {/* Subscription Card - removed as we now implement a complete paywall */}

            {activeTab === "create" && (
              <>
                {/* Update grid to single column */}
                <div className="mb-8">
                  <h3 className="text-xl font-bold mb-4 text-slate-800 flex items-center gap-2">
                    <div className="w-8 h-8 rounded-md bg-slate-100 flex items-center justify-center text-emerald-600">
                      <span className="text-lg font-bold">1</span>
                    </div>
                    Select Passage Topics
                  </h3>
                  {renderSubjects(subjects[0])}
                </div>

                <div className="h-px bg-gradient-to-r from-transparent via-slate-200 to-transparent my-8" />

                <div
                  className={`mb-8 transition-all duration-300 ${
                    isSubjectSelected ? "" : "opacity-40 pointer-events-none"
                  }`}
                >
                  <div>
                    <h3 className="text-xl font-bold mb-4 text-slate-800 flex items-center gap-2">
                      <div className="w-8 h-8 rounded-md bg-slate-100 flex items-center justify-center text-emerald-600">
                        <span className="text-lg font-bold">2</span>
                      </div>
                      Configure Your Test
                    </h3>
                    {renderQuestionCountSelector()}
                  </div>

                  <div className="h-px bg-gradient-to-r from-transparent via-slate-200 to-transparent my-8" />
                </div>

                {!hasAvailableQuestions() && (
                  <div className="mt-4 p-5 bg-amber-50 border border-amber-200 rounded-xl text-amber-800">
                    <div className="flex items-start gap-3">
                      <div className="mt-1">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 9V13M12 17H12.01M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#92400E"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold text-amber-900 mb-1">
                          No questions available
                        </p>
                        <p className="text-amber-800">
                          You have completed all available questions in our
                          database. Please check back later as we regularly add
                          new content.
                        </p>
                        <div className="mt-3 pt-3 border-t border-amber-200">
                          <p className="text-sm">
                            Consider resetting your progress to practice with
                            the same questions again.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {activeTab === "previous" && (
              <div>
                <h3 className="text-xl font-bold mb-6 text-slate-800 flex items-center gap-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5"
                      stroke="#10B981"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 12H15M9 16H15"
                      stroke="#10B981"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Test History & Analysis
                </h3>
                {renderPreviousTestsSection()}
              </div>
            )}

            {activeTab === "performance" && (
              <div>
                <h3 className="text-xl font-bold mb-6 text-slate-800 flex items-center gap-2">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 8V16M12 11V16M8 14V16"
                      stroke="#10B981"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3 8L3 16C3 18.2091 4.79086 20 7 20L17 20C19.2091 20 21 18.2091 21 16L21 8C21 5.79086 19.2091 4 17 4L7 4C4.79086 4 3 5.79086 3 8Z"
                      stroke="#10B981"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Performance Analytics
                </h3>
                {renderPerformanceSection()}
              </div>
            )}
          </div>
        </main>
      </div>

      {/* Performance Chart Dialog */}
      <Dialog
        open={isPerformanceChartOpen}
        onOpenChange={setIsPerformanceChartOpen}
      >
        <DialogContent className="sm:max-w-3xl bg-white">
          <DialogHeader>
            <DialogTitle className="text-gray-900">
              CARS Performance Over Time
            </DialogTitle>
            <DialogDescription className="text-gray-600">
              Track your CARS test performance and progress over time.
            </DialogDescription>
          </DialogHeader>
          <div className="w-full h-[400px]">
            {" "}
            {/* Increased height for better visibility */}
            {selectedPerformanceSubject ? (
              getChartData(selectedPerformanceSubject).length > 0 ? (
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart
                    data={getChartData(selectedPerformanceSubject)}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 60,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
                    <XAxis
                      dataKey="date"
                      stroke="#4B5563"
                      angle={-45}
                      textAnchor="end"
                      height={60}
                      tick={{ fontSize: 12 }}
                    />
                    <YAxis
                      domain={[0, 100]}
                      stroke="#4B5563"
                      tick={{ fontSize: 12 }}
                      label={{
                        value: "Score (%)",
                        angle: -90,
                        position: "insideLeft",
                        style: { textAnchor: "middle" },
                      }}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "white",
                        border: "1px solid #E5E7EB",
                        borderRadius: "6px",
                        padding: "10px",
                      }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <div className="bg-white p-4 shadow-lg rounded-lg border border-gray-200">
                              <p className="font-semibold text-gray-900">
                                Test #{data.testNumber}
                              </p>
                              <p className="text-gray-600">Date: {data.date}</p>
                              <p className="text-blue-600 font-bold">
                                Score: {data.score}%
                              </p>
                              <p className="text-gray-600">
                                Questions: {data.questions}
                              </p>
                              {data.topics && (
                                <p className="text-gray-600">
                                  Topics: {data.topics}
                                </p>
                              )}
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#3b82f6"
                      strokeWidth={3}
                      dot={{ r: 6, fill: "#3b82f6", strokeWidth: 2 }}
                      activeDot={{ r: 8, fill: "#2563eb" }}
                      connectNulls
                    />
                  </LineChart>
                </ResponsiveContainer>
              ) : (
                <div className="flex flex-col items-center justify-center h-full text-gray-600">
                  <ClipboardList className="w-16 h-16 text-gray-400 mb-4" />
                  <p className="text-lg font-semibold mb-2">
                    No Test Data Available
                  </p>
                  <p className="text-center text-sm">
                    Complete your first CARS test to start tracking your
                    performance.
                  </p>
                  <Button
                    className="mt-4 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white"
                    onClick={() => {
                      setIsPerformanceChartOpen(false);
                      setActiveTab("create");
                    }}
                  >
                    Create a Test
                  </Button>
                </div>
              )
            ) : (
              <div className="flex items-center justify-center h-full text-gray-600">
                <p>Select a subject to view performance data.</p>
              </div>
            )}
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsPerformanceChartOpen(false)}
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Login Dialog */}
      <Dialog open={isLoginDialogOpen} onOpenChange={setIsLoginDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Log in or Sign up</DialogTitle>
            <DialogDescription>
              Access your UPangea account or create a new one.
            </DialogDescription>
          </DialogHeader>
          <div className="grid w-full grid-cols-2">
            <button
              onClick={() => setActiveTab("login")}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === "login"
                  ? "bg-white bg-opacity-20 text-white"
                  : "text-gray-200 hover:bg-white hover:bg-opacity-10"
              }`}
            >
              Login
            </button>
            <button
              onClick={() => setActiveTab("register")}
              className={`w-full text-left py-2 px-4 rounded transition-colors duration-200 ${
                activeTab === "register"
                  ? "bg-white bg-opacity-20 text-white"
                  : "text-gray-200 hover:bg-white hover:bg-opacity-10"
              }`}
            >
              Register
            </button>
          </div>
          {activeTab === "login" && (
            <Card>
              <CardHeader>
                <CardTitle>Login</CardTitle>
                <CardDescription>
                  Enter your email and password to access your account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" placeholder="m@example.com" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="password">Password</Label>
                  <Input id="password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                  Log in
                </Button>
              </CardFooter>
            </Card>
          )}
          {activeTab === "register" && (
            <Card>
              <CardHeader>
                <CardTitle>Create an account</CardTitle>
                <CardDescription>
                  Enter your details to create a new account.
                </CardDescription>
              </CardHeader>
              <CardContent className="space-y-2">
                <div className="space-y-1">
                  <Label htmlFor="new-email">Email</Label>
                  <Input
                    id="new-email"
                    type="email"
                    placeholder="m@example.com"
                  />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="new-password">Password</Label>
                  <Input id="new-password" type="password" />
                </div>
                <div className="space-y-1">
                  <Label htmlFor="confirm-password">Confirm Password</Label>
                  <Input id="confirm-password" type="password" />
                </div>
              </CardContent>
              <CardFooter>
                <Button className="w-full bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                  Sign Up
                </Button>
              </CardFooter>
            </Card>
          )}
        </DialogContent>
      </Dialog>

      {/* Profile Dialog */}
      <Dialog open={isProfileOpen} onOpenChange={setIsProfileOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Profile</DialogTitle>
            <DialogDescription>
              View and manage your UPangea profile and subscription.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            {session ? (
              <>
                <div className="flex items-center gap-4">
                  <div className="h-16 w-16 bg-gray-200 rounded-full flex items-center justify-center">
                    <User className="w-8 h-8 text-gray-500" />
                  </div>
                  <div>
                    <h3 className="font-medium">{session.user.email}</h3>
                    <p className="text-sm text-gray-500">
                      {session.user.email}
                    </p>
                  </div>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Current Plan</h4>
                  <p className="text-sm text-gray-500">Free</p>
                  <Button className="mt-2 bg-gradient-to-r from-blue-600 to-teal-500 hover:from-blue-700 hover:to-teal-600 text-white">
                    Upgrade to Premium
                  </Button>
                </div>
                <hr />
                <div>
                  <h4 className="font-medium mb-2">Account Settings</h4>
                  <Button variant="outline" className="w-full justify-start">
                    <Settings className="mr-2 h-4 w-4" />
                    Edit Profile
                  </Button>
                  <Button
                    variant="outline"
                    className="w-full justify-start mt-2"
                  >
                    <CreditCard className="mr-2 h-4 w-4" />
                    Manage Subscription
                  </Button>
                </div>
              </>
            ) : (
              <p>Please log in to view your profile.</p>
            )}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsProfileOpen(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onOpenChange={setIsErrorModalOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Error</DialogTitle>
          </DialogHeader>
          <p>{errorMessage}</p>
          <DialogFooter>
            <Button onClick={() => setIsErrorModalOpen(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Initial Reset Confirmation Dialog */}
      <Dialog open={isResetConfirmOpen} onOpenChange={setIsResetConfirmOpen}>
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Confirm Reset</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            Are you sure you want to reset all your progress? This action cannot
            be undone.
          </DialogDescription>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsResetConfirmOpen(false)}
            >
              Cancel
            </Button>
            <Button onClick={handleResetConfirm}>Confirm</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Type Confirmation Dialog */}
      <Dialog
        open={isResetTypeConfirmOpen}
        onOpenChange={setIsResetTypeConfirmOpen}
      >
        <DialogContent className="bg-white">
          <DialogHeader>
            <DialogTitle>Final Confirmation</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            To reset all progress, please type &quot;RESET&quot; in the box
            below:
          </DialogDescription>
          <Input
            value={resetConfirmText}
            onChange={(e) => setResetConfirmText(e.target.value)}
            placeholder="Type RESET here"
          />
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsResetTypeConfirmOpen(false)}
            >
              Cancel
            </Button>
            <Button
              onClick={handleResetTypeConfirm}
              disabled={resetConfirmText.toUpperCase() !== "RESET"}
            >
              Reset All Progress
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Onboarding flow before payment */}
      <OnboardingFlow
        open={isOnboardingOpen}
        onOpenChange={setIsOnboardingOpen}
        session={session}
        onComplete={() => {
          // After completing onboarding and payment, refresh
          window.location.href = "/payment-success";
        }}
      />

      {/* Direct payment modal (used from subscription card) */}
      <PaymentModal
        open={isPaymentModalOpen}
        onOpenChange={setIsPaymentModalOpen}
        onSuccess={() => {
          setIsPaymentModalOpen(false);
          // Force refresh user plan after successful payment
          window.location.href = "/payment-success";
        }}
      />
    </div>
  );
}
