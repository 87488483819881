import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { cn } from "../lib/utils";
import { Button } from "../components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "../components/ui/card";
import {
  BarChart2,
  BookOpen,
  CheckCircle,
  Clock,
  Flag,
  CircleX,
  Brain,
  Search,
  MessageCircle,
  FileText,
} from "lucide-react";
import { supabase } from "../supabaseClient";
import { SessionContext } from "../index";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../components/ui/select";
import { ScrollArea } from "../components/ui/scroll-area";
import { Input } from "../components/ui/input";

// Optional helper to format roman numeral questions
const formatRomanNumeralQuestion = (text) => {
  const lines = text.split("\n");
  if (lines.length >= 5) {
    const hasRomanNumerals =
      lines[2]?.trim().startsWith("I.") &&
      lines[3]?.trim().startsWith("II.") &&
      lines[4]?.trim().startsWith("III.");
    if (hasRomanNumerals) {
      return [
        lines[0],
        lines[1],
        `\u2003\u2003${lines[2]}`,
        `\u2003\u2003${lines[3]}`,
        `\u2003\u2003${lines[4]}`,
        ...lines.slice(5),
      ].join("\n");
    }
  }
  return text;
};

// Correct component name
export default function TestSummary({ isReviewMode: propIsReviewMode = false }) {
  const { testId } = useParams();
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  const [testData, setTestData] = useState(null);
  const [passageAnalysis, setPassageAnalysis] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [filters, setFilters] = useState({
    questionType: "all",
    difficulty: "all",
    confidence: "all",
    result: "all",
  });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (!session) {
      navigate("/");
      return;
    }

    const fetchTestData = async () => {
      try {
        // 1. Fetch the main test record
        const { data: test, error: testError } = await supabase
          .from("tests_newest")
          .select("*, ai_summary") // ai_summary is actually no longer needed
          .eq("id", testId)
          .single();

        if (testError) throw testError;

        // 2. Fetch the passages
        const { data: passages, error: passagesError } = await supabase
          .from("new_passages")
          .select(
            `
              *,
              new_questions (
                id,
                text,
                correct_answer
              )
            `,
          )
          .in("id", test.passage_ids);

        if (passagesError) throw passagesError;

        // 3. Fetch the test answers
        const { data: answers, error: answersError } = await supabase
          .from("test_answers_newest")
          .select("*")
          .eq("test_id", testId);

        if (answersError) throw answersError;

        // Enrich the test object
        const enrichedTest = {
          ...test,
          passages,
          answers,
          totalPassages: passages.length,
          totalQuestions: passages.reduce(
            (sum, p) => sum + p.new_questions.length,
            0,
          ),
          correctAnswers: answers.filter((a) => a.is_correct).length,
          flaggedQuestions: answers.filter((a) => a.flagged).length,
          confidenceData: {
            High: answers.filter((a) => a.confidence_rating === "High").length,
            Medium: answers.filter((a) => a.confidence_rating === "Medium")
              .length,
            Low: answers.filter((a) => a.confidence_rating === "Low").length,
          },
        };

        setTestData(enrichedTest);

        // 4. Fetch detailed passage data
        if (test.passage_ids && test.passage_ids.length > 0) {
          const { data: passagesData, error: passagesError2 } = await supabase
            .from("new_passages")
            .select(
              `
                id,
                passage_topic,
                passage_discipline,
                passage_reading_difficulty,
                new_questions (
                  id,
                  text
                )
              `,
            )
            .in("id", test.passage_ids);

          if (passagesError2) throw passagesError2;

          // 5. Reuse the answers from above for final combination
          const passageDetails = passagesData.map((passage) => {
            const passageAnswers = answers.filter(
              (ans) => ans.passage_id === passage.id,
            );
            const correctAnswers = passageAnswers.filter((ans) => ans.is_correct)
              .length;

            return {
              ...passage,
              accuracy: (correctAnswers / passage.new_questions.length) * 100,
              totalQuestions: passage.new_questions.length,
              correctAnswers,
            };
          });

          setPassageAnalysis(passageDetails);
        }
      } catch (error) {
        console.error("Error fetching test data:", error);
      }
    };

    fetchTestData();
  }, [testId, session, navigate]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        if (!testData?.passages) return;

        // 6. Fetch the more detailed question info
        const { data: questionsData, error: questionsError } = await supabase
          .from("new_questions")
          .select(
            `
              id,
              text,
              question_type,
              question_difficulty,
              correct_answer
            `,
          )
          .in(
            "id",
            testData.passages.flatMap((p) => p.new_questions.map((q) => q.id)),
          );

        if (questionsError) throw questionsError;

        // Combine each question with the user's answer
        const enrichedQuestions = questionsData.map((q) => {
          const match = testData.answers.find((a) => a.question_id === q.id);
          return {
            ...q,
            confidence: match?.confidence_rating || "Not Rated",
            isCorrect: match?.is_correct || false,
            selectedAnswer: match?.selected_answer,
          };
        });

        setQuestions(enrichedQuestions);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [testData]);

  // Renders each passage's analysis card
  const renderPassageAnalysis = () => {
    if (!passageAnalysis.length) return null;

    return (
      <Card className="border border-slate-200/60 shadow-sm rounded-xl overflow-hidden relative">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-sky-500 to-indigo-500"></div>
        <CardHeader className="border-b border-slate-100 bg-gradient-to-r from-slate-50 to-white pb-4">
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-lg bg-sky-50 flex items-center justify-center">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-sky-500">
                <path d="M19.5 14.25V11.625C19.5 9.76104 17.989 8.25 16.125 8.25H14.625C14.0037 8.25 13.5 7.74632 13.5 7.125V5.625C13.5 3.76104 11.989 2.25 10.125 2.25H8.25M8.25 15H15.75M8.25 18H12M10.5 2.25H5.625C5.00368 2.25 4.5 2.75368 4.5 3.375V20.625C4.5 21.2463 5.00368 21.75 5.625 21.75H18.375C18.9963 21.75 19.5 21.2463 19.5 20.625V11.25C19.5 6.27944 15.4706 2.25 10.5 2.25ZM9.25 11H14.75M9.25 8H14.75M9.25 14H11.75M5.75 21H18.25C19.3546 21 20.25 20.1046 20.25 19V5C20.25 3.89543 19.3546 3 18.25 3H5.75C4.64543 3 3.75 3.89543 3.75 5V19C3.75 20.1046 4.64543 21 5.75 21ZM17 17H21M17 17C17 15.3431 15.6569 14 14 14C12.3431 14 11 15.3431 11 17C11 18.6569 12.3431 20 14 20C15.6569 20 17 18.6569 17 17ZM3 17H7M21 7H17M14 7H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <CardTitle className="text-lg font-bold text-slate-800">
                Passage Performance
              </CardTitle>
              <CardDescription className="text-slate-500 mt-1">
                How well you did on each reading passage
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
            {passageAnalysis.map((passage, index) => {
              // Determine color theme based on accuracy
              const colorTheme = passage.accuracy >= 80 
                ? "emerald" 
                : passage.accuracy >= 60 
                  ? "amber" 
                  : "rose";
                  
              // Create dynamic class strings
              const gradientBg = `bg-gradient-to-br from-${colorTheme}-50 to-white`;
              const borderColor = `border-${colorTheme}-100`; 
              const iconColor = `text-${colorTheme}-500`;
              const headingColor = `text-${colorTheme}-900`;
              const secondaryTextColor = `text-${colorTheme}-700`;
              
              return (
                <div
                  key={passage.id}
                  className={`rounded-xl border ${borderColor} ${gradientBg} overflow-hidden shadow-sm relative`}
                >
                  <div className={`p-4 flex flex-col h-full`}>
                    {/* Header with difficulty badge */}
                    <div className="flex justify-between items-start mb-3">
                      <div className="flex gap-2">
                        <div className={`w-8 h-8 rounded-full ${gradientBg} border ${borderColor} flex items-center justify-center ${iconColor}`}>
                          {index + 1}
                        </div>
                        <div>
                          <h3 className="font-semibold text-slate-800 line-clamp-1">{passage.passage_topic}</h3>
                          <p className="text-xs text-slate-500">{passage.passage_discipline}</p>
                        </div>
                      </div>
                      <span
                        className={cn(
                          "inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium",
                          passage.passage_reading_difficulty === "Challenging"
                            ? "bg-rose-50 text-rose-700 border border-rose-100"
                            : "bg-amber-50 text-amber-700 border border-amber-100",
                        )}
                      >
                        {passage.passage_reading_difficulty}
                      </span>
                    </div>
                    
                    {/* Main Stats */}
                    <div className="flex flex-col gap-3 mt-auto">
                      {/* Accuracy Bar */}
                      <div className="mt-1">
                        <div className="flex justify-between items-center mb-1.5">
                          <span className="text-sm font-medium text-slate-600">Accuracy</span>
                          <span className={`text-sm font-bold ${secondaryTextColor}`}>
                            {Math.round(passage.accuracy)}%
                          </span>
                        </div>
                        <div className="h-2.5 bg-slate-100 rounded-full overflow-hidden">
                          <div
                            className={cn(
                              "h-full rounded-full transition-all duration-500",
                              `bg-gradient-to-r from-${colorTheme}-400 to-${colorTheme}-500`
                            )}
                            style={{ width: `${passage.accuracy}%` }}
                          />
                        </div>
                      </div>
                      
                      {/* Stats Grid */}
                      <div className="grid grid-cols-2 gap-3 mt-2">
                        <div className={`bg-white/80 rounded-lg border ${borderColor} p-2 text-center`}>
                          <div className="flex items-center justify-center gap-1.5 mb-0.5">
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconColor}>
                              <path d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 8V12L9 15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="text-xs text-slate-600">Reading Time</span>
                          </div>
                          <span className={`text-sm font-bold ${secondaryTextColor}`}>
                            {testData.passage_elapsed_time?.[index]
                              ? Math.round(testData.passage_elapsed_time[index] / 60)
                              : "N/A"} min
                          </span>
                        </div>
                        
                        <div className={`bg-white/80 rounded-lg border ${borderColor} p-2 text-center`}>
                          <div className="flex items-center justify-center gap-1.5 mb-0.5">
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={iconColor}>
                              <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="text-xs text-slate-600">Questions</span>
                          </div>
                          <span className={`text-sm font-bold ${secondaryTextColor}`}>
                            {passage.correctAnswers}/{passage.totalQuestions}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          
          {/* Summary Box */}
          <div className="mt-8 p-4 bg-sky-50 border border-sky-100 rounded-lg">
            <div className="flex items-start gap-3">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-sky-600 mt-0.5">
                <path d="M13 16H12V12H11M12 8H12.01M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 8V12L15 15M21 7H17M14 7H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <div>
                <h4 className="text-sm font-medium text-sky-800 mb-1">Passage Insight</h4>
                <p className="text-xs text-sky-700">
                  {(() => {
                    const strongPassages = passageAnalysis.filter(p => p.accuracy >= 80).length;
                    const weakPassages = passageAnalysis.filter(p => p.accuracy < 60).length;
                    const totalPassages = passageAnalysis.length;
                    
                    if (strongPassages >= totalPassages * 0.7) {
                      return "You performed exceptionally well across most passages. Your CARS reasoning skills are strong and consistent.";
                    } else if (weakPassages >= totalPassages * 0.5) {
                      return "You struggled with multiple passages. Focus on identifying key ideas and applying critical reasoning consistently.";
                    } else if (Math.max(...passageAnalysis.map(p => p.accuracy)) - 
                              Math.min(...passageAnalysis.map(p => p.accuracy)) > 40) {
                      return "Your performance varies significantly between passages. Work on maintaining consistent reasoning across different topics and styles.";
                    } else {
                      return "Your performance was balanced across different passages. Continue practicing with varied passage types to maintain this consistency.";
                    }
                  })()}
                </p>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  };

  // Filters the question list
  const filteredQuestions = questions.filter((question) => {
    const matchesType =
      filters.questionType === "all" || question.question_type === filters.questionType;
    const matchesDifficulty =
      filters.difficulty === "all" ||
      question.question_difficulty === filters.difficulty;
    const matchesConfidence =
      filters.confidence === "all" || question.confidence === filters.confidence;
    const matchesResult =
      filters.result === "all" ||
      (filters.result === "correct" && question.isCorrect) ||
      (filters.result === "incorrect" && !question.isCorrect);
    const matchesSearch =
      searchQuery === "" ||
      question.text.toLowerCase().includes(searchQuery.toLowerCase());

    return (
      matchesType &&
      matchesDifficulty &&
      matchesConfidence &&
      matchesResult &&
      matchesSearch
    );
  });

  // Renders the question analysis table
  const renderQuestionAnalysis = () => {
    const uniqueTypes = [...new Set(questions.map((q) => q.question_type))];
    const uniqueDifficulties = [...new Set(questions.map((q) => q.question_difficulty))];
    
    // Calculate analytics for question types and difficulties
    const typeAnalytics = {};
    const difficultyAnalytics = {};
    
    uniqueTypes.forEach(type => {
      const typeQuestions = questions.filter(q => q.question_type === type);
      const correct = typeQuestions.filter(q => q.isCorrect).length;
      const total = typeQuestions.length;
      typeAnalytics[type] = {
        correct,
        total,
        accuracy: total > 0 ? Math.round((correct / total) * 100) : 0
      };
    });
    
    uniqueDifficulties.forEach(diff => {
      const diffQuestions = questions.filter(q => q.question_difficulty === diff);
      const correct = diffQuestions.filter(q => q.isCorrect).length;
      const total = diffQuestions.length;
      difficultyAnalytics[diff] = {
        correct,
        total,
        accuracy: total > 0 ? Math.round((correct / total) * 100) : 0
      };
    });
    
    // Get the question type with the lowest accuracy
    const weakestType = Object.entries(typeAnalytics)
      .sort((a, b) => a[1].accuracy - b[1].accuracy)[0];
      
    // Get the difficulty with the lowest accuracy
    const weakestDifficulty = Object.entries(difficultyAnalytics)
      .sort((a, b) => a[1].accuracy - b[1].accuracy)[0];

    return (
      <Card className="border border-slate-200/60 shadow-sm rounded-xl overflow-hidden relative">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-500 to-purple-500"></div>
        <CardHeader className="border-b border-slate-100 bg-gradient-to-r from-slate-50 to-white pb-4">
          <div className="flex items-center gap-3">
            <div className="w-8 h-8 rounded-lg bg-indigo-50 flex items-center justify-center">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-500">
                <path d="M3 12H7M12 12H16M21 12H16M16 8V12M7 8V12M7 12V16M3 8H7M12 8H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div>
              <CardTitle className="text-lg font-bold text-slate-800">
                Question Analysis
              </CardTitle>
              <CardDescription className="text-slate-500 mt-1">
                Detailed breakdown of your question performance
              </CardDescription>
            </div>
          </div>
        </CardHeader>
        <CardContent className="p-6">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
            {/* Question Type Analysis */}
            <div className="lg:col-span-2">
              <div className="flex items-center gap-2 mb-3">
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-500">
                  <path d="M9.25 11H14.75M9.25 8H14.75M9.25 14H11.75M5.75 21H18.25C19.3546 21 20.25 20.1046 20.25 19V5C20.25 3.89543 19.3546 3 18.25 3H5.75C4.64543 3 3.75 3.89543 3.75 5V19C3.75 20.1046 4.64543 21 5.75 21ZM17 17H21M17 17C17 15.3431 15.6569 14 14 14C12.3431 14 11 15.3431 11 17C11 18.6569 12.3431 20 14 20C15.6569 20 17 18.6569 17 17ZM3 17H7M21 7H17M14 7H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h3 className="font-semibold text-slate-800">Performance By Question Type</h3>
              </div>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {Object.entries(typeAnalytics).map(([type, stats]) => (
                  <div 
                    key={type} 
                    className={`border rounded-lg p-3 bg-gradient-to-br ${
                      stats.accuracy >= 80 
                        ? "from-emerald-50 to-white border-emerald-100" 
                        : stats.accuracy >= 60 
                          ? "from-amber-50 to-white border-amber-100" 
                          : "from-rose-50 to-white border-rose-100"
                    }`}
                  >
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-slate-800 text-sm">{type}</h4>
                      <div className={`text-xs font-medium px-2 py-0.5 rounded-md ${
                        stats.accuracy >= 80 
                          ? "bg-emerald-100 text-emerald-800" 
                          : stats.accuracy >= 60 
                            ? "bg-amber-100 text-amber-800" 
                            : "bg-rose-100 text-rose-800"
                      }`}>
                        {stats.accuracy}% accuracy
                      </div>
                    </div>
                    <div className="h-2.5 bg-slate-100 rounded-full overflow-hidden mb-2">
                      <div 
                        className={`h-full rounded-full ${
                          stats.accuracy >= 80 
                            ? "bg-emerald-500" 
                            : stats.accuracy >= 60 
                              ? "bg-amber-500" 
                              : "bg-rose-500"
                        }`}
                        style={{ width: `${stats.accuracy}%` }}
                      ></div>
                    </div>
                    <div className="text-xs text-slate-600">
                      {stats.correct} correct of {stats.total} questions
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Improvement Focus */}
            <div className="bg-indigo-50 border border-indigo-100 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-3">
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-600">
                  <path d="M9.25 9.05C9.25 11.0267 10.85 12.625 12.825 12.625C14.8 12.625 16.4 11.0267 16.4 9.05C16.4 7.07333 14.8 5.475 12.825 5.475M9.25 9.05C9.25 7.07333 10.85 5.475 12.825 5.475M9.25 9.05C9.25 11.0267 7.65 12.625 5.675 12.625C3.7 12.625 2.1 11.0267 2.1 9.05C2.1 7.07333 3.7 5.475 5.675 5.475C7.65 5.475 9.25 7.07333 9.25 9.05ZM12.825 5.475C14.8 5.475 16.4 7.07333 16.4 9.05C16.4 11.0267 14.8 12.625 12.825 12.625M12.825 5.475C14.8 5.475 16.4 3.87667 16.4 1.9C16.4 1.9 15.575 1.9 14.75 1.9C12.775 1.9 11.175 3.87667 11.175 1.9C11.175 1.9 11.175 1.9 12.825 1.9M12.825 12.625C10.85 12.625 9.25 14.2233 9.25 16.2C9.25 18.1767 10.85 19.775 12.825 19.775C14.8 19.775 16.4 18.1767 16.4 16.2M16.4 16.2C16.4 18.1767 18 19.775 19.975 19.775C21.95 19.775 23.55 18.1767 23.55 16.2C23.55 14.2233 21.95 12.625 19.975 12.625C18 12.625 16.4 14.2233 16.4 16.2ZM9.25 16.2C9.25 14.2233 7.65 12.625 5.675 12.625" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h3 className="font-semibold text-indigo-900">Focus Areas</h3>
              </div>
              
              <div className="space-y-4">
                {weakestType && (
                  <div className="bg-white/80 rounded-lg border border-indigo-100 p-3">
                    <h4 className="text-sm font-medium text-indigo-900 mb-1">Question Type to Improve</h4>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-indigo-800">{weakestType[0]}</p>
                      <span className="text-xs bg-indigo-100 text-indigo-700 px-2 py-0.5 rounded-full">
                        {weakestType[1].accuracy}% accuracy
                      </span>
                    </div>
                    <p className="text-xs text-slate-600 mt-2">
                      This was your most challenging question type. Focus on improving your approach.
                    </p>
                  </div>
                )}
                
                {weakestDifficulty && (
                  <div className="bg-white/80 rounded-lg border border-indigo-100 p-3">
                    <h4 className="text-sm font-medium text-indigo-900 mb-1">Difficulty to Practice</h4>
                    <div className="flex justify-between items-center">
                      <p className="text-sm text-indigo-800">{weakestDifficulty[0]}</p>
                      <span className="text-xs bg-indigo-100 text-indigo-700 px-2 py-0.5 rounded-full">
                        {weakestDifficulty[1].accuracy}% accuracy
                      </span>
                    </div>
                    <p className="text-xs text-slate-600 mt-2">
                      You struggled most with {weakestDifficulty[0].toLowerCase()} questions. Practice more of these.
                    </p>
                  </div>
                )}
                
                <div className="bg-indigo-100/50 rounded-lg border border-indigo-200 p-3 mt-auto">
                  <div className="flex items-start gap-2">
                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-600 mt-0.5">
                      <path d="M13 16H12V12H11M12 8H12.01M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <p className="text-xs text-indigo-700">
                      Review your weakest areas in detail by clicking "Review Full Test" to see your answers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Filters & Question List */}
          <div className="bg-slate-50 border border-slate-200 rounded-xl p-5">
            <div className="flex flex-col md:flex-row justify-between gap-4 mb-5">
              <h3 className="text-sm font-semibold text-slate-800 flex items-center gap-2">
                <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-slate-600">
                  <path d="M3 7H6M6 7C6 8.65685 7.34315 10 9 10C10.6569 10 12 8.65685 12 7C12 5.34315 10.6569 4 9 4C7.34315 4 6 5.34315 6 7ZM17 17H21M17 17C17 15.3431 15.6569 14 14 14C12.3431 14 11 15.3431 11 17C11 18.6569 12.3431 20 14 20C15.6569 20 17 18.6569 17 17ZM3 17H6M21 7H17M14 7H9" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Question Filters
              </h3>
              
              <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
                {/* Search */}
                <div className="flex items-center w-full md:w-auto relative">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute left-3 text-slate-400">
                    <path d="M21 21L15.8033 15.8033M15.8033 15.8033C17.1605 14.4461 18 12.5711 18 10.5C18 6.35786 14.6421 3 10.5 3C6.35786 3 3 6.35786 3 10.5C3 14.6421 6.35786 18 10.5 18C12.5711 18 14.4461 17.1605 15.8033 15.8033Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <Input
                    placeholder="Search questions..."
                    className="pl-10 bg-white border-slate-200 w-full md:w-[200px]"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>

                {/* Type / Difficulty / Confidence / Result */}
                <div className="flex flex-wrap md:flex-nowrap items-center gap-3">
                  <Select
                    value={filters.questionType}
                    onValueChange={(value) =>
                      setFilters((prev) => ({ ...prev, questionType: value }))
                    }
                  >
                    <SelectTrigger className="bg-white border-slate-200 w-full md:w-[160px]">
                      <SelectValue placeholder="Question Type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Types</SelectItem>
                      {uniqueTypes.map((type) => (
                        <SelectItem key={type} value={type}>
                          {type}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <Select
                    value={filters.difficulty}
                    onValueChange={(value) =>
                      setFilters((prev) => ({ ...prev, difficulty: value }))
                    }
                  >
                    <SelectTrigger className="bg-white border-slate-200 w-full md:w-[160px]">
                      <SelectValue placeholder="Difficulty" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Difficulties</SelectItem>
                      {uniqueDifficulties.map((diff) => (
                        <SelectItem key={diff} value={diff}>
                          {diff}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <Select
                    value={filters.confidence}
                    onValueChange={(value) =>
                      setFilters((prev) => ({ ...prev, confidence: value }))
                    }
                  >
                    <SelectTrigger className="bg-white border-slate-200 w-full md:w-[160px]">
                      <SelectValue placeholder="Confidence" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Confidence</SelectItem>
                      <SelectItem value="High">High</SelectItem>
                      <SelectItem value="Medium">Medium</SelectItem>
                      <SelectItem value="Low">Low</SelectItem>
                    </SelectContent>
                  </Select>

                  <Select
                    value={filters.result}
                    onValueChange={(value) =>
                      setFilters((prev) => ({ ...prev, result: value }))
                    }
                  >
                    <SelectTrigger className="bg-white border-slate-200 w-full md:w-[160px]">
                      <SelectValue placeholder="Result" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="all">All Results</SelectItem>
                      <SelectItem value="correct">Correct</SelectItem>
                      <SelectItem value="incorrect">Incorrect</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </div>

            {/* Questions Table */}
            <div className="bg-white rounded-lg border border-slate-200 overflow-hidden">
              <ScrollArea className="h-[350px]">
                <Table>
                  <TableHeader>
                    <TableRow className="bg-slate-50 hover:bg-slate-50/80">
                      <TableHead className="text-slate-700 font-medium w-[80px]">Q #</TableHead>
                      <TableHead className="text-slate-700 font-medium">Type</TableHead>
                      <TableHead className="text-slate-700 font-medium">Difficulty</TableHead>
                      <TableHead className="text-slate-700 font-medium">Confidence</TableHead>
                      <TableHead className="text-slate-700 font-medium">Result</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filteredQuestions.length > 0 ? filteredQuestions.map((question, idx) => (
                      <TableRow 
                        key={question.id} 
                        className={`border-slate-100 ${question.isCorrect ? 'hover:bg-emerald-50/30' : 'hover:bg-rose-50/30'}`}
                      >
                        <TableCell className="font-medium text-center border-r border-slate-100 text-slate-700">
                          {idx + 1}
                        </TableCell>
                        <TableCell className="text-slate-700">
                          {question.question_type}
                        </TableCell>
                        <TableCell>
                          <span
                            className={cn(
                              "inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium border",
                              question.question_difficulty === "Hard"
                                ? "bg-rose-50 text-rose-700 border-rose-100"
                                : question.question_difficulty === "Medium"
                                ? "bg-amber-50 text-amber-700 border-amber-100"
                                : "bg-emerald-50 text-emerald-700 border-emerald-100",
                            )}
                          >
                            {question.question_difficulty}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            className={cn(
                              "inline-flex items-center px-2 py-0.5 rounded-md text-xs font-medium border",
                              question.confidence === "High"
                                ? "bg-emerald-50 text-emerald-700 border-emerald-100"
                                : question.confidence === "Medium"
                                ? "bg-amber-50 text-amber-700 border-amber-100"
                                : question.confidence === "Low"
                                ? "bg-rose-50 text-rose-700 border-rose-100"
                                : "bg-slate-50 text-slate-700 border-slate-100", // fallback if "Not Rated"
                            )}
                          >
                            {question.confidence}
                          </span>
                        </TableCell>
                        <TableCell>
                          {question.isCorrect ? (
                            <span className="inline-flex items-center gap-1.5 text-emerald-600 font-medium">
                              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.5 12L10.5 15L16.5 9M3 12H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                              Correct
                            </span>
                          ) : (
                            <span className="inline-flex items-center gap-1.5 text-rose-600 font-medium">
                              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 9L9 15M9 9L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                              </svg>
                              Incorrect
                            </span>
                          )}
                        </TableCell>
                      </TableRow>
                    )) : (
                      <TableRow>
                        <TableCell colSpan={5} className="text-center py-8 text-slate-500">
                          No questions match your filters. Try adjusting your search criteria.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </ScrollArea>
            </div>
            
            {/* Results count */}
            <div className="mt-3 flex justify-between items-center text-xs text-slate-500">
              <span>
                Showing {filteredQuestions.length} of {questions.length} questions
              </span>
              <span>
                {filteredQuestions.filter(q => q.isCorrect).length} correct ({Math.round((filteredQuestions.filter(q => q.isCorrect).length / filteredQuestions.length) * 100) || 0}%)
              </span>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }; // <-- Make sure we close the function cleanly here, with NO extra brace after

  // Simple MCAT Score approximation
  const calculateMCATScore = (percentage) => {
    if (percentage >= 100) return 132;
    if (percentage >= 99) return 131;
    if (percentage >= 98) return 130;
    if (percentage >= 95) return 129;
    if (percentage >= 90) return 128;
    if (percentage >= 83) return 127;
    if (percentage >= 73) return 126;
    if (percentage >= 61) return 125;
    if (percentage >= 49) return 124;
    if (percentage >= 36) return 123;
    if (percentage >= 25) return 122;
    if (percentage >= 15) return 121;
    if (percentage >= 8) return 120;
    if (percentage >= 3) return 119;
    if (percentage >= 1) return 118;
    if (percentage > 0) return 117;
    return null;
  };

  if (!testData) {
    return null;
  }

  return (
    <div className="min-h-screen bg-[#f8fafc] py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto space-y-8">
        {/* Header section with breadcrumb and page title */}
        <div className="flex flex-col space-y-3 sm:flex-row sm:justify-between sm:items-center sm:space-y-0 mb-8">
          <div>
            <div className="flex items-center text-sm text-slate-500 mb-2">
              <button 
                onClick={() => navigate("/dashboard")}
                className="hover:text-emerald-600 transition-colors"
              >
                Dashboard
              </button>
              <svg width="16" height="16" viewBox="0 0 24 24" className="mx-2">
                <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
              </svg>
              <span className="text-slate-700 font-medium">Test Results</span>
            </div>
            <h1 className="text-2xl font-bold text-slate-900 flex items-center">
              Test Summary
              <span className="ml-3 text-xs font-normal bg-emerald-100 text-emerald-800 px-3 py-1 rounded-full inline-flex items-center">
                <svg width="14" height="14" viewBox="0 0 24 24" className="mr-1" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21ZM12 12H15M12 16H15M9 12H9.01M9 16H9.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                {new Date(testData.test_date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}
              </span>
            </h1>
          </div>
          <div className="flex space-x-3">
            <Button
              onClick={() => navigate("/dashboard")}
              variant="outline"
              className="text-slate-600 border-slate-300 hover:bg-slate-50 flex items-center gap-1.5"
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 20L3 12L9 4M3 12H21" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Back to Dashboard
            </Button>
            <Button
              onClick={() => navigate(`/test/${testId}/review`)}
              className="bg-emerald-600 hover:bg-emerald-700 text-white flex items-center gap-1.5"
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V15M9 3H15M9 3L15 9M15 3H19C20.1046 3 21 3.89543 21 5V9M15 3L21 9V15M21 9H15V15M21 15H15" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              Review Full Test
            </Button>
          </div>
        </div>

        {/* Top stats cards */}
        <div className="grid grid-cols-1 lg:grid-cols-8 gap-6">
          {/* Main Score Card */}
          <Card className="lg:col-span-5 border border-slate-200/60 shadow-sm rounded-xl overflow-hidden relative">
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-emerald-500 to-sky-500"></div>
            <div className="flex flex-col md:flex-row">
              {/* Left side - Percentage Score */}
              <div className="p-6 md:w-2/5 bg-gradient-to-br from-slate-50 to-white flex flex-col justify-center items-center md:border-r border-slate-100">
                <div className="w-full space-y-1 mb-2">
                  <div className="flex justify-between items-center">
                    <h3 className="text-sm font-medium text-slate-500">Your Score</h3>
                    <span className="text-xs bg-emerald-100 text-emerald-800 px-2 py-0.5 rounded-full">
                      {testData.correctAnswers} of {testData.totalQuestions} correct
                    </span>
                  </div>
                </div>
                
                <div className="relative flex items-center justify-center my-2">
                  <svg className="w-40 h-40">
                    <circle 
                      cx="80" 
                      cy="80" 
                      r="70" 
                      fill="none" 
                      stroke="#f1f5f9" 
                      strokeWidth="12"
                    />
                    <circle 
                      cx="80" 
                      cy="80" 
                      r="70" 
                      fill="none" 
                      stroke="url(#scoreGradient)" 
                      strokeWidth="12"
                      strokeDasharray="439.8"
                      strokeDashoffset={439.8 - (439.8 * testData.score) / 100}
                      strokeLinecap="round"
                      transform="rotate(-90 80 80)"
                      className="transition-all duration-1000 ease-out"
                    />
                    <defs>
                      <linearGradient id="scoreGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor="#10b981" />
                        <stop offset="100%" stopColor="#0ea5e9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div className="absolute inset-0 flex items-center justify-center flex-col">
                    <span className="text-5xl font-bold text-slate-800">{testData.score}%</span>
                    <span className="text-sm text-slate-500 mt-1">Accuracy</span>
                  </div>
                </div>
              </div>
              
              {/* Right side - Performance Stats */}
              <div className="p-6 md:w-3/5">
                <div className="mb-5">
                  <h3 className="text-lg font-bold text-slate-800 mb-1">MCAT CARS Performance</h3>
                  <p className="text-sm text-slate-500">Test completed in {Math.floor(testData.elapsed_time / 60)} minutes</p>
                </div>
                
                <div className="grid grid-cols-2 gap-5">
                  {/* MCAT Score Card */}
                  <div className="bg-gradient-to-br from-purple-50 to-white border border-purple-100 rounded-lg p-4 flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-1.5">
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-purple-500">
                          <path d="M9 12L11 14L15 10M12 3L4 7.5L12 12L20 7.5L12 3Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M4 14.5L12 19L20 14.5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="text-sm font-medium text-slate-700">MCAT CARS Score</span>
                      </div>
                    </div>
                    <div className="mt-auto pt-1 flex items-end justify-between">
                      <span className="text-3xl font-bold text-purple-700">{calculateMCATScore(testData.score)}</span>
                      <div className="flex flex-col items-end">
                        <span className="text-xs text-purple-700 font-medium px-2 py-0.5 bg-purple-50 rounded-md border border-purple-100 mb-1">
                          {calculateMCATScore(testData.score) >= 128 ? "Exceptional" : 
                           calculateMCATScore(testData.score) >= 125 ? "Very Good" : 
                           calculateMCATScore(testData.score) >= 122 ? "Good" : "Improving"}
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  {/* Time per Question Card */}
                  <div className="bg-gradient-to-br from-sky-50 to-white border border-sky-100 rounded-lg p-4 flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-1.5">
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-sky-500">
                          <path d="M12 8V12L15 15M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="text-sm font-medium text-slate-700">Avg. Time Per Question</span>
                      </div>
                    </div>
                    <div className="mt-auto pt-1 flex items-end justify-between">
                      <span className="text-3xl font-bold text-sky-700">
                        {Math.round((testData.elapsed_time / testData.totalQuestions) / 60 * 10) / 10} min
                      </span>
                      <div className="flex flex-col items-end">
                        {(() => {
                          const avgTime = (testData.elapsed_time / testData.totalQuestions) / 60;
                          const status = avgTime <= 1.2 ? "Fast" : avgTime <= 1.75 ? "Good Pace" : "Careful";
                          const color = avgTime <= 1.2 ? "text-emerald-700 bg-emerald-50 border-emerald-100" : 
                                        avgTime <= 1.75 ? "text-sky-700 bg-sky-50 border-sky-100" : 
                                        "text-amber-700 bg-amber-50 border-amber-100";
                          return (
                            <span className={`text-xs font-medium px-2 py-0.5 rounded-md border mb-1 ${color}`}>
                              {status}
                            </span>
                          );
                        })()}
                      </div>
                    </div>
                  </div>
                  
                  {/* Flagged Questions Card */}
                  <div className="bg-gradient-to-br from-amber-50 to-white border border-amber-100 rounded-lg p-4 flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-1.5">
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-500">
                          <path d="M3 21V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H3ZM3 21L17 7M3 12H7M12 12H16M3 8H7M12 8H16" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="text-sm font-medium text-slate-700">Flagged Questions</span>
                      </div>
                    </div>
                    <div className="mt-auto pt-1 flex items-end justify-between">
                      <span className="text-3xl font-bold text-amber-700">{testData.flaggedQuestions}</span>
                      <div className="flex flex-col items-end">
                        <span className="text-xs text-amber-700 font-medium px-2 py-0.5 bg-amber-50 rounded-md border border-amber-100 mb-1">
                          {Math.round((testData.flaggedQuestions / testData.totalQuestions) * 100)}% of test
                        </span>
                      </div>
                    </div>
                  </div>
                  
                  {/* Passage Count Card */}
                  <div className="bg-gradient-to-br from-emerald-50 to-white border border-emerald-100 rounded-lg p-4 flex flex-col">
                    <div className="flex justify-between items-center mb-2">
                      <div className="flex items-center gap-1.5">
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-emerald-500">
                          <path d="M9 5H7C5.89543 5 5 5.89543 5 7V19C5 20.1046 5.89543 21 7 21H17C18.1046 21 19 20.1046 19 19V7C19 5.89543 18.1046 5 17 5H15M9 5C9 6.10457 9.89543 7 11 7H13C14.1046 7 15 6.10457 15 5M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5M12 12H15M12 16H15M9 12H9.01M9 16H9.01" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span className="text-sm font-medium text-slate-700">Passages Read</span>
                      </div>
                    </div>
                    <div className="mt-auto pt-1 flex items-end justify-between">
                      <span className="text-3xl font-bold text-emerald-700">{testData.totalPassages}</span>
                      <div className="flex flex-col items-end">
                        <span className="text-xs text-emerald-700 font-medium px-2 py-0.5 bg-emerald-50 rounded-md border border-emerald-100 mb-1">
                          {Math.round(testData.totalQuestions / testData.totalPassages * 10) / 10} Q per passage
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>

          {/* Confidence Distribution Card */}
          <Card className="lg:col-span-3 border border-slate-200/60 shadow-sm rounded-xl overflow-hidden relative">
            <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-amber-500 to-red-500"></div>
            <CardHeader className="pb-2">
              <CardTitle className="text-lg font-bold text-slate-800">
                Confidence Analysis
              </CardTitle>
              <CardDescription className="text-slate-500 mt-1">
                How confident were you in your answers?
              </CardDescription>
            </CardHeader>
            <CardContent className="pt-2">
              <div className="space-y-4 mt-3">
                {Object.entries(testData.confidenceData).map(
                  ([level, count]) => (
                    <div key={level} className="space-y-1.5">
                      <div className="flex justify-between text-sm">
                        <span className={`font-medium ${
                          level === "High" ? "text-emerald-700" : 
                          level === "Medium" ? "text-amber-700" : 
                          "text-rose-700"
                        }`}>
                          {level} Confidence
                        </span>
                        <span className="font-medium text-slate-700">
                          {count} questions
                        </span>
                      </div>
                      <div className="h-2.5 bg-slate-100 rounded-full overflow-hidden">
                        <div
                          className={`h-full rounded-full transition-all duration-500 ${
                            level === "High" ? "bg-emerald-500" : 
                            level === "Medium" ? "bg-amber-500" : 
                            "bg-rose-500"
                          }`}
                          style={{
                            width: `${(count / testData.totalQuestions) * 100}%`,
                          }}
                        />
                      </div>
                      <div className="flex justify-between text-xs text-slate-500">
                        <span>
                          {Math.round((count / testData.totalQuestions) * 100)}% of answers
                        </span>
                        <span>
                          {(() => {
                            const correctData = questions.filter(q => 
                              q.confidence === level && q.isCorrect
                            ).length;
                            const accuracyPct = count > 0 ? Math.round((correctData / count) * 100) : 0;
                            return `${accuracyPct}% accurate`;
                          })()}
                        </span>
                      </div>
                    </div>
                  ),
                )}
              </div>

              <div className="mt-8 p-4 bg-amber-50 border border-amber-100 rounded-lg">
                <div className="flex items-start gap-3">
                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-600 mt-0.5">
                    <path d="M13 16H12V12H11M12 8H12.01M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <div>
                    <h4 className="text-sm font-medium text-amber-800 mb-1">Confidence Insight</h4>
                    <p className="text-xs text-amber-700">
                      {(() => {
                        const highConfCorrect = questions.filter(q => 
                          q.confidence === "High" && q.isCorrect
                        ).length;
                        const highConfTotal = testData.confidenceData["High"] || 0;
                        const highConfAccuracy = highConfTotal > 0 ? (highConfCorrect / highConfTotal) * 100 : 0;
                        
                        if (highConfAccuracy < 70) {
                          return "You may be overconfident. Many answers you were highly confident about were incorrect.";
                        } else if (testData.confidenceData["Low"] > testData.totalQuestions * 0.3) {
                          return "You marked many questions with low confidence. Focus on building your content knowledge and reasoning skills.";
                        } else if (highConfAccuracy > 85 && testData.confidenceData["High"] > testData.totalQuestions * 0.6) {
                          return "Your confidence assessment is excellent! You know when you know the answer.";
                        } else {
                          return "Your confidence levels show reasonable self-assessment. Continue to practice recognizing when you're uncertain.";
                        }
                      })()}
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Passage Analysis Section */}
        {renderPassageAnalysis()}

        {/* Question Analysis Section */}
        {renderQuestionAnalysis()}

        {/* Next Steps Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-8">
          <div className="bg-gradient-to-br from-purple-50 to-indigo-50 border border-indigo-100 rounded-xl shadow-sm p-5 flex flex-col">
            <div className="flex items-start gap-3">
              <div className="w-12 h-12 rounded-lg bg-white border border-indigo-100 flex items-center justify-center shadow-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-600">
                  <path d="M16 8V16M12 11V16M8 14V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                <h3 className="text-lg font-bold text-indigo-900">Review Full Test</h3>
                <p className="text-sm text-indigo-700 mt-1">
                  See every passage and your answers with detailed explanations
                </p>
              </div>
            </div>
            
            <div className="mt-5 bg-white/70 rounded-lg border border-indigo-100 p-3">
              <ul className="space-y-2 text-sm">
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  See complete passages with context
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  View all answer choices
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-indigo-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Read detailed explanations
                </li>
              </ul>
            </div>
            
            <div className="mt-auto pt-5">
              <Button
                onClick={() => navigate(`/test/${testId}/review`)}
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-medium"
              >
                Start Full Review
              </Button>
            </div>
          </div>

          <div className="bg-gradient-to-br from-emerald-50 to-teal-50 border border-emerald-100 rounded-xl shadow-sm p-5 flex flex-col">
            <div className="flex items-start gap-3">
              <div className="w-12 h-12 rounded-lg bg-white border border-emerald-100 flex items-center justify-center shadow-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-emerald-600">
                  <path d="M16 8V16M12 11V16M8 14V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                <h3 className="text-lg font-bold text-emerald-900">Practice More</h3>
                <p className="text-sm text-emerald-700 mt-1">
                  Continue improving your CARS skills with more practice
                </p>
              </div>
            </div>
            
            <div className="mt-5 bg-white/70 rounded-lg border border-emerald-100 p-3">
              <ul className="space-y-2 text-sm">
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-emerald-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Create new customized tests
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-emerald-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Focus on your weak areas
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-emerald-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Track your improvement over time
                </li>
              </ul>
            </div>
            
            <div className="mt-auto pt-5">
              <Button
                onClick={() => navigate("/dashboard")}
                className="w-full bg-emerald-600 hover:bg-emerald-700 text-white font-medium"
              >
                Back to Dashboard
              </Button>
            </div>
          </div>

          <div className="bg-gradient-to-br from-amber-50 to-orange-50 border border-amber-100 rounded-xl shadow-sm p-5 flex flex-col relative overflow-hidden">
            <div className="absolute -top-1 -right-1">
              <div className="bg-amber-500 text-xs text-white px-3 py-1 rotate-12 transform shadow-sm font-bold">
                FREE
              </div>
            </div>
            
            <div className="flex items-start gap-3">
              <div className="w-12 h-12 rounded-lg bg-white border border-amber-100 flex items-center justify-center shadow-sm">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-600">
                  <path d="M16 8V16M12 11V16M8 14V16M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </div>
              <div>
                <h3 className="text-lg font-bold text-amber-900">Get Expert Help</h3>
                <p className="text-sm text-amber-700 mt-1">
                  Book a free 1:1 tutoring session with an MCAT expert
                </p>
              </div>
            </div>
            
            <div className="mt-5 bg-white/70 rounded-lg border border-amber-100 p-3">
              <ul className="space-y-2 text-sm">
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Personalized strategy session
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Review your specific challenges
                </li>
                <li className="flex items-center gap-2 text-slate-700">
                  <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-amber-500 flex-shrink-0">
                    <path d="M9 12L11 14L15 10M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  Proven tips from 520+ scorers
                </li>
              </ul>
            </div>
            
            <div className="mt-auto pt-5">
              <Button
                onClick={() => window.open("https://calendly.com/nickv-testkey/30min", "_blank")}
                className="w-full bg-amber-600 hover:bg-amber-700 text-white font-medium"
              >
                Book Free Session
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
