import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "./ui/dialog";
import { Button } from "./ui/button";
import {
  CheckCircle,
  CreditCard,
  ExternalLink,
  ShieldCheck,
  Copy,
  Loader2,
} from "lucide-react";
import { cn } from "../lib/utils";
import { SessionContext } from "../index";
import { invokeEdgeFunction } from "../utils/supabaseEdgeFunctions";

// Automatically determine if we're in a production environment
// This checks if the current hostname is not localhost and not an IP address
const isProduction =
  !window.location.hostname.includes("localhost") &&
  !/^(\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname);

// Beta discount code - used for early bird users
// To create this discount code in Stripe:
// 1. Go to https://dashboard.stripe.com/coupons
// 2. Click "Create coupon"
// 3. Set "Discount type" to "Percentage"
// 4. Set "Amount off" to "50%"
// 5. Set "Duration" to "Once"
// 6. Enter "BETA50" as the Coupon ID
// 7. Save the coupon
const BETA_DISCOUNT_CODE = "BETA50";

// Main component
const PaymentModal = ({
  open,
  onOpenChange,
  plan = "3month",
  planDetails = null,
  onSuccess,
  hasEarlyBirdDiscount = true,
}) => {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const [copySuccess, setCopySuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fallback plan details if none provided
  const defaultPlanDetails = {
    label: "3 Months Access",
    price: "247",
    originalPrice: "327",
    period: "for 3 months",
  };

  // Use provided plan details or fallback
  const activePlanDetails = planDetails || defaultPlanDetails;

  const handlePaymentClick = async () => {
    setLoading(true);
    setError(null);

    try {
      // Use the invokeEdgeFunction utility instead of direct fetch
      const data = await invokeEdgeFunction("create-checkout-session", {
        plan: plan,
        userId: session?.user?.id,
        isTest: !isProduction, // Set isTest based on environment
        discountCode: hasEarlyBirdDiscount ? BETA_DISCOUNT_CODE : undefined,
        successUrl: `${window.location.origin}/payment-success`,
        cancelUrl: `${window.location.origin}/cancel`,
      });

      // Redirect to Stripe checkout
      // window.open(data.url, "_blank");

      window.location.href = data.url;

      // Close the modal
      onOpenChange(false);
    } catch (err) {
      console.error("Payment error:", err);
      setError(
        err.message || "An error occurred when creating the payment session",
      );
    } finally {
      setLoading(false);
    }
  };

  // Handle copy discount code to clipboard
  const handleCopyCode = () => {
    navigator.clipboard.writeText(BETA_DISCOUNT_CODE).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        className="sm:max-w-[500px] max-h-[90vh] overflow-y-auto bg-white border shadow-lg"
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, calc(-50% - 30px))", // Shift upward to avoid Mac dock
          maxHeight: "calc(100vh - 100px)", // Ensure it doesn't extend below the viewport
        }}
      >
        <DialogHeader className="pb-2 border-b">
          <DialogTitle className="text-xl font-bold text-gray-800">
            Upgrade to {activePlanDetails.label}
          </DialogTitle>
        </DialogHeader>

        {/* Environment indicator for development only */}
        {!isProduction && (
          <div className="text-xs bg-yellow-100 text-yellow-800 px-2 py-1 rounded-md mb-2">
            Development Mode: Test payments only
          </div>
        )}

        <div className="mt-4 space-y-5 px-1">
          {/* Plan Summary */}
          <div className="rounded-lg border bg-gray-50 p-5">
            <div className="flex items-start justify-between">
              <div>
                <h3 className="font-semibold text-gray-800">
                  {activePlanDetails.label}
                </h3>
                <p className="text-sm text-gray-600">
                  {activePlanDetails.period}
                </p>
              </div>
              <div className="text-right">
                {hasEarlyBirdDiscount && activePlanDetails.originalPrice && (
                  <span className="text-sm line-through text-gray-500 block">
                    ${activePlanDetails.originalPrice}
                  </span>
                )}
                <p className="text-2xl font-bold text-blue-600">
                  ${activePlanDetails.price}
                </p>
                {hasEarlyBirdDiscount && (
                  <span className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full mt-1">
                    Early Bird Discount!
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Early Bird Discount Code (Only shown for eligible users) */}
          {hasEarlyBirdDiscount && (
            <div className="rounded-lg border bg-amber-50 p-4 border-amber-200">
              <h3 className="font-semibold text-amber-800 mb-2 flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 mr-2 text-amber-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
                Your 50% Beta Discount Code
              </h3>
              <p className="text-sm text-amber-700 mb-3">
                Enter this code during checkout to receive your 50% early bird
                discount:
              </p>
              <div className="flex items-center justify-between bg-white rounded border border-amber-300 p-2">
                <code className="font-mono text-lg font-semibold text-amber-900 tracking-wide">
                  {BETA_DISCOUNT_CODE}
                </code>
                <button
                  onClick={handleCopyCode}
                  className="ml-2 rounded-md p-1 hover:bg-amber-100 text-amber-700 transition-colors"
                  aria-label="Copy discount code"
                >
                  {copySuccess ? (
                    <span className="text-green-600 flex items-center text-sm font-medium">
                      <CheckCircle className="h-4 w-4 mr-1" />
                      Copied!
                    </span>
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </button>
              </div>
              <p className="text-xs text-amber-600 mt-2">
                * Your discount will be automatically applied during checkout
              </p>
            </div>
          )}

          {/* Features List */}
          <div className="space-y-3 bg-white p-3 rounded-lg border">
            <h3 className="font-semibold text-gray-800">Includes:</h3>
            <ul className="space-y-3">
              <li className="flex items-center gap-3">
                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                <span className="text-gray-700">
                  Full access to all features and content
                </span>
              </li>
              <li className="flex items-center gap-3">
                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                <span className="text-gray-700">
                  13,000+ AAMC-style practice questions
                </span>
              </li>
              <li className="flex items-center gap-3">
                <CheckCircle className="h-5 w-5 text-green-500 flex-shrink-0" />
                <span className="text-gray-700">
                  AI-powered feedback and analysis
                </span>
              </li>
              <li className="flex items-center gap-3">
                <ShieldCheck className="h-5 w-5 text-blue-500 flex-shrink-0" />
                <span className="text-gray-700 font-medium">
                  Money-back guarantee
                </span>
              </li>
            </ul>
          </div>

          {/* Error message */}
          {error && (
            <div className="rounded-lg border border-red-200 bg-red-50 p-3 text-red-800 text-sm">
              <p className="font-medium">An error occurred:</p>
              <p>{error}</p>
            </div>
          )}

          {/* Payment Button and Safety Info */}
          <DialogFooter className="flex flex-col gap-3 pt-3 pb-2 px-1 mt-3 border-t">
            <Button
              className="w-full py-6 text-lg font-medium bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white transition-all duration-200 shadow-md hover:shadow-lg"
              onClick={handlePaymentClick}
              disabled={loading}
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                  Creating secure checkout...
                </>
              ) : (
                <>
                  <CreditCard className="mr-2 h-5 w-5" />
                  Proceed to Secure Payment
                  <ExternalLink className="ml-2 h-4 w-4" />
                </>
              )}
            </Button>

            <div className="flex items-center justify-center mt-2 gap-2 text-center">
              <svg
                className="h-4 w-4 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                <path d="M7 11V7a5 5 0 0 1 10 0v4" />
              </svg>
              <p className="text-sm text-gray-600">
                Secure checkout via Stripe
              </p>
            </div>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
