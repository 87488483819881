import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  AlertCircle,
  Mail,
  LogOut,
  CreditCard,
  Loader2,
  Check,
} from "lucide-react";
import { Button } from "./ui/button";
import { SessionContext } from "../index";
import { supabase } from "../supabaseClient";
import { invokeEdgeFunction } from "../utils/supabaseEdgeFunctions";
import { cn } from "../lib/utils";

// Determine if we're in production environment
const isProduction =
  !window.location.hostname.includes("localhost") &&
  !/^(\d{1,3}\.){3}\d{1,3}$/.test(window.location.hostname);

const PaymentCancelPage = () => {
  const [countdown, setCountdown] = useState(30);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState("3month");
  const navigate = useNavigate();
  const session = useContext(SessionContext);

  // Pricing options based on OnboardingFlow.js
  const pricingOptions = {
    "1month": {
      label: "1 Month Access",
      price: "97",
      originalPrice: "129",
      period: "for 1 month",
      description: "Great for last-minute prep or trying out the platform",
    },
    "3month": {
      label: "3 Months Access",
      price: "247",
      originalPrice: "327",
      period: "for 3 months",
      description: "Perfect for dedicated CARS prep during intensive study",
      popular: true,
    },
    "6month": {
      label: "6 Months Access",
      price: "347",
      originalPrice: "459",
      period: "for 6 months",
      description: "For long-term practice, early starters, or slower pace",
    },
    lifetime: {
      label: "Lifetime Access",
      price: "447",
      originalPrice: "599",
      period: "one-time",
      description: "One-time investment, unlimited CARS practice forever",
    },
  };

  // Automatic redirect to logout and landing page after 30 seconds
  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      console.log(
        "Countdown complete, logging out and redirecting to landing page",
      );
      handleLogout();
    }
  }, [countdown]);

  // Handle logout and redirect to landing page
  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      navigate("/");
    } catch (error) {
      console.error("Error during logout:", error);
      // Redirect to landing page even if logout fails
      navigate("/");
    }
  };

  // Handle plan selection
  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  // Handle direct checkout using the edge function
  const handleCheckout = async (plan = selectedPlan) => {
    setLoading(true);
    setError(null);

    try {
      // Use the invokeEdgeFunction utility to create checkout session
      const data = await invokeEdgeFunction("create-checkout-session", {
        plan: plan,
        userId: session?.user?.id,
        isTest: !isProduction,
        successUrl: `${window.location.origin}/payment-success`,
        cancelUrl: `${window.location.origin}/cancel`,
      });

      // Redirect to Stripe checkout
      window.location.href = data.url;
    } catch (err) {
      console.error("Payment error:", err);
      setError(
        err.message || "An error occurred when creating the payment session",
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50 p-4">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-lg w-full border border-gray-100">
        <div className="text-center mb-6">
          <div className="bg-gradient-to-r from-amber-100 to-orange-100 p-5 rounded-full inline-block mb-4 shadow-sm">
            <AlertCircle className="h-16 w-16 text-amber-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 mb-3">
            Payment Canceled
          </h1>
          <p className="text-lg text-gray-600 mb-3">
            Your premium subscription purchase was not completed
          </p>
          <div className="bg-gradient-to-r from-blue-50 to-indigo-50 p-3 rounded-lg inline-block">
            <p className="text-gray-700">
              No charges have been made to your account
            </p>
          </div>
          <div className="mt-4 flex justify-center items-center text-sm">
            <span className="text-blue-700">
              Auto-redirecting in <span className="font-bold">{countdown}</span>{" "}
              seconds
            </span>
          </div>
        </div>

        {/* Error message if checkout fails */}
        {error && (
          <div className="mb-6 p-3 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
            <p className="font-medium">Error:</p>
            <p>{error}</p>
          </div>
        )}

        {/* Plan selection */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4 text-center">
            Choose a Plan to Continue
          </h3>
          <div className="space-y-3">
            {Object.entries(pricingOptions).map(([planId, plan]) => (
              <div
                key={planId}
                className={cn(
                  "relative border rounded-lg p-4 transition-all cursor-pointer hover:shadow-md",
                  selectedPlan === planId
                    ? "border-blue-600 bg-blue-50 shadow-md"
                    : "border-gray-200",
                )}
                onClick={() => handlePlanSelect(planId)}
              >
                {plan.popular && (
                  <div className="absolute -top-2 right-4 bg-gradient-to-r from-blue-600 to-green-600 text-white px-3 py-0.5 rounded-full text-xs font-bold shadow-sm">
                    Most Popular
                  </div>
                )}

                <div className="flex justify-between items-center">
                  <div className="pl-6">
                    <h4 className="font-semibold text-gray-900">
                      {plan.label}
                    </h4>
                    <p className="text-xs text-gray-500 mt-0.5">
                      {plan.description}
                    </p>
                  </div>
                  <div className="text-right flex-shrink-0">
                    <div className="flex items-center justify-end space-x-2">
                      <span className="line-through text-red-400 text-xs">
                        ${plan.originalPrice}
                      </span>
                      <span className="text-xl font-bold text-gray-900">
                        ${plan.price}
                      </span>
                    </div>
                    <p className="text-xs text-gray-500">{plan.period}</p>
                  </div>
                </div>

                {selectedPlan === planId && (
                  <div className="absolute top-1/2 transform -translate-y-1/2 left-2">
                    <Check className="w-4 h-4 text-blue-600" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* Main action buttons */}
        <div className="space-y-4 mb-6">
          <Button
            onClick={() => handleCheckout()}
            disabled={loading}
            className="w-full py-6 bg-gradient-to-r from-blue-600 to-blue-700 text-white hover:from-blue-700 hover:to-blue-800"
          >
            {loading ? (
              <>
                <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <CreditCard className="mr-2 h-5 w-5" />
                Try Checkout with {pricingOptions[selectedPlan].label}
              </>
            )}
          </Button>

          <div className="flex flex-col sm:flex-row gap-3">
            <Button
              variant="outline"
              onClick={() =>
                (window.location.href =
                  "mailto:team@upangea.com?subject=Payment%20Question")
              }
              className="w-full py-4 border-blue-200"
            >
              <Mail className="mr-2 h-5 w-5 text-blue-600" />
              <span className="text-blue-700">Email Support</span>
            </Button>

            <Button
              variant="outline"
              onClick={handleLogout}
              className="w-full py-4 border-gray-200"
            >
              <LogOut className="mr-2 h-5 w-5 text-gray-600" />
              <span className="text-gray-700">Return to Home</span>
            </Button>
          </div>
        </div>

        <div className="text-center text-sm text-gray-500">
          <p>
            Questions? Contact our team at{" "}
            <a
              href="mailto:team@upangea.com"
              className="text-blue-600 hover:underline"
            >
              team@upangea.com
            </a>
            <span className="block mt-1">We respond within 24 hours</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentCancelPage;
