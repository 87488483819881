import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
import { SessionContext } from "../index";
import OnboardingFlow from "./OnboardingFlow";
import { Button } from "./ui/button";

/**
 * A middleware component that checks if the user has a premium subscription
 * and redirects to the onboarding/payment flow if not
 */
const PaywallMiddleware = ({ children }) => {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isPremium, setIsPremium] = useState(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const [lastCheckTimestamp, setLastCheckTimestamp] = useState(0);
  const [hasEarlyBirdDiscount, setHasEarlyBirdDiscount] = useState(false);

  // We use this key to force a refresh after payment completes
  const [refreshKey, setRefreshKey] = useState(0);

  const handlePaymentSuccess = () => {
    console.log("PaywallMiddleware: Payment success handler triggered");

    // Close the onboarding modal
    setIsOnboardingOpen(false);

    // Update the lastCheckTimestamp to force a recheck of premium status
    setLastCheckTimestamp(Date.now());

    // Increment the refresh key to force a component update
    setRefreshKey((prev) => prev + 1);

    // Clear any cached state
    setIsPremium(false);
    setIsLoading(true);

    // If Chrome is having issues with the state updates, we can
    // use a more direct approach as a fallback
    setTimeout(() => {
      // Force a direct check of the user's subscription status
      const directCheck = async () => {
        try {
          console.log(
            "PaywallMiddleware: Performing direct subscription check",
          );
          if (!session) return;

          const { data, error } = await supabase
            .from("user_profiles")
            .select("subscription_data, early_bird_discount")
            .eq("user_id", session.user.id)
            .single();

          if (error) {
            console.error("Error in direct check:", error);
            return;
          }

          console.log("PaywallMiddleware: Direct check result:", data);

          // Check if subscription is active based on subscription_data
          const hasPremiumAccess = checkSubscriptionStatus(
            data?.subscription_data,
          );

          if (hasPremiumAccess) {
            console.log(
              "PaywallMiddleware: User has premium, setting isPremium=true",
            );
            setIsPremium(true);
            setIsLoading(false);
          }
        } catch (e) {
          console.error("Error in direct subscription check:", e);
        }
      };

      directCheck();
    }, 1000);
  };

  // Helper function to check if subscription is active based on subscription_data
  const checkSubscriptionStatus = (subscriptionData) => {
    if (!subscriptionData) return false;

    // Check if subscription has valid status
    const hasActiveStatus =
      subscriptionData.status === "active" ||
      subscriptionData.status === "trialing";

    // Check if subscription is not expired
    const notExpired = subscriptionData.current_period_end
      ? new Date(subscriptionData.current_period_end * 1000) > new Date()
      : false;

    // Handle lifetime subscriptions (no end date)
    const isLifetime =
      subscriptionData.price_id &&
      (subscriptionData.price_id.includes("lifetime") ||
        (subscriptionData.status === "active" &&
          !subscriptionData.current_period_end));

    return hasActiveStatus && (notExpired || isLifetime);
  };

  useEffect(() => {
    const checkSubscription = async () => {
      if (!session) {
        navigate("/");
        return;
      }

      try {
        console.log("Checking subscription status...");

        // First ensure the user profile exists
        await ensureUserProfileExists(session.user);

        // Check if user has premium access and early bird status
        const { data, error } = await supabase
          .from("user_profiles")
          .select("subscription_data, early_bird_discount, plan, plan_end_date")
          .eq("user_id", session.user.id)
          .single();

        if (error) throw error;

        console.log("Subscription data:", data?.subscription_data);

        // First check subscription_data for detailed subscription info
        let hasPremiumAccess = checkSubscriptionStatus(data?.subscription_data);

        // Fallback to legacy fields if subscription_data check doesn't provide a result
        if (!hasPremiumAccess && data?.plan === "Premium") {
          // If we have a plan_end_date, check if it's in the future
          const planIsActive = data?.plan_end_date
            ? new Date(data.plan_end_date) > new Date()
            : true; // If no end date, assume lifetime plan

          hasPremiumAccess = planIsActive;
        }

        // Check if user has early bird discount
        const earlyBirdStatus = data?.early_bird_discount === true;
        setHasEarlyBirdDiscount(earlyBirdStatus);

        console.log("Subscription check result:", {
          hasPremiumAccess,
          earlyBirdStatus,
        });

        if (hasPremiumAccess) {
          setIsPremium(true);
        } else {
          setIsOnboardingOpen(true);
        }
      } catch (error) {
        console.error("Error checking subscription:", error);
        // If there's an error, we should still show the onboarding
        setIsOnboardingOpen(true);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscription();
  }, [session, navigate, refreshKey]); // Added refreshKey to dependencies

  // Helper function to ensure user profile exists
  const ensureUserProfileExists = async (user) => {
    if (!user || !user.id) return;

    try {
      // First check if profile already exists
      const { data, error } = await supabase
        .from("user_profiles")
        .select("user_id")
        .eq("user_id", user.id)
        .maybeSingle();

      if (error && error.code !== "PGRST116") {
        console.error("Error checking user profile:", error);
        return;
      }

      // If profile already exists, no need to create one
      if (data) {
        console.log("User profile already exists");
        return;
      }

      // Profile doesn't exist, create it now
      console.log("Creating user profile for:", user.id);
      const createResult = await supabase.from("user_profiles").insert({
        user_id: user.id,
        email: user.email,
        plan: "Free",
      });

      if (createResult.error) {
        console.error("Error creating user profile:", createResult.error);
      } else {
        console.log("User profile created successfully");
      }
    } catch (err) {
      console.error("Exception creating user profile:", err);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  // If premium, render children
  if (isPremium) {
    return children;
  }

  // Otherwise show the onboarding flow modal that leads to payment
  return (
    <>
      <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-blue-50 to-green-50 p-4">
        <div
          className="text-center px-6 py-6 max-w-lg mx-auto bg-white rounded-xl shadow-xl border border-gray-100"
          style={{ maxHeight: "calc(100vh - 40px)", overflowY: "auto" }}
        >
          <div className="mb-4">
            {/* Urgency Bar */}
            {hasEarlyBirdDiscount && (
              <div className="bg-amber-50 border border-amber-200 p-2 rounded-lg mb-4">
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4 text-amber-500 mr-2"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className="font-semibold text-amber-700">
                    Limited Time: 50% Early Bird Discount
                  </span>
                </div>
              </div>
            )}

            <div className="w-16 h-16 mx-auto mb-3 bg-gradient-to-r from-blue-600 to-green-600 rounded-full flex items-center justify-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z"
                />
              </svg>
            </div>

            <h1 className="text-2xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text mb-2">
              MCAT CARS Mastery Program
            </h1>

            <p className="text-gray-700 mb-2 text-base max-w-md mx-auto">
              Hundreds of students improved their CARS score by{" "}
              <span className="font-bold text-blue-600">6 points</span> using
              our system.
            </p>

            <p className="text-gray-600 mb-4 max-w-md mx-auto text-sm">
              The difference between a{" "}
              <span className="line-through text-red-500">124</span> and{" "}
              <span className="font-bold text-green-600">130</span> in CARS
              could get you into your dream medical school.
            </p>

            {/* Value Proposition Badges - More compact layout */}
            <div className="flex flex-wrap justify-center gap-1 mb-4">
              <span className="bg-blue-50 text-blue-700 text-xs font-semibold px-2 py-0.5 rounded-full flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Highest Rated
              </span>
              <span className="bg-green-50 text-green-700 text-xs font-semibold px-2 py-0.5 rounded-full flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812a3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Money-Back Guarantee
              </span>
            </div>

            {/* Benefits Section - More compact grid */}
            <div className="grid grid-cols-2 gap-2 mb-4 text-left">
              <div className="flex items-start space-x-2 p-2 bg-blue-50 rounded-lg border border-blue-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-blue-600 mt-0.5 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <p className="font-medium text-gray-900 text-xs">
                    13,000+ AAMC-Style Questions
                  </p>
                  <p className="text-xs text-gray-600 leading-tight">
                    Authentic practice
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-2 p-2 bg-green-50 rounded-lg border border-green-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-green-600 mt-0.5 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <p className="font-medium text-gray-900 text-xs">
                    24/7 AI Tutor
                  </p>
                  <p className="text-xs text-gray-600 leading-tight">
                    Immediate feedback
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-2 p-2 bg-purple-50 rounded-lg border border-purple-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-purple-600 mt-0.5 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <p className="font-medium text-gray-900 text-xs">
                    Advanced Analytics
                  </p>
                  <p className="text-xs text-gray-600 leading-tight">
                    Pinpoint improvements
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-2 p-2 bg-amber-50 rounded-lg border border-amber-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-amber-600 mt-0.5 flex-shrink-0"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div>
                  <p className="font-medium text-gray-900 text-xs">
                    Double Money-Back
                  </p>
                  <p className="text-xs text-gray-600 leading-tight">
                    100% guaranteed
                  </p>
                </div>
              </div>
            </div>

            {/* Social Proof - Shortened testimonial */}
            <div className="bg-gradient-to-r from-blue-50 to-green-50 p-3 rounded-lg border border-blue-100 shadow-sm mb-4">
              <div className="flex justify-center mb-1">
                {"★★★★★".split("").map((star, i) => (
                  <span key={i} className="text-amber-500">
                    ★
                  </span>
                ))}
              </div>
              <p className="text-xs text-gray-700 mb-1 line-clamp-3">
                "When I got a 124 in CARS, I was devastated. UPangea helped me
                improve to a 129 in 10 weeks. Their passages perfectly capture
                the subtle logical patterns of actual AAMC questions."
              </p>
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 bg-blue-200 rounded-full flex items-center justify-center text-blue-700 font-bold text-xs mr-2">
                  K
                </div>
                <p className="text-xs font-medium text-gray-900">
                  Keisha J., Mayo Clinic
                </p>
              </div>
            </div>
          </div>

          {/* Primary CTA Button - Fixed at bottom with space for Mac dock */}
          <div
            className="sticky bottom-0 bg-white pt-2 pb-2"
            style={{ marginBottom: "10px" }}
          >
            <Button
              onClick={() => setIsOnboardingOpen(true)}
              className="w-full py-4 bg-gradient-to-r from-blue-600 to-green-600 text-white rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:from-blue-700 hover:to-green-700 text-lg font-bold"
            >
              Start My Free CARS Assessment
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 ml-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>

            {/* Trust Builders - More compact */}
            <div className="mt-2 flex flex-col space-y-0.5">
              <div className="flex items-center justify-center text-xs text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 mr-1 text-green-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                Takes just 2 minutes
              </div>
              <div className="flex items-center justify-center text-xs text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3 mr-1 text-green-500"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
                No credit card required
              </div>
            </div>
          </div>
        </div>
      </div>

      <OnboardingFlow
        open={isOnboardingOpen}
        onOpenChange={setIsOnboardingOpen}
        session={session}
        onComplete={handlePaymentSuccess}
        hasEarlyBirdDiscount={hasEarlyBirdDiscount}
      />
    </>
  );
};

export default PaywallMiddleware;
