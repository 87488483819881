import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/ui/button";
import logoImage from "../assets/images/5.svg";

export default function TermsOfService() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-6 h-20 flex items-center bg-white shadow-md bg-opacity-90 backdrop-blur-md">
        <Link className="flex items-center justify-center" to="/">
          <img src={logoImage} alt="UPangea Logo" className="h-14 w-14" />
          <span className="ml-1 text-4xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
            UPangea
          </span>
        </Link>
        <div className="ml-auto">
          <Button
            asChild
            variant="outline"
            className="text-blue-600 border-blue-600 hover:bg-blue-50"
          >
            <Link to="/">Back to Home</Link>
          </Button>
        </div>
      </header>

      {/* Main Content */}
      <main className="container mx-auto px-4 pt-32 pb-16 max-w-4xl">
        <h1 className="text-4xl font-bold mb-8 text-gray-900">
          Terms of Service
        </h1>
        <div className="space-y-8 text-gray-700">
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              1. Agreement to Terms
            </h2>
            <p className="mb-4">
              By accessing and using UPangea's services, you agree to be bound
              by these Terms of Service and all applicable laws and regulations.
              If you disagree with any part of these terms, you may not access
              our services. These Terms of Service constitute a legally binding
              agreement between you and UPangea Inc.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              2. Description of Service
            </h2>
            <p className="mb-4">
              UPangea provides an online MCAT preparation platform that includes
              practice questions, performance tracking, and educational content
              focused on the CARS (Critical Analysis and Reasoning Skills)
              section of the MCAT. Our services include access to approximately
              13,000 AAMC-style CARS practice questions, AI tutoring, analytics,
              and progress tracking.
            </p>
            <p className="mb-4">
              We reserve the right to modify, suspend, or discontinue any aspect
              of our services at any time without prior notice. UPangea may
              introduce new features, remove existing features, or update
              content as needed to maintain quality and relevance.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              3. User Accounts
            </h2>
            <ul className="list-disc pl-6 mb-4">
              <li>
                You must provide accurate, current, and complete information
                when creating an account and keep this information updated
              </li>
              <li>
                You are responsible for maintaining the confidentiality and
                security of your account credentials and for all activities that
                occur under your account
              </li>
              <li>
                Each account is for a single user only; sharing account
                credentials with others is strictly prohibited
              </li>
              <li>
                You must notify us immediately of any unauthorized access to
                your account or any other breach of security
              </li>
              <li>
                We reserve the right to suspend or terminate accounts that
                violate our terms, engage in fraudulent activity, or provide
                false information
              </li>
              <li>
                Users under the age of 18 must have parental or guardian consent
                to use our services
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              4. Subscription and Payments
            </h2>
            <p className="mb-4">
              UPangea offers various subscription plans with different durations
              and pricing. By subscribing to our service, you agree to the
              following:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Subscription fees are billed in advance on a one-time basis
                according to your selected plan duration (1-month, 3-month,
                6-month, or lifetime access)
              </li>
              <li>
                Payment information provided must be accurate and complete, and
                you authorize us to charge the designated payment method for all
                fees incurred
              </li>
              <li>
                All payments are processed through secure third-party payment
                processors, and we do not store complete credit card information
                on our servers
              </li>
              <li>
                Prices are subject to change, but changes will not affect the
                current subscription period for existing subscribers
              </li>
              <li>
                We reserve the right to offer promotional pricing, early bird
                discounts, or special offers at our discretion
              </li>
              <li>
                Failed payments may result in immediate service interruption
                until payment issues are resolved
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              5. Refund Policy and Money-Back Guarantee
            </h2>
            <p className="mb-4">
              UPangea offers a unique money-back guarantee based on the
              following specific conditions:
            </p>
            <h3 className="text-xl font-medium mb-2 text-gray-800">
              5.1 Refund Eligibility
            </h3>
            <p className="mb-2">
              Users are eligible for a 100% refund of their subscription fee if
              they meet EITHER of the following criteria:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                <span className="font-semibold">Achievement-Based Refund:</span>{" "}
                The user scores in the top 10% (90th percentile or higher) on
                the CARS section of their official AAMC MCAT exam. To claim this
                refund, the user must:
                <ul className="list-disc pl-6 mt-2">
                  <li>
                    Provide official AAMC score report documentation showing
                    their CARS score
                  </li>
                  <li>
                    Authorize and facilitate AAMC sending verification of their
                    score directly to UPangea
                  </li>
                  <li>
                    Submit their refund request within 60 days of receiving
                    their official MCAT score
                  </li>
                </ul>
              </li>
              <li className="mt-4">
                <span className="font-semibold">Completion-Based Refund:</span>{" "}
                The user completes all 13,000+ practice questions in the UPangea
                question bank. To qualify for this refund:
                <ul className="list-disc pl-6 mt-2">
                  <li>
                    Questions must be completed with genuine effort and
                    reasonable time spent on each question
                  </li>
                  <li>
                    Our system monitors time spent per question and identifies
                    patterns inconsistent with genuine learning attempts
                  </li>
                  <li>
                    Questions completed in less than a reasonable amount of time
                    (rapid-clicking or automated means) will not count toward
                    the completion total
                  </li>
                  <li>
                    UPangea reserves the right to deny refunds where our systems
                    detect question completion without genuine effort
                  </li>
                  <li>
                    Refund requests must be submitted within 30 days of
                    completing the question bank
                  </li>
                </ul>
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2 text-gray-800">
              5.2 Refund Process
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Refund requests must be submitted through our designated form in
                the user's account dashboard
              </li>
              <li>Required documentation must be attached to the request</li>
              <li>
                Processing time for refund requests is typically 10-15 business
                days
              </li>
              <li>
                Approved refunds will be issued to the original payment method
                used for subscription purchase
              </li>
              <li>
                In cases where the original payment method is no longer
                available, alternative refund methods may be arranged
              </li>
            </ul>

            <h3 className="text-xl font-medium mb-2 text-gray-800">
              5.3 Refund Exceptions
            </h3>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Promotional or discounted subscriptions may have modified refund
                terms, which will be specified at the time of purchase
              </li>
              <li>
                Accounts found to be in violation of our Terms of Service are
                not eligible for refunds
              </li>
              <li>
                UPangea reserves the right to review all refund requests for
                fraud prevention and policy compliance
              </li>
              <li>
                Decisions regarding the genuine effort criterion for question
                completion are at UPangea's sole discretion
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              6. Intellectual Property
            </h2>
            <p className="mb-4">
              All content, features, and functionality of UPangea's services,
              including but not limited to text, graphics, logos, icons, images,
              audio clips, digital downloads, data compilations, software, and
              the compilation thereof, are owned by UPangea, its licensors, or
              other content providers and are protected by international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.
            </p>
            <p className="mb-4">
              Users are granted a limited, non-exclusive, non-transferable, and
              revocable license to access and use UPangea's services for
              personal, non-commercial educational purposes only. This license
              is subject to these Terms of Service and does not include:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Modifying, copying, distributing, transmitting, displaying,
                performing, reproducing, publishing, licensing, creating
                derivative works from, transferring, or selling any content from
                our services
              </li>
              <li>
                Using data mining, robots, or similar data gathering and
                extraction methods
              </li>
              <li>
                Downloading or copying account information for the benefit of
                another party
              </li>
              <li>
                Using any meta tags or hidden text utilizing UPangea's name or
                trademarks
              </li>
              <li>
                Attempting to decipher, decompile, disassemble, or reverse
                engineer any of the software comprising or making up the service
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              7. User Conduct
            </h2>
            <p className="mb-4">
              Users agree to use our services only for lawful purposes and in
              accordance with these Terms of Service. Users specifically agree
              not to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                Share account credentials with others or allow multiple
                individuals to use a single account
              </li>
              <li>
                Copy, download, screenshot, reproduce, distribute, publish, or
                transmit any content from the platform without explicit
                permission
              </li>
              <li>
                Use our services for unauthorized commercial purposes, including
                reselling access or content
              </li>
              <li>
                Attempt to bypass any security measures, monitoring systems, or
                activity tracking
              </li>
              <li>
                Interfere with or disrupt the service or servers or networks
                connected to the service
              </li>
              <li>
                Upload or transmit viruses, malware, or other malicious code
              </li>
              <li>Harvest or collect user information without permission</li>
              <li>
                Manipulate the platform to artificially progress through content
                without engaging with it meaningfully
              </li>
              <li>
                Engage in any activity that is designed to game our refund
                policy or performance metrics
              </li>
              <li>
                Use the service in any manner that could damage, disable,
                overburden, or impair the service
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              8. Data Usage and Analytics
            </h2>
            <p className="mb-4">
              UPangea collects and analyzes user behavior and performance data
              to improve our services and personalize your learning experience.
              By using our services, you acknowledge and consent to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                The collection of your study patterns, time spent on questions,
                answer selections, and performance metrics
              </li>
              <li>
                Analysis of your interactions with content to identify strengths
                and weaknesses
              </li>
              <li>
                Use of your anonymized data for research purposes and platform
                improvements
              </li>
              <li>
                System detection of unusual patterns that may indicate violation
                of our terms
              </li>
              <li>
                Monitoring of progress and completion rates for service
                optimization and refund eligibility verification
              </li>
            </ul>
            <p className="mb-4">
              For complete information on data collection and usage, please
              refer to our{" "}
              <Link to="/privacy" className="text-blue-600 hover:underline">
                Privacy Policy
              </Link>
              .
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              9. Disclaimers and Limitations
            </h2>
            <p className="mb-4">
              UPangea strives to provide high-quality educational content, but
              we make no guarantees regarding MCAT score improvements or
              admission outcomes. Our services are provided "as is" and "as
              available" without warranties of any kind, either express or
              implied.
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>
                We do not guarantee that our services will meet your specific
                requirements or expectations
              </li>
              <li>
                We do not guarantee specific MCAT score improvements, although
                many users experience substantial gains
              </li>
              <li>
                We do not guarantee uninterrupted, secure, or error-free service
              </li>
              <li>Any reliance on our materials is at your own risk</li>
              <li>
                While our content aims to simulate AAMC MCAT questions, we are
                not affiliated with AAMC
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              10. Limitation of Liability
            </h2>
            <p className="mb-4">
              To the maximum extent permitted by law, UPangea and its officers,
              directors, employees, and agents shall not be liable for any
              indirect, incidental, special, consequential, or punitive damages
              resulting from your use or inability to use our services,
              including but not limited to:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Loss of profits, revenue, data, or anticipated savings</li>
              <li>Medical school application outcomes or test results</li>
              <li>Damages for service interruption or system failures</li>
              <li>Cost of substitute services</li>
              <li>Any other damages arising from your use of our services</li>
            </ul>
            <p className="mb-4">
              Our total liability for any claims arising under these terms shall
              be limited to the amount you paid for your subscription in the 12
              months preceding the claim.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              11. Indemnification
            </h2>
            <p className="mb-4">
              You agree to indemnify, defend, and hold harmless UPangea, its
              affiliates, officers, directors, employees, and agents from and
              against any and all claims, liabilities, damages, losses, costs,
              expenses, or fees (including reasonable attorneys' fees) arising
              from:
            </p>
            <ul className="list-disc pl-6 mb-4">
              <li>Your violation of these Terms of Service</li>
              <li>Your violation of any rights of another</li>
              <li>Your misuse of our services</li>
              <li>
                Any content you submit, post, or transmit through our services
              </li>
            </ul>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              12. Term and Termination
            </h2>
            <p className="mb-4">
              These Terms of Service will remain in full force and effect while
              you use UPangea's services. We reserve the right to terminate or
              suspend your account and access to our services immediately,
              without prior notice or liability, for any reason whatsoever,
              including, without limitation, if you breach these Terms of
              Service.
            </p>
            <p className="mb-4">
              Upon termination, your right to use the service will immediately
              cease. If your account is terminated for your breach of these
              terms, you may not be eligible for refunds of any kind.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              13. Changes to Terms
            </h2>
            <p className="mb-4">
              We reserve the right to modify, revise, or update these Terms of
              Service at any time by posting the amended terms on our website.
              Your continued use of our services after the posting of any
              changes constitutes acceptance of such changes.
            </p>
            <p className="mb-4">
              For material changes to these terms, we will make reasonable
              efforts to notify you via email or through a notification on our
              platform. It is your responsibility to review these Terms of
              Service periodically for changes.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              14. Governing Law
            </h2>
            <p className="mb-4">
              These Terms of Service shall be governed by and construed in
              accordance with the laws of the State of California, without
              regard to its conflict of law provisions. You agree to submit to
              the personal and exclusive jurisdiction of the courts located
              within San Francisco County, California.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              15. Severability
            </h2>
            <p className="mb-4">
              If any provision of these Terms of Service is held to be invalid
              or unenforceable, such provision shall be struck and the remaining
              provisions shall be enforced to the fullest extent under law.
            </p>
          </section>

          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">
              16. Contact Information
            </h2>
            <p>
              For questions about these Terms of Service or any other inquiries,
              please contact us at:{" "}
              <a
                href="mailto:team@upangea.com"
                className="text-blue-600 hover:underline"
              >
                team@upangea.com
              </a>
            </p>
          </section>

          <section>
            <p className="mt-8 font-medium">Last Updated: March 1, 2024</p>
          </section>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 py-6">
        <div className="container mx-auto px-4">
          <div className="text-center">
            <p className="text-sm">© 2024 UPangea Inc. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
