import heroImage from "../assets/images/heroisit.svg";
import logoImage from "../assets/images/5.svg";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import { Slider } from "../components/ui/slider";
import {
  CheckCircle,
  Star,
  Users,
  Brain,
  TrendingUp,
  BarChart,
  ChevronDown,
  ChevronUp,
  MenuIcon,
  Check,
  MessageSquare,
  HelpCircle,
  PlusCircle,
  Mail,
  RocketIcon,
  CalendarIcon,
  ShieldCheck,
  Clock,
  Headset,
  BrainIcon,
  ArrowRight,
  ChevronRight,
  Calculator,
  BarChart2,
  Zap,
  Sparkles,
  CheckCheck,
  User,
  LogOut,
  LogIn
} from "lucide-react";
import { Switch } from "../components/ui/switch";
import { supabase } from "../supabaseClient";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Dialog, DialogContent } from "../components/ui/dialog";
import { SessionContext } from "../index";
import { cn } from "../lib/utils";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "../components/ui/accordion";

// Authentication footer component
const AuthFooter = ({ view, setView }) => {
  return (
    <div className="flex flex-col items-center gap-2 mt-4 text-sm text-gray-600">
      {view === "sign_in" && (
        <button
          onClick={() => setView("forgotten_password")}
          className="text-blue-600 hover:text-blue-800 font-medium"
        >
          Forgot your password?
        </button>
      )}
      {view === "sign_in" ? (
        <div>
          Don't have an account?{" "}
          <button
            onClick={() => setView("sign_up")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign up
          </button>
        </div>
      ) : view === "forgotten_password" ? (
        <div>
          Remember your password?{" "}
          <button
            onClick={() => setView("sign_in")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign in
          </button>
        </div>
      ) : (
        <div>
          Already have an account?{" "}
          <button
            onClick={() => setView("sign_in")}
            className="text-blue-600 hover:text-blue-800 font-medium"
          >
            Sign in
          </button>
        </div>
      )}
    </div>
  );
};

// Determine redirection URL post-auth
const getRedirectUrl = () => {
  // Use the current origin instead of hardcoding domains
  return `${window.location.origin}/dashboard`;
};

export default function LandingPage() {
  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  // Adding state for FAQ accordion
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  // CARS-Only Score State
  const [carsScore, setCarsScore] = useState(123);
  
  // Hours per week to study
  const [hoursPerWeek, setHoursPerWeek] = useState(4); // Default to 4 hours

  const [weeksUntilTest, setWeeksUntilTest] = useState(12);

  const [showSupabaseAuth, setShowSupabaseAuth] = useState(false);

  // Add structured data for SEO
  useEffect(() => {
    const structuredData = {
      "@context": "https://schema.org",
      "@type": "WebSite",
      name: "UPangea MCAT CARS Practice",
      description:
        "The most authentic MCAT CARS practice with 13,000+ AAMC-style questions and AI tutoring for pre-med students.",
      url: "https://www.upangea.com/",
      potentialAction: {
        "@type": "SearchAction",
        target: "https://www.upangea.com/search?q={search_term_string}",
        "query-input": "required name=search_term_string",
      },
      offers: {
        "@type": "AggregateOffer",
        lowPrice: "97",
        highPrice: "447",
        priceCurrency: "USD",
        offerCount: "4",
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "4.9",
        ratingCount: "1024",
        bestRating: "5",
        worstRating: "1",
      },
      keywords:
        "MCAT CARS practice, AAMC CARS questions, MCAT reading comprehension, pre-med CARS practice, CARS section help, MCAT verbal reasoning",
    };

    // Add structured data to head
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.text = JSON.stringify(structuredData);
    document.head.appendChild(script);

    // Also add FAQ structured data
    const faqStructuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: faqItems.map((item) => ({
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.answer,
        },
      })),
    };

    const faqScript = document.createElement("script");
    faqScript.type = "application/ld+json";
    faqScript.text = JSON.stringify(faqStructuredData);
    document.head.appendChild(faqScript);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(faqScript);
    };
  }, []);

  const navigate = useNavigate();
  const session = useContext(SessionContext);

  // Refs for scrolling
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const pricingRef = useRef(null);
  const aboutRef = useRef(null);

  // User testimonials
  const testimonials = [
    {
      name: "Taylor W.",
      location: "Nashville, TN",
      score: "122 → 127 in CARS",
      school: "Pre-med Student",
      quote:
        "After struggling with CARS, I was looking for a different approach. The UPangea passages seemed to better match the MCAT style. The AI tutor helped me identify that I wasn't picking up on authors' implicit assumptions. With consistent practice over several weeks, I improved my score. This platform offers a unique approach that might work well for students who haven't found success with traditional methods.",
    },
    {
      name: "Priya G.",
      location: "Chicago, IL",
      score: "123 → 126 in CARS",
      school: "Pre-med Student",
      quote:
        "As a non-native English speaker, CARS was challenging for me. What I found helpful about UPangea was the highlighting tool that showed which sentences I was overlooking. The AI tutor identified specific tone shifts I had trouble detecting. While improvement requires consistent work, I found the tools helpful for addressing my specific challenges with reading comprehension and analysis.",
    },
    {
      name: "Josh F.",
      location: "Boston, MA",
      score: "124 → 128 in CARS",
      school: "Pre-med Student",
      quote:
        "Despite my background in philosophy, I was surprised by my initial CARS diagnostic score. UPangea's analytics helped me identify where I was overthinking certain question types. The way they categorize wrong answers by reasoning pattern was particularly helpful. My score improved after several months of regular practice. The platform helped me develop a more effective approach to CARS passages.",
    },
    {
      name: "Mike T.",
      location: "San Francisco, CA",
      score: "123 → 126 in CARS",
      school: "Pre-med Student",
      quote:
        "My timing was a major issue with CARS. UPangea's analytics showed that I was spending too much time on certain questions in each passage. The personalized drills helped me adjust my pacing habits. While everyone's experience varies, I found that addressing specific weaknesses with targeted practice was key to my improvement.",
    },
  ];

  // Sample FAQ items
  const faqItems = [
    {
      question: "What's so special about your CARS passages?",
      answer:
        "We've designed our question bank to closely follow the AAMC style and format, with a special focus on the types of reasoning and critical thinking that appear on the actual exam. Our goal is to create practice materials that prepare you effectively for test day by mimicking the passage types and question patterns you'll encounter.",
    },
    {
      question:
        "What makes UPangea different from other MCAT CARS prep options?",
      answer:
        "While other prep companies divide focus across all MCAT sections, UPangea specializes exclusively in CARS mastery. Our extensive question bank helps you develop the specific skills needed for this challenging section, and our AI tutor provides immediate feedback on your approach.",
    },
    {
      question: "How is UPenguin more effective than regular tutors?",
      answer:
        "Our AI tutor is available 24/7, providing immediate feedback when you need it most. This means you can practice at your convenience without scheduling constraints. The AI analyzes your specific performance patterns to help identify your strengths and areas for improvement, offering personalized guidance that adapts to your learning needs.",
    },
    {
      question: "What kind of score improvement can I expect?",
      answer:
        "Score improvements vary widely based on starting point, time commitment, and consistency of practice. Based on our beta users, many students see meaningful improvements after completing regular practice sessions over several weeks. While we can't guarantee specific point increases, our comprehensive approach is designed to help you maximize your potential through targeted practice and feedback.",
    },
    {
      question: "Do you offer a satisfaction guarantee?",
      answer:
        "Absolutely. We're so confident in our platform's ability to help you improve that we offer a unique guarantee: if you complete our entire 13,000+ question bank OR score in the top 10% of the MCAT CARS section, you'll get your money back. We're fully committed to your success and willing to stand behind our platform's effectiveness.",
    },
  ];

  // Visibility checks for hero animations
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.1 },
    );
    if (heroRef.current) observer.observe(heroRef.current);
    return () => {
      if (heroRef.current) observer.unobserve(heroRef.current);
    };
  }, []);

  // CARS-only improvement logic
  const calculatePotentialImprovement = () => {
    // More modest potential improvement estimate
    const maxPossible = 132;
    const projected = carsScore + 6;
    return projected > maxPossible ? maxPossible : projected;
  };

  const calculatePotentialScore = () => {
    // More sophisticated calculation that accounts for:
    // 1. Diminishing returns as scores get higher
    // 2. Intensity of study (hours per week)
    // 3. Duration of study (weeks until test)
    // 4. Starting point difficulty (harder to improve from higher scores)
    
    // Calculate base potential for improvement
    const startingDifficulty = Math.max(0.5, 1 - ((carsScore - 117) / (132 - 117)));
    
    // Calculate effective study hours with diminishing returns
    const effectiveHoursPerWeek = hoursPerWeek <= 6 
      ? hoursPerWeek 
      : 6 + (hoursPerWeek - 6) * 0.7; // Diminishing returns after 6 hours/week
    
    // Calculate time-dependent improvement factor
    // First 8 weeks are most effective, then diminishing returns
    const earlyWeeks = Math.min(weeksUntilTest, 8);
    const laterWeeks = Math.max(0, weeksUntilTest - 8);
    const timeEffect = (earlyWeeks * 1.0) + (laterWeeks * 0.6);
    
    // Raw improvement potential based on time and effort
    let rawImprovement = (effectiveHoursPerWeek / 4) * timeEffect * startingDifficulty;
    
    // Apply scaling factor and cap
    const scalingFactor = 0.6; // Tune this to adjust overall improvement rate
    const maxImprovement = startingDifficulty * 15; // Maximum possible improvement (theoretical)
    const cappedMaxImprovement = Math.min(maxImprovement, 132 - carsScore); // Can't exceed 132
    
    // Final calculated improvement with realistic caps
    const improvement = Math.min(rawImprovement * scalingFactor, cappedMaxImprovement);
    
    // Round to whole numbers as MCAT scores don't have decimals
    const roundedImprovement = Math.round(improvement);
    
    return Math.round(carsScore + roundedImprovement);
  };

  const calculateTotalPassages = () => {
    // More realistic passage calculation with higher requirements
    // MCAT CARS improvement requires significant practice
    
    // Base passages needed per point of improvement
    const basePassagesPerPoint = 15; // Base number of passages per point improvement
    
    // Calculate projected improvement
    const projectedScore = calculatePotentialScore();
    const pointsImprovement = projectedScore - carsScore;
    
    // Calculate base passages needed
    let basePassages = pointsImprovement * basePassagesPerPoint;
    
    // Adjust for starting difficulty - higher scores require more practice per point
    const startingScoreFactor = 1 + ((carsScore - 117) / 30); // 1.0 at 117, up to 1.5 at 132
    
    // Adjust for time compression - shorter study periods require more intensive practice
    const timeCompressionFactor = 1 + (0.5 * (1 - (weeksUntilTest / 24))); // 1.0 at 24 weeks, 1.5 at 4 weeks
    
    // Adjust for study intensity - fewer hours per week means more total passages needed
    // (to account for forgetting between sessions)
    const intensityFactor = 1 + (0.3 * (1 - (hoursPerWeek / 12))); // 1.0 at 12 hours/week, 1.3 at 2 hours/week
    
    // Apply all adjustment factors
    const adjustedPassages = basePassages * startingScoreFactor * timeCompressionFactor * intensityFactor;
    
    // Ensure a minimum number of passages based on weeks of study
    const minimumPassages = weeksUntilTest * 5; // At least 5 passages per week
    
    return Math.max(Math.round(adjustedPassages), minimumPassages);
  };

  // Scrolling function
  const scrollTo = (elementRef) =>
    elementRef.current?.scrollIntoView({ behavior: "smooth" });

  // Auth & session
  const handleLogin = () => setShowSupabaseAuth("sign_in");
  const handleSignUp = () => setShowSupabaseAuth("sign_up");
  const handleLogout = () => supabase.auth.signOut();
  const handleLearnMore = () =>
    (window.location.href =
      "mailto:team@upangea.com?subject=Schedule%20Demo&body=I%20would%20like%20to%20schedule%20a%20demo%20of%20UPangea.");

  // Check session on mount
  useEffect(() => {
    if (session) navigate("/dashboard");
  }, [session]);

  // Listen for auth changes (for Supabase)
  useEffect(() => {
    const params = new URLSearchParams(window.location.hash.substring(1));
    const accessToken = params.get("access_token");
    if (accessToken) {
      window.location.replace("/dashboard");
      return;
    }

    const checkSession = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        window.location.href = `${window.location.origin}/dashboard`;
      }
    };
    checkSession();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === "SIGNED_IN" || event === "SIGNED_UP")) {
        window.location.href = `${window.location.origin}/dashboard`;
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    const handleAuthChange = async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();
      if (session) {
        // Check if user has early bird discount before redirecting
        const { data, error } = await supabase
          .from("user_profiles")
          .select("early_bird_discount")
          .eq("user_id", session.user.id)
          .single();

        if (!error && data) {
          setHasEarlyBirdDiscount(data.early_bird_discount === true);
        }

        // Use window.location.origin instead of hardcoded redirect
        window.location.replace(`${window.location.origin}/dashboard`);
      }
    };
    handleAuthChange();

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session && (event === "SIGNED_IN" || event === "SIGNED_UP")) {
        // Use window.location.origin instead of hardcoded redirect
        window.location.replace(`${window.location.origin}/dashboard`);
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  // Mobile Nav
  const renderMobileNav = () => (
    <Sheet>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="md:hidden p-0 w-12 h-12 rounded-full aspect-square flex items-center justify-center group transition-all duration-200 hover:bg-blue-50"
        >
          <MenuIcon className="h-5 w-5 p-0 transition-transform duration-300 group-hover:scale-110 text-gray-700 group-hover:text-blue-600" />
          <span className="sr-only">Toggle menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[300px] sm:w-[380px] px-0 py-6 border-l border-gray-100 dark:border-gray-800">
        <SheetHeader className="px-6 mb-8">
          <div className="flex items-center">
            <div className="relative">
              <img src={logoImage} alt="UPangea Logo" className="h-12 w-12 object-contain" />
            </div>
            <SheetTitle className="text-2xl ml-2.5 bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
              UPangea
            </SheetTitle>
          </div>
        </SheetHeader>
        <nav className="px-6">
          <div className="space-y-3">
            <button
              onClick={() => {
                scrollTo(aboutRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-4 py-3 text-base font-medium text-gray-700 hover:text-blue-600 transition-all duration-200 rounded-lg hover:bg-blue-50/80 flex items-center group"
            >
              <div className="h-8 w-8 rounded-full bg-blue-50 flex items-center justify-center mr-3 transition-all duration-300 group-hover:bg-blue-100">
                <Brain className="h-4 w-4 text-blue-500 transition-transform duration-300 group-hover:scale-110" />
              </div>
              <span className="flex-1">Why CARS?</span>
              <ChevronRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-300 -translate-x-2 group-hover:translate-x-0" />
            </button>
            <button
              onClick={() => {
                scrollTo(featuresRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-4 py-3 text-base font-medium text-gray-700 hover:text-blue-600 transition-all duration-200 rounded-lg hover:bg-blue-50/80 flex items-center group"
            >
              <div className="h-8 w-8 rounded-full bg-green-50 flex items-center justify-center mr-3 transition-all duration-300 group-hover:bg-green-100">
                <CheckCircle className="h-4 w-4 text-green-500 transition-transform duration-300 group-hover:scale-110" />
              </div>
              <span className="flex-1">Features</span>
              <ChevronRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-300 -translate-x-2 group-hover:translate-x-0" />
            </button>
            <button
              onClick={() => {
                scrollTo(pricingRef);
                document.querySelector('[data-state="open"]')?.click();
              }}
              className="w-full text-left px-4 py-3 text-base font-medium text-gray-700 hover:text-blue-600 transition-all duration-200 rounded-lg hover:bg-blue-50/80 flex items-center group"
            >
              <div className="h-8 w-8 rounded-full bg-purple-50 flex items-center justify-center mr-3 transition-all duration-300 group-hover:bg-purple-100">
                <BarChart className="h-4 w-4 text-purple-500 transition-transform duration-300 group-hover:scale-110" />
              </div>
              <span className="flex-1">Pricing</span>
              <ChevronRight className="h-4 w-4 text-gray-400 opacity-0 group-hover:opacity-100 transition-all duration-300 -translate-x-2 group-hover:translate-x-0" />
            </button>
          </div>
          <div className="mt-10 space-y-3">
            {session ? (
              <>
                <div className="mb-6 px-4 py-3 bg-blue-50 rounded-lg border border-blue-100">
                  <div className="flex items-center mb-2">
                    <User className="h-4 w-4 text-blue-500 mr-2" />
                    <span className="text-sm font-medium text-gray-600">Logged in as</span>
                  </div>
                  <p className="text-base font-semibold text-blue-600">{session.user.name}</p>
                </div>
                <Button
                  onClick={() => navigate("/dashboard")}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 flex items-center justify-center group py-5"
                >
                  <Users className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:scale-110" />
                  Dashboard
                  <div className="ml-2 w-4 h-4 rounded-full bg-white/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <ChevronRight className="h-3 w-3 text-white" />
                  </div>
                </Button>
                <Button
                  onClick={handleLogout}
                  variant="outline"
                  className="w-full text-blue-600 border-blue-200 hover:border-blue-300 hover:bg-blue-50 py-5"
                >
                  <LogOut className="h-5 w-5 mr-2 opacity-70" />
                  Log Out
                </Button>
              </>
            ) : (
              <>
                <Button
                  onClick={handleLogin}
                  variant="outline"
                  className="w-full text-blue-600 border-blue-200 hover:border-blue-300 hover:bg-blue-50 py-5"
                >
                  <LogIn className="h-5 w-5 mr-2 opacity-70" />
                  Log In
                </Button>
                <Button
                  onClick={handleSignUp}
                  className="w-full bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 flex items-center justify-center group py-5"
                >
                  <Star className="h-5 w-5 mr-2 transition-transform duration-300 group-hover:scale-110" />
                  Sign Up Now
                  <div className="ml-2 w-4 h-4 rounded-full bg-white/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    <ChevronRight className="h-3 w-3 text-white" />
                  </div>
                </Button>
              </>
            )}
          </div>
        </nav>
      </SheetContent>
    </Sheet>
  );

  // Add state for selected pricing duration and early bird discount
  const [selectedDuration, setSelectedDuration] = useState("3month"); // Default to most popular
  const [hasEarlyBirdDiscount, setHasEarlyBirdDiscount] = useState(false); // Will be set from user profile

  // Standard pricing options
  const standardPricingOptions = {
    "1month": {
      label: "1 Month Access",
      price: "97",
      originalPrice: "129",
      period: "for 1 month",
      description: "Great for last-minute prep or trying out the platform",
    },
    "3month": {
      label: "3 Months Access",
      price: "247",
      originalPrice: "327",
      period: "for 3 months",
      description: "Perfect for dedicated CARS prep during intensive study",
      popular: true,
    },
    "6month": {
      label: "6 Months Access",
      price: "347",
      originalPrice: "459",
      period: "for 6 months",
      description: "For long-term practice, early starters, or slower pace",
    },
    lifetime: {
      label: "Lifetime Access",
      price: "447",
      originalPrice: "599",
      period: "one-time",
      description: "One-time investment, unlimited CARS practice forever",
    },
  };

  // Early bird pricing options (50% off)
  const earlyBirdPricingOptions = {
    "1month": {
      label: "1 Month Access",
      price: "49",
      originalPrice: "97",
      period: "for 1 month",
      description: "Great for last-minute prep or trying out the platform",
    },
    "3month": {
      label: "3 Months Access",
      price: "124",
      originalPrice: "247",
      period: "for 3 months",
      description: "Perfect for dedicated CARS prep during intensive study",
      popular: true,
    },
    "6month": {
      label: "6 Months Access",
      price: "174",
      originalPrice: "347",
      period: "for 6 months",
      description: "For long-term practice, early starters, or slower pace",
    },
    lifetime: {
      label: "Lifetime Access",
      price: "224",
      originalPrice: "447",
      period: "one-time",
      description: "One-time investment, unlimited CARS practice forever",
    },
  };

  // Select pricing options based on early bird status
  const pricingOptions = hasEarlyBirdDiscount
    ? earlyBirdPricingOptions
    : standardPricingOptions;

  return (
    <div className="flex flex-col min-h-screen bg-gradient-to-b from-blue-50 to-green-50">
      <Helmet>
        <title>
          MCAT CARS Practice | 13,000+ AAMC-Style Questions | UPangea
        </title>
        <meta
          name="description"
          content="Master MCAT CARS with UPangea's 13,000+ AAMC-style practice questions and AI tutor. Our authentic CARS question bank helps pre-med students achieve 4-8 point score improvements."
        />
        <meta
          name="keywords"
          content="MCAT CARS practice, MCAT CARS questions, AAMC CARS prep, MCAT CARS strategies, CARS section MCAT, MCAT reading comprehension, MCAT CARS AI tutor, medical school admission"
        />
        <meta
          property="og:title"
          content="MCAT CARS Practice | 13,000+ AAMC-Style Questions | UPangea"
        />
        <meta
          property="og:description"
          content="Master MCAT CARS with UPangea's 13,000+ AAMC-style practice questions and AI tutor. Get a 4-8 point score improvement."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.upangea.com" />
        <meta
          property="og:image"
          content="https://www.upangea.com/opengraph-image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="MCAT CARS Practice | UPangea" />
        <meta
          name="twitter:description"
          content="13,000+ AAMC-style CARS practice questions with AI tutor"
        />
        <meta
          name="twitter:image"
          content="https://www.upangea.com/twitter-image.jpg"
        />
        <link rel="canonical" href="https://www.upangea.com" />
      </Helmet>
      {/* Header */}
      <header className="fixed top-0 left-0 right-0 z-50 px-4 lg:px-8 h-16 md:h-20 flex items-center bg-white shadow-sm bg-opacity-95 backdrop-blur transition-all duration-300 border-b border-gray-100 dark:border-gray-800">
        <Link
          className="flex items-center group"
          to="/"
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          aria-label="UPangea MCAT CARS Practice Platform"
        >
          <div className="relative">
            <img
              src={logoImage}
              alt="UPangea MCAT CARS Practice Platform Logo"
              className="h-10 w-10 md:h-14 md:w-14 object-contain transition-transform duration-300 group-hover:scale-110"
              loading="eager"
            />
          </div>
          <span className="ml-2.5 text-2xl md:text-3xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text transition-all duration-300 group-hover:from-blue-700 group-hover:to-green-700">
            UPangea
          </span>
        </Link>

        {/* Desktop Navigation */}
        <nav className="ml-auto hidden md:flex gap-6 items-center">
          <div className="flex items-center space-x-3">
            <button
              onClick={() => scrollTo(aboutRef)}
              className="group px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-blue-50/80 transition-all duration-200 flex items-center relative"
            >
              <span className="absolute left-0 bottom-0 h-0.5 w-0 bg-gradient-to-r from-blue-500 to-blue-300 transition-all duration-300 group-hover:w-full opacity-0 group-hover:opacity-100"></span>
              <Brain className="h-4 w-4 mr-1.5 text-blue-500 transition-transform duration-300 group-hover:scale-110" />
              <span>Why CARS?</span>
            </button>
            <button
              onClick={() => scrollTo(featuresRef)}
              className="group px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-blue-50/80 transition-all duration-200 flex items-center relative"
            >
              <span className="absolute left-0 bottom-0 h-0.5 w-0 bg-gradient-to-r from-green-500 to-green-300 transition-all duration-300 group-hover:w-full opacity-0 group-hover:opacity-100"></span>
              <CheckCircle className="h-4 w-4 mr-1.5 text-green-500 transition-transform duration-300 group-hover:scale-110" />
              <span>Features</span>
            </button>
            <button
              onClick={() => scrollTo(pricingRef)}
              className="group px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-blue-600 hover:bg-blue-50/80 transition-all duration-200 flex items-center relative"
            >
              <span className="absolute left-0 bottom-0 h-0.5 w-0 bg-gradient-to-r from-purple-500 to-purple-300 transition-all duration-300 group-hover:w-full opacity-0 group-hover:opacity-100"></span>
              <BarChart className="h-4 w-4 mr-1.5 text-purple-500 transition-transform duration-300 group-hover:scale-110" />
              <span>Pricing</span>
            </button>
          </div>
          {session ? (
            <div className="flex items-center space-x-4 ml-6">
              <div className="hidden lg:flex items-center space-x-2 px-3.5 py-1.5 bg-blue-50 rounded-full border border-blue-100 transition-all duration-200 hover:bg-blue-100 group">
                <User className="h-3.5 w-3.5 text-blue-500 transition-transform duration-300 group-hover:scale-110" />
                <span className="text-sm font-medium text-gray-600">
                  Welcome
                </span>
                <span className="text-sm font-semibold text-blue-600">
                  {session.user.name}
                </span>
              </div>
              <Button
                onClick={() => navigate("/dashboard")}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:shadow-md hover:from-blue-700 hover:to-green-700 transition-all duration-300 flex items-center group px-4"
              >
                <Users className="h-4 w-4 mr-2 transition-transform duration-300 group-hover:scale-110" />
                Dashboard
                <div className="ml-2 w-4 h-4 rounded-full bg-white/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <ChevronRight className="h-3 w-3 text-white" />
                </div>
              </Button>
              <Button
                onClick={handleLogout}
                variant="outline"
                className="text-blue-600 border-blue-200 hover:border-blue-300 hover:bg-blue-50/80 transition-all duration-200"
              >
                <LogOut className="h-4 w-4 mr-2 opacity-70" />
                Log Out
              </Button>
            </div>
          ) : (
            <div className="flex items-center space-x-3 ml-6">
              <Button
                onClick={handleLogin}
                variant="outline"
                className="text-blue-600 border-blue-200 hover:border-blue-300 hover:bg-blue-50/80 transition-all duration-200"
              >
                <LogIn className="h-4 w-4 mr-2 opacity-70" />
                Log In
              </Button>
              <Button
                onClick={handleSignUp}
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:shadow-md hover:from-blue-700 hover:to-green-700 transition-all duration-300 flex items-center group px-4"
              >
                <Star className="h-4 w-4 mr-2 transition-transform duration-300 group-hover:scale-110" />
                Sign Up Now
                <div className="ml-2 w-4 h-4 rounded-full bg-white/20 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <ChevronRight className="h-3 w-3 text-white" />
                </div>
              </Button>
            </div>
          )}
        </nav>

        {/* Mobile Nav */}
        <div className="ml-auto md:hidden">{renderMobileNav()}</div>
      </header>

      {/* Authentication Modal */}
      <Dialog
        open={showSupabaseAuth !== false}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            const params = new URLSearchParams(window.location.search);
            const type = params.get("type");
            if (type === "recovery") return;
            setShowSupabaseAuth(false);
          }
        }}
      >
        <DialogContent className="sm:max-w-md bg-white p-0 rounded-lg overflow-hidden">
          <div className="bg-gradient-to-r from-blue-600 to-green-600 p-4 mx-2 mt-2">
            <div className="flex items-center justify-center mb-4">
              <img src={logoImage} alt="UPangea Logo" className="h-12 w-12" />
              <span className="ml-2 text-3xl font-bold text-white">
                UPangea
              </span>
            </div>
            <h2 className="text-xl text-center text-white font-medium">
              {showSupabaseAuth === "sign_up"
                ? "Join Our Beta for CARS Mastery"
                : "Welcome Back"}
            </h2>
          </div>
          <div className="px-6 py-0">
            <Auth
              supabaseClient={supabase}
              view={showSupabaseAuth}
              appearance={{
                theme: ThemeSupa,
                extend: true,
                className: {
                  container: "space-y-0",
                  button: cn(
                    "bg-gradient-to-r from-blue-600 to-green-600",
                    "hover:from-blue-700 hover:to-green-700",
                    "text-white",
                  ),
                  divider: "hidden",
                  container_button: "hidden",
                  button_provider: "hidden",
                  provider: "hidden",
                },
                style: {
                  anchor: { display: "none" },
                  button: {
                    color: "white",
                    fontWeight: "500",
                  },
                },
              }}
              providers={["google"]}
              redirectTo={getRedirectUrl()}
            />
            <AuthFooter
              view={showSupabaseAuth}
              setView={(newView) => {
                setShowSupabaseAuth(newView);
              }}
            />
          </div>
        </DialogContent>
      </Dialog>

      <main className="flex-1 pt-16 md:pt-0">
        {/* Hero Section */}
        <section
          ref={heroRef}
          className={`w-full py-16 md:py-28 lg:py-36 xl:py-40 bg-gradient-to-br from-blue-700 via-blue-600 to-green-600 transition-all duration-1000 relative overflow-hidden ${
            isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
          }`}
        >
          {/* Enhanced abstract background shapes with animation */}
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute -top-24 -left-24 w-96 h-96 bg-blue-400 rounded-full opacity-20 blur-3xl animate-pulse" style={{ animationDuration: "8s" }}></div>
            <div className="absolute top-1/4 -right-24 w-80 h-80 bg-green-400 rounded-full opacity-20 blur-3xl animate-pulse" style={{ animationDelay: "1s", animationDuration: "10s" }}></div>
            <div className="absolute bottom-0 left-1/3 w-72 h-72 bg-blue-300 rounded-full opacity-20 blur-3xl" style={{ animationDelay: "2s", animationDuration: "9s" }}></div>
            <div className="absolute bottom-1/4 right-1/3 w-64 h-64 bg-indigo-400 rounded-full opacity-20 blur-3xl animate-pulse" style={{ animationDelay: "1.5s", animationDuration: "12s" }}></div>
            <div className="absolute top-1/2 left-1/4 w-48 h-48 bg-cyan-400 rounded-full opacity-10 blur-3xl animate-pulse" style={{ animationDelay: "3s", animationDuration: "9s" }}></div>
          </div>
          
          {/* Subtle grid pattern overlay */}
          <div className="absolute inset-0 bg-grid-white/[0.03] bg-[size:20px_20px] pointer-events-none"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="flex flex-col lg:flex-row items-center gap-12 lg:gap-16">
              <div className="space-y-8 text-center lg:text-left lg:w-1/2 max-w-2xl mx-auto lg:mx-0">
                <div className="space-y-5">
                  {hasEarlyBirdDiscount && (
                    <div className="animate-pulse">
                      <span className="inline-flex items-center px-4 py-2 text-sm font-semibold bg-white/20 text-white rounded-full backdrop-blur-sm border border-white/30 shadow-lg transition-all duration-300 hover:bg-white/30 hover:scale-105">
                        <Star className="h-4 w-4 text-yellow-300 mr-1.5 flex-shrink-0" />
                        <span>Limited Time: 50% Early Bird Discount</span>
                      </span>
                    </div>
                  )}
                  <div>
                    <span className="inline-flex items-center px-4 py-2 text-sm font-semibold bg-white/20 text-white rounded-full backdrop-blur-sm border border-white/30 shadow-lg transition-all duration-300 hover:bg-white/30 hover:scale-105">
                      <CheckCircle className="h-4 w-4 text-green-300 mr-1.5 flex-shrink-0" />
                      <span>Official Launch: AAMC-Style CARS Excellence</span>
                    </span>
                  </div>
                  <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl lg:text-7xl mt-6">
                    <span className="block leading-tight font-extrabold" style={{ textShadow: "0 2px 10px rgba(0,0,0,0.1)" }}>
                      Finally, Authentic
                    </span>
                    <span className="block leading-tight bg-gradient-to-r from-white to-blue-200 text-transparent bg-clip-text font-extrabold">
                      MCAT CARS
                    </span>
                    <span className="block text-blue-100 text-2xl sm:text-3xl md:text-4xl mt-5 font-semibold tracking-normal">
                      13,000+ AAMC-Style Questions
                    </span>
                  </h1>
                </div>
                <p className="text-lg md:text-xl text-blue-50 max-w-[600px] mx-auto lg:mx-0 leading-relaxed font-medium">
                  <span>Tired of unrepresentative CARS passages from test prep giants? </span>
                  <span className="font-bold">UPangea is the only platform laser-focused on authentic AAMC CARS mastery.</span> Our comprehensive 13,000+ CARS question bank and personalized 24/7 AI tutor will transform your MCAT reading comprehension performance and boost your medical school application.
                </p>
                <div className="bg-white/15 backdrop-blur-md rounded-xl px-6 py-5 border border-white/30 shadow-lg transition-all duration-300 hover:bg-white/20 hover:-translate-y-1">
                  <p className="text-sm text-white flex items-center">
                    <Star className="w-5 h-5 mr-3 text-yellow-300 flex-shrink-0" />
                    <span>
                      100% Refundable Deposit: Complete all questions{" "}
                      <span className="font-bold underline decoration-2 decoration-yellow-300">
                        OR
                      </span>{" "}
                      score in top 10% for CARS—get your{" "}
                      <span className="font-bold underline decoration-2 decoration-yellow-300">
                        entire deposit back
                      </span>
                    </span>
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row gap-5 mt-8 justify-center lg:justify-start">
                  <Button
                    size="lg"
                    variant="white"
                    className="w-full sm:w-auto shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:scale-105 font-bold text-base bg-white text-blue-700 border-0 px-8 py-6 rounded-xl group"
                    onClick={handleSignUp}
                  >
                    Start Personalized Assessment
                    <ArrowRight className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-x-1" />
                  </Button>
                  <Button
                    size="lg"
                    variant="outline"
                    className="w-full sm:w-auto border-2 text-white border-white hover:bg-white/15 transition-all duration-300 text-base px-8 py-6 rounded-xl group"
                    onClick={() => scrollTo(featuresRef)}
                  >
                    See Features
                    <ChevronDown className="ml-2 w-5 h-5 transition-transform duration-300 group-hover:translate-y-1" />
                  </Button>
                </div>
                <div className="flex flex-wrap items-center justify-center lg:justify-start gap-6 text-sm text-blue-50 mt-6">
                  <div className="flex items-center group">
                    <div className="bg-white/15 p-2 rounded-full mr-2.5 group-hover:bg-white/30 transition-colors duration-300">
                      <CheckCheck className="w-4 h-4" />
                    </div>
                    <span className="font-medium">Exact AAMC Q-Style</span>
                  </div>
                  <div className="flex items-center group">
                    <div className="bg-white/15 p-2 rounded-full mr-2.5 group-hover:bg-white/30 transition-colors duration-300">
                      <BrainIcon className="w-4 h-4" />
                    </div>
                    <span className="font-medium">AI Tutor (Harvard-Proven)</span>
                  </div>
                  <div className="flex items-center group">
                    <div className="bg-white/15 p-2 rounded-full mr-2.5 group-hover:bg-white/30 transition-colors duration-300">
                      <Sparkles className="w-4 h-4" />
                    </div>
                    <span className="font-medium">Performance Rewards</span>
                  </div>
                </div>
                
                {/* Medical School Success Logos section removed */}
                
              </div>
              <div className="lg:w-1/2 relative">
                <div className="w-full h-full bg-gradient-to-br from-blue-100/30 to-green-100/30 rounded-2xl shadow-2xl overflow-hidden transition-all duration-500 transform hover:rotate-1 hover:scale-[1.02] backdrop-blur-sm border border-white/40 group">
                  <div className="relative">
                    <img
                      src={heroImage}
                      alt="MCAT CARS Preparation Platform - UPangea"
                      className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-105"
                      loading="eager"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-blue-600/30 to-transparent"></div>
                  </div>
                </div>
                <div className="absolute -bottom-4 -right-4 bg-white p-5 rounded-xl shadow-xl transform rotate-2 border-t-4 border-blue-500 transition-all duration-300 hover:shadow-2xl hover:-translate-y-1 hover:rotate-3">
                  <p className="text-blue-600 font-bold flex items-center">
                    <Brain className="h-6 w-6 mr-2 text-green-500" />
                    13,000+ Authentic Questions
                  </p>
                </div>
                
                {/* Enhanced decorative elements */}
                <div className="absolute -top-6 -left-6 bg-gradient-to-br from-indigo-500 to-purple-500 w-12 h-12 rounded-full opacity-70 blur-lg animate-pulse" style={{ animationDuration: "7s" }}></div>
                <div className="absolute top-1/3 -right-8 bg-gradient-to-br from-green-400 to-cyan-400 w-16 h-16 rounded-full opacity-70 blur-lg animate-pulse" style={{ animationDelay: "1.2s", animationDuration: "9s" }}></div>
                <div className="absolute bottom-1/4 left-1/4 bg-gradient-to-br from-blue-400 to-indigo-400 w-14 h-14 rounded-full opacity-60 blur-lg animate-pulse" style={{ animationDelay: "2.2s", animationDuration: "8s" }}></div>
              </div>
            </div>
          </div>
        </section>

        {/* About Us Section */}
        <section
          ref={aboutRef}
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-200 via-white to-green-200 relative overflow-hidden"
        >
          {/* Background patterns */}
          <div className="absolute inset-0 bg-grid-black/[0.02] bg-[size:30px_30px] pointer-events-none"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>
          <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-gradient-to-r from-blue-600 to-green-600 relative z-20">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-white text-blue-600 rounded-full">
                  <Brain className="h-4 w-4 inline mr-2 text-green-500" />
                  Finally, Real CARS
                </span>
              </div>
              <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-6 leading-tight">
                Why True AAMC Replica CARS{" "}
                <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                  Matters
                </span>
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed mb-8">
                The MCAT CARS section is notorious for its subtle logic traps,
                rhetorical twists, and precisely structured passages—yet most
                MCAT prep companies cut corners. At UPangea, we meticulously
                replicate every nuance of the AAMC CARS design, so you face the
                real thing before test day, not some halfway clone. Our pre-med
                students consistently report that our practice questions mirror
                the actual MCAT exam.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="relative group">
                <div className="absolute inset-0.5 bg-gradient-to-br from-blue-500 to-green-500 rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-300"></div>
                <div className="relative bg-white rounded-xl p-8 shadow-lg border border-blue-100 h-full flex flex-col">
                  <div className="h-14 w-14 rounded-xl bg-gradient-to-br from-blue-500 to-green-500 flex items-center justify-center mb-6 shadow-md group-hover:scale-110 transition-transform duration-300">
                    <Brain className="h-7 w-7 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 text-gray-900 group-hover:text-blue-600 transition-colors">
                    Focused CARS Preparation
                  </h3>
                  <p className="text-gray-600 leading-relaxed mb-4 flex-grow">
                    Unlike generalists who split focus across all MCAT sections,
                    we dedicate our resources to creating high-quality CARS
                    practice materials that target the specific critical thinking skills
                    needed for this challenging section.
                  </p>
                  <div className="mt-auto">
                    <div className="flex items-center p-3 bg-blue-50 rounded-lg border border-blue-100">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0" />
                      <span className="text-sm text-blue-800 font-medium">
                        Our beta users report improved confidence and performance
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative group">
                <div className="absolute inset-0.5 bg-gradient-to-br from-purple-500 to-pink-500 rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-300"></div>
                <div className="relative bg-white rounded-xl p-8 shadow-lg border border-purple-100 h-full flex flex-col">
                  <div className="h-14 w-14 rounded-xl bg-gradient-to-br from-purple-500 to-pink-500 flex items-center justify-center mb-6 shadow-md group-hover:scale-110 transition-transform duration-300">
                    <MessageSquare className="h-7 w-7 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 text-gray-900 group-hover:text-purple-600 transition-colors">
                    Efficient Learning
                  </h3>
                  <p className="text-gray-600 leading-relaxed mb-4 flex-grow">
                    Our 24/7 AI tutor provides immediate feedback on
                    your reasoning approach, helping you identify patterns in your
                    thinking and reducing time spent on
                    ineffective study methods, enhancing study efficiency.
                  </p>
                  <div className="mt-auto">
                    <div className="flex items-center p-3 bg-purple-50 rounded-lg border border-purple-100">
                      <Clock className="h-5 w-5 text-purple-600 mr-2 flex-shrink-0" />
                      <span className="text-sm text-purple-800 font-medium">
                        8-12 weeks of consistent practice shows meaningful improvement
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative group">
                <div className="absolute inset-0.5 bg-gradient-to-br from-orange-500 to-red-500 rounded-xl blur opacity-30 group-hover:opacity-100 transition duration-300"></div>
                <div className="relative bg-white rounded-xl p-8 shadow-lg border border-orange-100 h-full flex flex-col">
                  <div className="h-14 w-14 rounded-xl bg-gradient-to-br from-orange-500 to-red-500 flex items-center justify-center mb-6 shadow-md group-hover:scale-110 transition-transform duration-300">
                    <Users className="h-7 w-7 text-white" />
                  </div>
                  <h3 className="text-xl font-bold mb-3 text-gray-900 group-hover:text-orange-600 transition-colors">
                    Growing Community
                  </h3>
                  <p className="text-gray-600 leading-relaxed mb-4 flex-grow">
                    Our platform has been developed with input from our 500+ beta
                    users who provided valuable feedback to help us refine our
                    approach, resulting in a system that addresses real student needs in CARS preparation.
                  </p>
                  <div className="mt-auto">
                    <div className="flex items-center p-3 bg-orange-50 rounded-lg border border-orange-100">
                      <Star className="h-5 w-5 text-orange-600 mr-2 flex-shrink-0" />
                      <span className="text-sm text-orange-800 font-medium">
                        500+ beta users have helped shape our platform
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-4xl mx-auto bg-gradient-to-r from-blue-50 to-green-50 rounded-2xl overflow-hidden shadow-xl mt-16 mb-12">
              <div className="grid md:grid-cols-5">
                <div className="md:col-span-3 p-8 md:p-10">
                  <div className="text-4xl font-serif text-blue-300 mb-2">"</div>
                  <blockquote className="text-xl md:text-2xl font-medium text-gray-800 italic mb-6">
                    Stop settling for half-baked CARS passages. Tackle the
                    real thing—and watch your score soar faster than you thought
                    possible.
                  </blockquote>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="h-12 w-12 rounded-full bg-gradient-to-r from-blue-500 to-green-500 flex items-center justify-center text-white font-bold text-xl">
                        U
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="text-gray-900 font-bold">
                        The UPangea Team
                      </div>
                      <div className="text-blue-600 text-sm font-medium">
                        Redefining CARS for Pre-Meds Everywhere
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:col-span-2 bg-gradient-to-br from-blue-600 to-green-600 flex items-center justify-center">
                  <div className="p-10 h-full w-full flex items-center justify-center">
                    <div className="bg-white/20 p-6 rounded-lg backdrop-blur-sm border border-white/30 shadow-lg transition-all duration-300 hover:bg-white/30 hover:scale-105">
                      <div className="flex justify-center mb-3">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <Star
                            key={star}
                            className="h-8 w-8 text-yellow-400 fill-yellow-400 mx-1"
                          />
                        ))}
                      </div>
                      <p className="text-white text-center font-medium">
                        Trusted by hundreds of pre-med students
                      </p>
                      <p className="text-white/80 text-center text-sm mt-2">
                        Rated 4.9/5 by our beta users
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <Button
                onClick={handleSignUp}
                size="lg"
                className="bg-gradient-to-r from-blue-600 to-green-600 text-white hover:from-blue-700 hover:to-green-700 shadow-lg hover:shadow-xl transition-all duration-300 py-6 px-8 text-lg"
              >
                Start Your CARS Mastery Journey
                <ArrowRight className="ml-2 h-5 w-5" />
              </Button>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section
          id="features"
          ref={featuresRef}
          className="w-full py-16 md:py-28 lg:py-32 bg-white relative overflow-hidden"
        >
          {/* Background decorative elements */}
          <div className="absolute top-0 right-0 w-1/3 h-1/3 bg-gradient-to-br from-blue-50 to-green-50 rounded-bl-[100px] opacity-80"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>
          
          {/* Subtle grid pattern */}
          <div className="absolute inset-0 bg-grid-slate-100/50 bg-[size:24px_24px] pointer-events-none"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-gradient-to-r from-blue-600 to-green-600">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-white text-blue-600 rounded-full">
                  <CheckCircle className="h-4 w-4 inline mr-2 text-blue-600" />
                  Platform Features
                </span>
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tighter md:text-5xl mb-6 text-center text-gray-900">
                Specialized Tools for{" "}
                <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                  CARS Mastery
                </span>
              </h2>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto">
                We've built our platform specifically for CARS section success, with features designed to target the unique challenges of this critical MCAT component.
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-6 lg:gap-8">
              {[
                {
                  icon: MessageSquare,
                  title: "24/7 AI Tutor (UPenguin)",
                  description:
                    "Our AI tutor analyzes your reasoning patterns and provides immediate, personalized feedback on your approach to CARS passages and questions.",
                  color: "from-blue-500 to-blue-600",
                  iconBg: "bg-blue-100 text-blue-600",
                },
                {
                  icon: Brain,
                  title: "13,000+ Authentic Questions",
                  description:
                    "Practice with our extensive library of AAMC-style passages and questions, meticulously crafted to mirror the real MCAT CARS section.",
                  color: "from-green-500 to-green-600",
                  iconBg: "bg-green-100 text-green-600",
                },
                {
                  icon: Users,
                  title: "Personalized Feedback",
                  description:
                    "Receive tailored insights into your specific reasoning patterns and learn exactly which types of questions challenge you most.",
                  color: "from-purple-500 to-purple-600",
                  iconBg: "bg-purple-100 text-purple-600",
                },
                {
                  icon: BarChart,
                  title: "Performance Analytics",
                  description:
                    "Track your timing, question-type performance, and historical progress. Eliminate guesswork on your weak spots.",
                  color: "from-cyan-500 to-cyan-600",
                  iconBg: "bg-cyan-100 text-cyan-600",
                },
                {
                  icon: TrendingUp,
                  title: "6-Point Average Improvement",
                  description:
                    "Our focused approach and AI support result in measurable gains, often within a few months of consistent practice.",
                  color: "from-teal-500 to-teal-600",
                  iconBg: "bg-teal-100 text-teal-600",
                },
                {
                  icon: Star,
                  title: "Authentic AAMC-Style Testing Interface",
                  description:
                    "Medical school applicants practice in our MCAT CARS interface meticulously built to match the real exam's layout, features, and complexities, so test day feels second-nature.",
                  color: "from-emerald-500 to-emerald-600",
                  iconBg: "bg-emerald-100 text-emerald-600",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className="bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 hover:-translate-y-1 overflow-hidden border border-gray-100 relative group"
                >
                  {/* Background decoration */}
                  <div className={`absolute top-4 left-0 right-0 h-1.5 bg-gradient-to-r ${feature.color}`}></div>
                  <div className="p-8 pt-10">
                    <div className="space-y-6">
                      <div className="flex items-center justify-center h-16 w-16 rounded-full ${feature.iconBg} mb-6 group-hover:scale-110 transition-transform duration-300">
                        <feature.icon className="h-8 w-8" />
                      </div>
                      <h3 className="text-xl font-bold mb-4 text-gray-900 group-hover:text-blue-600 transition-colors">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600 leading-relaxed">
                        {feature.description}
                      </p>
                    </div>
                  
                    {/* Hover effect */}
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600/5 via-transparent to-green-600/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-20 text-center">
              <div className="inline-block relative">
                <Button
                  style={{
                    background: "linear-gradient(to right, #2563eb, #10b981)",
                    color: "white",
                    padding: "1.5rem 2rem",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    borderRadius: "0.75rem",
                    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.3s ease",
                    width: "100%"
                  }}
                  onClick={handleSignUp}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                  }}
                >
                  <div className="absolute inset-0 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-20 -translate-x-full group-hover:translate-x-full transition-all duration-1000 ease-in-out"></div>
                  <span className="relative z-10 flex items-center">
                    Try These Features Now
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="ml-2"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </span>
                </Button>
                {/* Decorative elements */}
                <div className="absolute -top-4 -right-4 w-8 h-8 bg-blue-100 rounded-full opacity-70 animate-ping" style={{ animationDuration: "3s" }}></div>
                <div className="absolute -bottom-4 -left-4 w-8 h-8 bg-green-100 rounded-full opacity-70 animate-ping" style={{ animationDuration: "4s", animationDelay: "1s" }}></div>
              </div>
            </div>
          </div>
        </section>

        {/* Score Calculator */}
        <section
          id="score-calculator"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-50 to-green-50 relative overflow-hidden"
        >
          {/* Background patterns */}
          <div className="absolute inset-0 bg-grid-slate-200/50 bg-[size:24px_24px] pointer-events-none"></div>
          <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>

          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 items-center">
              <div className="space-y-4">
                <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-gradient-to-r from-blue-600 to-green-600">
                  <span className="inline-block px-4 py-1.5 text-sm font-bold bg-white text-blue-600 rounded-full">
                    <Calculator className="h-4 w-4 inline mr-2 text-blue-600" />
                    Score Calculator
                  </span>
                </div>
                <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-gray-900 leading-tight tracking-tight">
                  See How We Can Boost Your{" "}
                  <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                    MCAT Score
                  </span>
                </h2>
                <p className="text-lg text-gray-600 max-w-2xl">
                  Our platform is designed specifically to improve your CARS performance. Estimate your potential score increase based on your current performance.
                </p>

                <div className="mt-10 space-y-6">
                  <div className="space-y-3">
                    <label className="text-base font-medium text-gray-900 flex items-center">
                      <Calculator className="h-5 w-5 mr-2 text-blue-600" />
                      Current CARS Score ({carsScore})
                    </label>
                    <div className="px-2 py-4">
                      <Slider
                        defaultValue={[carsScore]}
                        min={117}
                        max={132}
                        step={1}
                        onValueChange={(value) => setCarsScore(value[0])}
                        className="w-full"
                      />
                      <div className="flex justify-between mt-2 text-xs text-gray-500">
                        <span>117</span>
                        <span>Low</span>
                        <span>Average</span>
                        <span>High</span>
                        <span>132</span>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500">
                      Drag to select your current CARS section score
                    </p>
                  </div>

                  <div className="space-y-3">
                    <label className="text-base font-medium text-gray-900 flex items-center">
                      <Clock className="h-5 w-5 mr-2 text-blue-600" />
                      Hours per Week to Study ({hoursPerWeek})
                    </label>
                    <div className="px-2 py-4">
                      <Slider
                        defaultValue={[hoursPerWeek]}
                        min={2}
                        max={12}
                        step={1}
                        onValueChange={(value) => setHoursPerWeek(value[0])}
                        className="w-full"
                      />
                      <div className="flex justify-between mt-2 text-xs text-gray-500">
                        <span>2</span>
                        <span>4</span>
                        <span>6</span>
                        <span>8</span>
                        <span>10</span>
                        <span>12</span>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500">
                      How many hours can you dedicate to CARS practice each week?
                    </p>
                  </div>

                  <div className="space-y-3">
                    <label className="text-base font-medium text-gray-900 flex items-center">
                      <CalendarIcon className="h-5 w-5 mr-2 text-blue-600" />
                      Weeks Until Test ({weeksUntilTest})
                    </label>
                    <div className="px-2 py-4">
                      <Slider
                        defaultValue={[weeksUntilTest]}
                        min={4}
                        max={24}
                        step={4}
                        onValueChange={(value) => setWeeksUntilTest(value[0])}
                        className="w-full"
                      />
                      <div className="flex justify-between mt-2 text-xs text-gray-500">
                        <span>4</span>
                        <span>8</span>
                        <span>12</span>
                        <span>16</span>
                        <span>20</span>
                        <span>24</span>
                      </div>
                    </div>
                    <p className="text-sm text-gray-500">
                      How much time do you have before your MCAT?
                    </p>
                  </div>
                </div>
              </div>

              <div className="lg:pl-0">
                <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100 transition-all duration-500 transform hover:shadow-2xl">
                  <div className="relative p-8 md:p-10">
                    <div className="space-y-6">
                      <div className="flex items-center justify-between">
                        <h3 className="text-2xl font-bold text-gray-900">Your Projected Results</h3>
                        <div className="flex items-center justify-center px-3 py-1 rounded-full bg-emerald-50">
                          <span className="text-sm font-bold text-emerald-700">{calculatePotentialScore()}</span>
                        </div>
                      </div>

                      <div className="space-y-6">
                        <div>
                          <div className="flex items-center justify-between mb-2">
                            <span className="text-gray-600">Current Score</span>
                            <span className="text-gray-900 font-semibold">{carsScore}</span>
                          </div>
                          <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-gray-400 rounded-full"
                              style={{
                                width: `${((carsScore - 117) / 15) * 100}%`,
                              }}
                            ></div>
                          </div>
                        </div>

                        <div>
                          <div className="flex items-center justify-between mb-2">
                            <span className="text-gray-600">Potential Score</span>
                            <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text font-bold text-xl">
                              {calculatePotentialScore()}
                            </span>
                          </div>
                          <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden">
                            <div
                              className="h-full bg-gradient-to-r from-blue-500 to-green-500 rounded-full"
                              style={{
                                width: `${
                                  ((calculatePotentialScore() - 117) / 15) * 100
                                }%`,
                              }}
                            ></div>
                          </div>
                        </div>

                        <div className="pt-4 border-t border-gray-100">
                          <div className="flex items-center justify-between mb-2">
                            <span className="text-gray-600">Improvement</span>
                            <span className="text-emerald-600 font-bold text-lg">
                              +{calculatePotentialScore() - carsScore} points
                            </span>
                          </div>
                          <div className="h-8 w-full bg-emerald-50 rounded-lg flex items-center justify-center mt-2">
                            <span className="text-emerald-700 text-sm font-medium">
                              {calculateTotalPassages()} practice passages can lead to this improvement
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pt-6 border-t border-gray-100">
                        <Button
                          style={{
                            background: "linear-gradient(to right, #2563eb, #10b981)",
                            color: "white",
                            padding: "1rem 1.5rem",
                            fontSize: "1.125rem",
                            fontWeight: "600",
                            borderRadius: "0.75rem",
                            boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                            position: "relative",
                            overflow: "hidden",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transition: "all 0.3s ease",
                            width: "100%"
                          }}
                          onClick={handleSignUp}
                          onMouseOver={(e) => {
                            e.currentTarget.style.transform = "scale(1.05)";
                            e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.transform = "scale(1)";
                            e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                          }}
                        >
                          <span style={{ display: "flex", alignItems: "center" }}>
                            Get Started Now
                            <ArrowRight style={{ marginLeft: "0.5rem", transition: "transform 0.3s ease" }} />
                          </span>
                        </Button>
                      </div>
                    </div>

                    {/* Background decorative element */}
                    <div className="absolute -bottom-24 -right-24 w-64 h-64 bg-blue-50 rounded-full opacity-70"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Future Pricing Section */}
        <section
          id="pricing"
          ref={pricingRef}
          className="w-full py-12 md:py-24 lg:py-32 bg-white relative overflow-hidden"
        >
          {/* Background patterns */}
          <div className="absolute w-full h-full bg-gradient-to-b from-blue-50/50 via-transparent to-green-50/50 pointer-events-none" />
          <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-gradient-to-r from-blue-600 to-green-600">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-white text-blue-600 rounded-full">
                  <BarChart className="h-4 w-4 inline mr-2 text-blue-600" />
                  Pricing Plans
                </span>
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tighter md:text-5xl mb-6 text-center text-gray-900">
                Simple,{" "}
                <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                  Transparent
                </span>{" "}
                Pricing
              </h2>
              <p className="text-lg text-gray-600 max-w-2xl mx-auto mb-4">
                Choose your ideal access duration to get started with UPangea
                CARS today. All plans are one-time payments with no recurring
                charges.
              </p>

              {hasEarlyBirdDiscount && (
                <div className="mt-6 mb-8 inline-flex items-center py-2 px-4 bg-yellow-100 text-yellow-800 rounded-full animate-pulse border border-yellow-200">
                  <Star className="h-5 w-5 mr-2 text-yellow-600" />
                  <span className="font-semibold">
                    Limited Time: 50% Early Bird Discount Applied
                  </span>
                </div>
              )}
            </div>

            {/* Pricing Toggle */}
            <div className="max-w-5xl mx-auto mb-10">
              <div className="flex justify-center gap-4 mb-12">
                {Object.entries(pricingOptions).map(([key, option]) => (
                  <button
                    key={key}
                    onClick={() => setSelectedDuration(key)}
                    className={`relative px-5 py-3 rounded-xl text-base font-medium transition-all duration-300 ${
                      selectedDuration === key
                        ? "bg-gradient-to-r from-blue-600 to-green-600 text-white shadow-lg"
                        : "bg-white text-gray-700 hover:bg-gray-50 border border-gray-200 hover:border-blue-200"
                    } ${option.popular ? "ring-2 ring-blue-500 ring-offset-2" : ""}`}
                  >
                    {option.popular && (
                      <span className="absolute -top-2 left-1/2 transform -translate-x-1/2 bg-blue-600 text-white text-xs font-bold py-0.5 px-2 rounded-full">
                        Popular
                      </span>
                    )}
                    {option.label}
                  </button>
                ))}
              </div>

              {/* Pricing Card */}
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-blue-100 transform transition-all duration-500 hover:shadow-2xl">
                <div className="grid md:grid-cols-2">
                  <div className="p-8 md:p-10 relative overflow-hidden">
                    <div className="relative z-10">
                      <div className="flex items-baseline mb-2">
                        <span className="text-4xl md:text-5xl font-extrabold text-gray-900">
                          ${pricingOptions[selectedDuration].price}
                        </span>
                        {pricingOptions[selectedDuration].originalPrice && (
                          <span className="ml-2 text-xl text-gray-500 line-through">
                            ${pricingOptions[selectedDuration].originalPrice}
                          </span>
                        )}
                        <span className="ml-2 text-sm text-gray-500">
                          {pricingOptions[selectedDuration].period}
                        </span>
                      </div>

                      <h3 className="text-2xl font-bold mb-6 text-gray-900">
                        {pricingOptions[selectedDuration].label}
                      </h3>

                      <p className="text-gray-600 mb-8">
                        {pricingOptions[selectedDuration].description}
                      </p>

                      <div className="space-y-3 mb-8">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                              <Check className="h-3 w-3 text-green-600" />
                            </div>
                          </div>
                          <span className="text-gray-600">
                            Full access to 13,000+ CARS questions
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                              <Check className="h-3 w-3 text-green-600" />
                            </div>
                          </div>
                          <span className="text-gray-600">
                            24/7 AI Tutor (UPenguin)
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                              <Check className="h-3 w-3 text-green-600" />
                            </div>
                          </div>
                          <span className="text-gray-600">
                            Complete analytics suite
                          </span>
                        </div>
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            <div className="h-5 w-5 rounded-full bg-green-100 flex items-center justify-center mr-3">
                              <Check className="h-3 w-3 text-green-600" />
                            </div>
                          </div>
                          <span className="text-gray-600">
                            Priority support
                            {selectedDuration === "lifetime" ? " forever" : ""}
                          </span>
                        </div>
                      </div>

                      <Button
                        style={{
                          background: "linear-gradient(to right, #2563eb, #10b981)",
                          color: "white",
                          padding: "1rem 1.5rem",
                          fontSize: "1.125rem",
                          fontWeight: "600",
                          borderRadius: "0.75rem",
                          boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                          position: "relative",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: "all 0.3s ease",
                          width: "100%"
                        }}
                        onClick={handleSignUp}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                        }}
                      >
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Get Started Now
                          <ArrowRight style={{ marginLeft: "0.5rem", transition: "transform 0.3s ease" }} />
                        </span>
                      </Button>
                    </div>

                    {/* Background decorative element */}
                    <div className="absolute -bottom-24 -right-24 w-64 h-64 bg-blue-50 rounded-full opacity-70"></div>
                  </div>
                  <div className="bg-gradient-to-br from-blue-600 to-green-600 p-8 md:p-10 text-white">
                    <h3 className="text-2xl font-bold mb-6">
                      Achievement Rewards Program
                    </h3>
                    <p className="text-white/80 mb-8 text-blue-50">
                      Hit either of these milestones and get{" "}
                      <span className="font-bold text-white underline decoration-2">
                        100% of your money back
                      </span>
                    </p>

                    <div className="space-y-6">
                      <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl border border-white/20">
                        <div className="flex items-start space-x-4">
                          <div className="bg-white rounded-full p-2.5 mt-1">
                            <Check className="h-5 w-5 text-green-600" />
                          </div>
                          <div>
                            <h4 className="font-bold text-xl mb-2">
                              Master All Questions
                            </h4>
                            <p className="text-blue-50">
                              Complete our entire 13,000+ question bank and get
                              a full refund
                            </p>
                            <p className="mt-2 text-xs text-blue-100 italic">
                              "I challenged myself to finish all questions over
                              6 months. Only got through ~1000 questions
                              UPangea refunded me anyways for being a beta user,
                              but I'd have paid double for these results!" — Raj
                              K., MD Candidate
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="bg-white/10 backdrop-blur-sm p-6 rounded-xl border border-white/20">
                        <div className="flex items-start space-x-4">
                          <div className="bg-white rounded-full p-2.5 mt-1">
                            <Star className="h-5 w-5 text-yellow-600" />
                          </div>
                          <div>
                            <h4 className="font-bold text-xl mb-2">
                              Top 10% Score
                            </h4>
                            <p className="text-blue-50">
                              Achieve a 129+ score on your MCAT CARS section and
                              get a full refund
                            </p>
                            <p className="mt-2 text-xs text-blue-100 italic">
                              "Started with a 125 diagnostic, ended with 130 on
                              test day. UPangea refunded me in full, which will
                              help cover my application fees." — Taylor W.,
                              Applying Now
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-8 bg-white/10 backdrop-blur-sm p-4 rounded-lg border border-white/20 text-center">
                      <p className="text-blue-50 text-sm">
                        We're confident in our platform—your success is our
                        success
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="max-w-2xl mx-auto mt-12">
              <p className="text-center text-gray-600 text-sm">
                All plans include our complete feature set. Choose the duration
                that fits your study timeline best.
              </p>
              <div className="flex justify-center mt-6">
                <Button
                  style={{
                    background: "linear-gradient(to right, #2563eb, #10b981)",
                    color: "white",
                    padding: "1rem 1.5rem",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    borderRadius: "0.75rem",
                    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.3s ease",
                    width: "100%"
                  }}
                  onClick={handleSignUp}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center" }}>
                    Get Started Now
                    <ArrowRight style={{ marginLeft: "0.5rem", transition: "transform 0.3s ease" }} />
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section
          id="testimonials"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-50 to-green-50 relative overflow-hidden"
        >
          {/* Decorative elements */}
          <div className="absolute inset-0 bg-grid-black/[0.02] bg-[size:30px_30px] pointer-events-none" />
          <div className="absolute -top-32 -right-32 w-96 h-96 bg-blue-200 rounded-full opacity-30 blur-3xl"></div>
          <div className="absolute -bottom-32 -left-32 w-96 h-96 bg-green-200 rounded-full opacity-30 blur-3xl"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-blue-50">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-gradient-to-r from-blue-600 to-green-600 text-white rounded-full">
                  <Star className="h-4 w-4 inline mr-2 text-yellow-500" />
                  Success Stories
                </span>
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tighter md:text-5xl mb-6 text-center text-gray-900">
                Real Results from{" "}
                <span className="bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                  Real Students
                </span>
              </h2>
              <p className="text-lg text-gray-600 max-w-xl mx-auto">
                Hear from students who have transformed their CARS performance
                with our platform and secured spots at top medical schools.
              </p>
            </div>

            <div className="max-w-6xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {[
                  {
                    quote:
                      "UPangea's AI tutor identified patterns in my reasoning I never noticed. After 2 months of focused practice, I improved from 125 to 130 on the real MCAT.",
                    name: "Rebecca T.",
                    title: "Accepted to UCLA School of Medicine",
                    improvement: "+5 points",
                    color: "from-blue-600 to-blue-400",
                  },
                  {
                    quote:
                      "The authentic AAMC-style passages were game-changing. They felt exactly like the real test, which made me so much more confident on test day. 127 to 131 in three months!",
                    name: "Marcus J.",
                    title: "Accepted to McMaster University",
                    improvement: "+4 points",
                    color: "from-green-600 to-green-400",
                  },
                  {
                    quote:
                      "As an ESL student, CARS was my biggest challenge. The step-by-step reasoning breakdowns and personalized practice plan helped me improve from 123 to 128.",
                    name: "Sophia L.",
                    title: "Accepted to NYU Grossman",
                    improvement: "+5 points",
                    color: "from-purple-600 to-purple-400",
                  },
                ].map((testimonial, index) => (
                  <div
                    key={index}
                    className="group relative bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100 flex flex-col"
                  >
                    {/* Top decoration */}
                    <div className={`h-2 w-full bg-gradient-to-r ${testimonial.color}`}></div>
                    
                    <div className="p-8 flex-grow flex flex-col">
                      <div className="text-white/70 mb-3 group-hover:text-white transition-colors">
                        <Star className="w-5 h-5" />
                      </div>

                      <div className="mb-6 flex-grow">
                        <blockquote className="text-gray-700 italic">
                          "{testimonial.quote}"
                        </blockquote>
                      </div>

                      <div className="flex items-center">
                        <div className="w-10 h-10 rounded-full bg-gradient-to-r from-blue-600 to-green-600 flex items-center justify-center text-white font-bold mr-3">
                          {testimonial.name.split(' ')[0][0]}
                          {testimonial.name.split(' ')[1][0]}
                        </div>
                        <div>
                          <h4 className="font-semibold">{testimonial.name}</h4>
                          <div className="flex">
                            {[1, 2, 3, 4, 5].map((star) => (
                              <Star
                                key={star}
                                className="h-3 w-3 text-yellow-400 fill-yellow-400"
                              />
                            ))}
                          </div>
                          <p className="text-sm text-gray-500">{testimonial.title}</p>
                        </div>
                      </div>
                    </div>

                    {/* Hover effect */}
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600/5 via-transparent to-green-600/5 opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none"></div>
                  </div>
                ))}
              </div>

              <div className="mt-16 bg-white/15 backdrop-blur-sm rounded-2xl p-8 md:p-10 mb-10">
                <div className="grid md:grid-cols-2 gap-6 md:gap-10 items-center">
                  <div>
                    <div className="inline-block relative">
                      <Button
                        style={{
                          background: "linear-gradient(to right, #2563eb, #10b981)",
                          color: "white",
                          padding: "1.5rem 2rem",
                          fontSize: "1.125rem",
                          fontWeight: "600",
                          borderRadius: "0.75rem",
                          boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                          position: "relative",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: "all 0.3s ease",
                          width: "100%"
                        }}
                        onClick={handleSignUp}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                        }}
                      >
                        <div className="absolute inset-0 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-20 -translate-x-full group-hover:translate-x-full transition-all duration-1000 ease-in-out"></div>
                        <span className="relative z-10 flex items-center">
                          Start Personalized Assessment
                          <ArrowRight className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1" />
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="p-1 bg-gradient-to-r from-blue-600 to-green-600 rounded-xl shadow-xl">
                      <div className="bg-white p-6 rounded-lg">
                        <div className="flex justify-between items-center mb-3">
                          <div className="flex items-center">
                            <User className="h-5 w-5 text-blue-500 mr-2" />
                            <span className="text-sm md:text-base font-medium text-gray-700">Kamren Hall</span>
                          </div>
                          <div className="bg-green-100 text-green-800 text-xs md:text-sm font-bold px-2 py-1 rounded-full">
                            +7 Points
                          </div>
                        </div>
                        <blockquote className="text-gray-700 text-sm md:text-base leading-relaxed">
                          "I was consistently scoring 124-125 on CARS, which was holding back my application. UPangea's system helped me understand my specific reasoning flaws. After 3 months of focused practice, I scored 131 on the real MCAT. Now I'm heading to my dream school!"
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Stats */}
        <section
          id="stats"
          className="w-full py-12 md:py-24 lg:py-32 bg-gradient-to-br from-blue-600 to-green-600 relative overflow-hidden"
        >
          {/* Background patterns */}
          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px] pointer-events-none"></div>

          {/* Floating shapes decoration */}
          <div className="absolute top-1/4 left-10 w-32 h-32 bg-white opacity-5 rounded-full"></div>
          <div className="absolute bottom-1/4 right-10 w-48 h-48 bg-white opacity-5 rounded-full"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-gradient-to-r from-blue-600 to-green-600">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-white text-blue-600 rounded-full">
                  <BarChart className="h-4 w-4 inline mr-2 text-blue-600" />
                  Platform Metrics
                </span>
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tighter md:text-5xl mb-6 text-center text-white">
                MCAT CARS Success by the{" "}
                <span className="bg-white text-transparent bg-clip-text">
                  Numbers
                </span>
              </h2>
              <p className="text-lg text-blue-100 max-w-2xl mx-auto opacity-90">
                See the measurable impact our AAMC-style practice platform is
                making for pre-med students preparing for the MCAT CARS section
                and medical school applications.
              </p>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-10">
              {[
                {
                  value: "500+",
                  label: "Beta Users",
                  icon: <Users className="h-8 w-8" />,
                  description:
                    "Approval of 500+ beta users applying to medical school with an average CARS score of 128",
                },
                {
                  value: "13,000+",
                  label: "MCAT CARS Questions",
                  icon: <CheckCircle className="h-8 w-8" />,
                  description:
                    "Authentic AAMC-style CARS practice passages and questions",
                },
                {
                  value: "4-8",
                  label: "Avg. CARS Point Increase",
                  icon: <TrendingUp className="h-8 w-8" />,
                  description:
                    "Typical MCAT CARS score improvement with consistent practice",
                },
                {
                  value: "2X",
                  label: "Faster CARS Learning",
                  icon: <Brain className="h-8 w-8" />,
                  description:
                    "AI MCAT tutoring vs. traditional pre-med prep methods (Harvard study)",
                },
              ].map((stat, index) => (
                <div
                  key={index}
                  className="bg-white/10 backdrop-blur-md rounded-2xl p-6 md:p-8 transition-all duration-500 hover:bg-white/15 hover:scale-105 border border-white/10 group relative overflow-hidden"
                >
                  {/* Background decoration */}
                  <div className={`absolute top-0 left-0 right-0 h-1.5 bg-gradient-to-r from-blue-600 to-green-600`}></div>

                  <div className="flex flex-col h-full">
                    <div className="text-white/70 mb-3 group-hover:text-white transition-colors">
                      {stat.icon}
                    </div>

                    <h3 className="text-3xl md:text-4xl xl:text-5xl font-bold text-white mb-2 mt-auto">
                      {stat.value}
                    </h3>

                    <div className="space-y-1">
                      <p className="text-lg md:text-xl font-semibold text-blue-100">
                        {stat.label}
                      </p>
                      <p className="text-sm text-blue-200/80">
                        {stat.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-16 text-center">
              <div className="flex justify-center">
                <Button
                  style={{
                    background: "linear-gradient(to right, #2563eb, #10b981)",
                    color: "white",
                    padding: "1.5rem 2rem",
                    fontSize: "1.125rem",
                    fontWeight: "600",
                    borderRadius: "0.75rem",
                    boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transition: "all 0.3s ease"
                  }}
                  onClick={handleSignUp}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                  }}
                >
                  <div className="absolute inset-0 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-20 -translate-x-full group-hover:translate-x-full transition-all duration-1000 ease-in-out"></div>
                  <span className="relative z-10 flex items-center">
                    Join Our Success Stories
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="ml-2"
                    >
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ */}
        <section
          id="faq"
          className="w-full py-16 md:py-24 lg:py-32 bg-gray-50 relative overflow-hidden"
        >
          {/* Background decoration */}
          <div className="absolute inset-0 bg-grid-slate-200/50 bg-[size:24px_24px] pointer-events-none" />
          <div className="absolute top-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-slate-300 to-transparent"></div>

          {/* Decorative elements */}
          <div className="absolute right-0 top-0 -mt-16 -mr-16 w-64 h-64 bg-gradient-to-br from-blue-600/10 to-green-600/10 rounded-full blur-3xl"></div>
          <div className="absolute left-0 bottom-0 -mb-16 -ml-16 w-64 h-64 bg-gradient-to-tr from-blue-600/10 to-green-600/10 rounded-full blur-3xl"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="max-w-3xl mx-auto text-center mb-16">
              <div className="inline-flex items-center justify-center p-1 mb-4 rounded-full bg-blue-50">
                <span className="inline-block px-4 py-1.5 text-sm font-bold bg-gradient-to-r from-blue-600 to-green-600 text-white rounded-full">
                  <HelpCircle className="h-4 w-4 inline mr-2" />
                  Common Questions
                </span>
              </div>
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tighter md:text-5xl mb-6 text-slate-800">
                MCAT CARS Preparation FAQs
              </h2>
              <p className="text-lg text-slate-600 max-w-xl mx-auto">
                Find answers to the most common questions about UPangea's
                AAMC-style CARS practice platform and how our question bank
                helps pre-med students improve their MCAT CARS scores for
                medical school admission.
              </p>
            </div>

            <div className="max-w-4xl mx-auto">
              <div className="space-y-4">
                {[
                  {
                    question:
                      "How does UPangea help improve MCAT CARS scores for medical school applications?",
                    answer:
                      "UPangea combines a comprehensive 13,000+ question bank of authentic AAMC-style CARS passages with personalized AI tutoring specifically designed for pre-med students. Our platform analyzes your performance patterns to identify specific critical reasoning weaknesses, then provides targeted practice and explanations to help you improve those areas. Medical school applicants using our platform see an average 4-8 point CARS score improvement, significantly enhancing their overall MCAT score and application competitiveness.",
                  },
                  {
                    question:
                      "What makes UPangea different from other MCAT CARS prep options?",
                    answer:
                      "While other prep companies divide focus across all MCAT sections, UPangea specializes exclusively in CARS mastery. Our extensive question bank helps you develop the specific skills needed for this challenging section, and our AI tutor provides immediate feedback on your approach.",
                  },
                  {
                    question: "How is UPenguin more effective than regular tutors?",
                    answer:
                      "Our AI tutor is available 24/7, providing immediate feedback when you need it most. This means you can practice at your convenience without scheduling constraints. The AI analyzes your specific performance patterns to help identify your strengths and areas for improvement, offering personalized guidance that adapts to your learning needs.",
                  },
                  {
                    question: "What kind of score improvement can I expect?",
                    answer:
                      "Score improvements vary widely based on starting point, time commitment, and consistency of practice. Based on our beta users, many students see meaningful improvements after completing regular practice sessions over several weeks. While we can't guarantee specific point increases, our comprehensive approach is designed to help you maximize your potential through targeted practice and feedback.",
                  },
                  {
                    question: "Do you offer a satisfaction guarantee?",
                    answer:
                      "Absolutely. We're so confident in our platform's ability to help you improve that we offer a unique guarantee: if you complete our entire 13,000+ question bank OR score in the top 10% of the MCAT CARS section, you'll get your money back. We're fully committed to your success and willing to stand behind our platform's effectiveness.",
                  },
                ].map((item, index) => (
                  <div
                    key={index}
                    className="bg-white rounded-2xl shadow-md overflow-hidden border border-gray-100 transition-all duration-300 hover:shadow-lg hover:border-blue-100"
                  >
                    <button
                      onClick={() =>
                        setOpenFaqIndex(openFaqIndex === index ? null : index)
                      }
                      className="flex justify-between items-center w-full p-6 text-left"
                    >
                      <h3 className="font-semibold text-lg text-gray-900">{item.question}</h3>
                      <div className={`ml-4 flex-shrink-0 flex items-center justify-center w-8 h-8 rounded-full bg-gray-100 text-gray-500 transition-all duration-300 ${
                        openFaqIndex === index ? "rotate-180 bg-blue-100 text-blue-600" : ""
                      }`}>
                        <ChevronDown className="h-5 w-5" />
                      </div>
                    </button>
                    <div
                      className={`px-6 overflow-hidden transition-all duration-300 ${
                        openFaqIndex === index ? "pb-6 max-h-96" : "max-h-0"
                      }`}
                    >
                      <div className="prose prose-blue max-w-none text-gray-600">
                        <p>{item.answer}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="mt-12 text-center">
                <p className="text-slate-600 mb-6">
                  Still have questions? We're here to help.
                </p>
                <Button
                  variant="outline"
                  className="border-blue-600 text-blue-600 hover:bg-blue-50 transition-all duration-300 group"
                  onClick={() =>
                    (window.location.href = "mailto:team@upangea.com")
                  }
                >
                  <Mail className="mr-2 h-4 w-4 transition-transform duration-300 group-hover:scale-110" />
                  Contact Support
                </Button>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section
          id="cta"
          className="w-full py-16 md:py-24 lg:py-32 bg-gradient-to-br from-blue-600 via-blue-700 to-blue-800 relative overflow-hidden"
        >
          {/* Background patterns */}
          <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:40px_40px] pointer-events-none"></div>

          {/* Floating elements */}
          <div className="absolute top-1/4 left-10 w-32 h-32 bg-gradient-to-r from-green-400 to-green-500 opacity-20 rounded-full blur-3xl"></div>
          <div className="absolute bottom-1/4 right-10 w-48 h-48 bg-gradient-to-r from-blue-400 to-blue-500 opacity-20 rounded-full blur-3xl"></div>

          <div className="container px-4 md:px-6 mx-auto relative z-10">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-6 text-white leading-tight tracking-tight">
                Ready to transform your MCAT CARS score for medical school?
              </h2>

              <p className="text-lg md:text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
                Join hundreds of successful pre-med applicants who have
                significantly improved their MCAT CARS scores by 6 points with
                UPangea's authentic AAMC-style question bank and AI-powered
                tutoring platform.
              </p>

              <div className="bg-white/10 backdrop-blur-md rounded-2xl p-8 md:p-10 mb-10">
                <div className="grid md:grid-cols-2 gap-6 md:gap-10 items-center">
                  <div>
                    <div className="inline-block relative">
                      <Button
                        style={{
                          background: "linear-gradient(to right, #2563eb, #10b981)",
                          color: "white",
                          padding: "1.5rem 2rem",
                          fontSize: "1.125rem",
                          fontWeight: "600",
                          borderRadius: "0.75rem",
                          boxShadow: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
                          position: "relative",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transition: "all 0.3s ease",
                          width: "100%"
                        }}
                        onClick={handleSignUp}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.boxShadow = "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)";
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.boxShadow = "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)";
                        }}
                      >
                        <div className="absolute inset-0 w-full bg-gradient-to-r from-transparent via-white to-transparent opacity-0 group-hover:opacity-20 -translate-x-full group-hover:translate-x-full transition-all duration-1000 ease-in-out"></div>
                        <span className="relative z-10 flex items-center">
                          Start Personalized Assessment
                          <ArrowRight className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1" />
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div>
                    <div className="p-1 bg-gradient-to-r from-blue-600 to-green-600 rounded-xl shadow-xl">
                      <div className="bg-white p-6 rounded-lg">
                        <div className="flex justify-between items-center mb-3">
                          <div className="flex items-center">
                            <User className="h-5 w-5 text-blue-500 mr-2" />
                            <span className="text-sm md:text-base font-medium text-gray-700">Kamren Hall</span>
                          </div>
                          <div className="bg-green-100 text-green-800 text-xs md:text-sm font-bold px-2 py-1 rounded-full">
                            +7 Points
                          </div>
                        </div>
                        <blockquote className="text-gray-700 text-sm md:text-base leading-relaxed">
                          "I was consistently scoring 124-125 on CARS, which was holding back my application. UPangea's system helped me understand my specific reasoning flaws. After 3 months of focused practice, I scored 131 on the real MCAT. Now I'm heading to my dream school!"
                        </blockquote>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-white/80 mt-6 text-sm">
                100% Refundable Deposit: Complete all questions OR score in top 10% for CARS—get your entire deposit back
              </p>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="w-full py-12 bg-slate-900 text-slate-200">
          <div className="container px-4 md:px-6 mx-auto">
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <img src={logoImage} alt="UPangea Logo" className="h-10 w-10" />
                  <span className="text-xl font-bold bg-gradient-to-r from-blue-600 to-green-600 text-transparent bg-clip-text">
                    UPangea
                  </span>
                </div>
                <p className="text-slate-400">
                  Transforming MCAT CARS preparation with AI-powered personalized
                  tutoring and practice.
                </p>
                <div className="flex space-x-4">
                  <a
                    href="#"
                    className="text-slate-400 hover:text-white transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                    </svg>
                    <span className="sr-only">Twitter</span>
                  </a>
                  <a
                    href="#"
                    className="text-slate-400 hover:text-white transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        ry="5"
                      ></rect>
                      <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                      <line x1="17.5" y1="6.5" x2="17.5" y2="6.5"></line>
                    </svg>
                    <span className="sr-only">Instagram</span>
                  </a>
                  <a
                    href="#"
                    className="text-slate-400 hover:text-white transition-colors"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                      <rect x="2" y="9" width="4" height="12"></rect>
                      <circle cx="4" cy="4" r="2"></circle>
                    </svg>
                    <span className="sr-only">LinkedIn</span>
                  </a>
                </div>
              </div>

              <div>
                <h3 className="font-semibold mb-4 text-white">Platform</h3>
                <ul className="space-y-3">
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollTo(featuresRef);
                      }}
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Features
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollTo(pricingRef);
                      }}
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      href="#testimonials"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Testimonials
                    </a>
                  </li>
                  <li>
                    <a
                      href="#faq"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold mb-4 text-white">Legal</h3>
                <ul className="space-y-3">
                  <li>
                    <a
                      href="/privacy"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="/terms"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a
                      href="/cookies"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Cookie Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div>
                <h3 className="font-semibold mb-4 text-white">Company</h3>
                <ul className="space-y-3">
                  <li>
                    <a
                      href="#about"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollTo(aboutRef);
                      }}
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:team@upangea.com"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Contact
                    </a>
                  </li>
                  <li>
                    <a
                      href="/careers"
                      className="text-slate-400 hover:text-white transition-colors"
                    >
                      Careers
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-slate-800 flex flex-col md:flex-row justify-between items-center">
              <p className="text-slate-400 text-sm mb-4 md:mb-0">
                &copy; {new Date().getFullYear()} UPangea Inc. All rights reserved.
              </p>
              <div className="flex space-x-6">
                <a
                  href="/privacy"
                  className="text-slate-400 hover:text-white transition-colors text-sm"
                >
                  Privacy
                </a>
                <a
                  href="/terms"
                  className="text-slate-400 hover:text-white transition-colors text-sm"
                >
                  Terms
                </a>
                <a
                  href="/cookies"
                  className="text-slate-400 hover:text-white transition-colors text-sm"
                >
                  Cookies
                </a>
                <a
                  href="mailto:team@upangea.com"
                  className="text-slate-400 hover:text-white transition-colors text-sm"
                >
                  Contact
                </a>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  );
}
